<template>
    <div class="animated fadeIn faster">
        <div class="titlebar-con">
            <ar-titlebar
                a_class="d-block animated fadeIn fast position-relative"
                :selected_count ="selected_listings.length"
                :selected       ="selected_listings.length > 0"
                :action_text    ="select_all_text"
                :from_count ="listing_from_count"
                :to_count ="listing_to_count"
                :selected_max_count="page_size"
                @click          ="() => { is_select_all = true; checkAllListing(); }"
                @uns-click      ="() => { is_select_all = false; checkAllListing(); }"
                is_listings>
                <template v-slot:search-form>
                    <div class="animated fadeIn fast position-relative" style="z-index: 2">
                        <div class="row">
                            <div class="col-md-12">
                                <ar-textfield
                                    a_type                    ="chip"
                                    a_class                   ="mt-2 w-100"
                                    :placeholder               ="rids.length ? 'Input here and Press Enter (Press Alt+X to remove all)' : 'Input Listing IDs here and Press Enter'"
                                    id                        ="input-rids"
                                    v-model                   ="entered_rid"
                                    @keyup.enter.native       ="appendRids"
                                    @keydown.backspace.native ="removeLastRid"
                                    @keypress.native          ="isNumber"
                                    @paste                    ="cleanPasteInput"
                                >
                                    <ar-chip close v-for="(rid,index) in rids" :key="index" @remove-chip="input_disabled ? false : removeChip(rid)">{{rid}}</ar-chip>
                                </ar-textfield>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <ar-select
                                    id          = 'campaign-search'
                                    type        = "search"
                                    placeholder = "Select Campaign"
                                    class       = "position-relative"
                                    :options    ="campaigns_select"
                                    :val ="selected_campaign"
                                    v-model="selected_campaign"
                                />
                            </div>
                            <div class="col-md-4">
                                <ar-textfield
                                    type        = "text"
                                    a_class     = "w-100"
                                    placeholder = "Input Seller Name"
                                    id          = "seller-name"
                                    v-model     ="searched_seller"
                                    @keyup.enter.native="trySearch"
                                ></ar-textfield>
                            </div>
                            <div class="col-md-5">
                                <ar-textfield
                                    type="text"
                                    a_class="w-100"
                                    placeholder="Input Specific Listing URL"
                                    id="specific-url"
                                    v-model="specific_url"
                                    @keyup.enter.native="trySearch"
                                ></ar-textfield>
                            </div>
                        </div>
                    </div>
                    <div class="position-relative d-flex justify-content-between align-items-center border-top" style="border-width: 2px !important; border-color: #ccc;">
                        <div>
                            <span class="d-inline-block font-weight-bold" style="margin-top: 12px">Showing all {{ listing_from_count }}-{{ listing_to_count || 0 }} out of {{ lists_meta.total_no_of_items || 0 }} listing(s)</span>
                            <div class="dropdown d-inline-block pl-2 ml-3 border-left">
                                <a
                                    href="javascript:void(0);"
                                    id="sortDropdown"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <ar-button
                                        class="position-relative font-weight-bold pl-3 a-btn-text"
                                        style="font-size: 12px; text-align:center; padding-left: 12px !important; padding-right: 12px; padding-top: 3px; padding-bottom: 3px; margin-top: 2px;">
                                        Sort By - {{ sort_by_display }}
                                    </ar-button>
                                </a>
                                <div class="dropdown-menu" aria-labelledby="sortDropdown">
                                    <a
                                        v-for="option in sort_by_option"
                                        :key="option.value"
                                        class="dropdown-item"
                                        href="javascript:void(0);"
                                        @click="sort_by = option.value"
                                    >
                                        {{ option.label }}
                                    </a>
                                </div>
                            </div>
                            <div class="dropdown d-inline-block pl-2 ml-2 border-left">
                                <a
                                    href="javascript:void(0);"
                                    id="orderDropdown"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <ar-button
                                        class="position-relative font-weight-bold pl-3 a-btn-text"
                                        style="font-size: 12px; text-align:center; padding-left: 12px !important; padding-right: 12px; padding-top: 3px; padding-bottom: 3px; margin-top: 2px;">
                                        Order By - {{ $parent.ucFirst(order_by) }}ending
                                    </ar-button>
                                </a>
                                <div class="dropdown-menu" aria-labelledby="orderDropdown">
                                    <a
                                        v-for="option in order_by_option"
                                        :key="option.value"
                                        class="dropdown-item"
                                        href="javascript:void(0);"
                                        @click="order_by = option.value"
                                    >
                                        {{ option.label }}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <ar-button
                            :disabled="expanded && lists.length == 0 && required_filters_has_value"
                            class="position-relative rounded mt-2 pl-3"
                            :icon="`fas ${expanded ? 'fa-angle-up' : 'fa-angle-down'}`"
                            icon_right
                            outlined
                            style="font-size: 12px; padding-top: 3px; padding-bottom: 3px;"
                            @click="expanded = !expanded, getAdditionalFilters_data()">
                            {{ expanded ? 'Less' : 'More' }} filters <span v-if="applied_filters_count" class="badge badge-primary rounded-circle" style="padding: 4px 4px 4px 6px">{{ applied_filters_count }}</span>
                        </ar-button>
                    </div>
                    <div class="additional-filters-con" style="margin-top: 20px;" :class="{'expanded':expanded, 'has-lids': rids.length }">
                        <div class="additional-filters-con-con">
                            <div class="row">
                                <!-- START Newly added feature EMAIL - 7/22/22-->
                                <div class="col-md-6">
                                    <ar-textfield
                                        label="Email"
                                        a_type="text"
                                        v-model="filter_email"
                                        placeholder="Input Email here"
                                    />
                                </div>
                                <div class="col-md-6">
                                    <div class="email_info_container">
                                        <i class="fas fa-info-circle d-block text-lg mr-2" />
                                        <p>This is a generic email search. It will search in multiple email-related data such as: <strong><u>Account Email</u></strong> and <strong><u>Seller Email</u></strong>.</p>
                                    </div>
                                </div>
                                <!-- END Newly added feature EMAIL -->
                                <div class="col-md-12">
                                    <ar-textfield
                                        label="Seller URL / Store URL"
                                        a_type="text"
                                        v-model.trim="filter_seller_url"
                                        placeholder="Input Seller Url or Store URL here"
                                        title="Select Campaign first to access this feature"
                                    />
                                </div>
                                <div class="col-md-6">
                                    <template v-if="!selected_campaign">
                                        <ar-textfield
                                            label="Item Location - Select a campaign to get item location dropdown"
                                            a_type="text"
                                            v-model="filter_item_location"
                                            placeholder="Input keywords here"
                                        />
                                    </template>
                                    <template v-else>
                                        <ar-select 
                                            searchFieldId ="item_location"
                                            v-model="filters__.item_location.selected" 
                                            label="Item Location"
                                            :placeholder="getDataFor_AdditionalFilters ? 'Loading data...' : filters__.item_location.options.length == 0 ? 'No Item Locations Available' : 'Search Item Location'"
                                            type="search"
                                            :options="filters__.item_location.options"
                                            :disabled="filters__.item_location.options.length == 0 || getDataFor_AdditionalFilters"
                                        >
                                            <!-- Select Item Location -->
                                        </ar-select>
                                    </template>
                                </div>
                                <div class="col-md-3">
                                    <ar-textfield
                                        label="Date added (from)"
                                        a_type="date"
                                        a_class="remove-date-spinner"
                                        a_style="padding-right: 3px;"
                                        v-model="min_date_added"
                                        :max="today"
                                    />
                                </div>
                                <div class="col-md-3">
                                    <ar-textfield
                                        label="Date added (to)"
                                        a_type="date"
                                        a_class="remove-date-spinner"
                                        a_style="padding-right: 3px;"
                                        :disabled="min_date_added == ''"
                                        v-model="max_date_added"
                                        :min="min_date_added"
                                        :max="today"
                                    />
                                </div>

                                <div class="col-md-6 d-flex align-items-end">
                                    <div class="w-100">
                                        <ar-select
                                            label="Seller Country"
                                            :options="seller_locations"
                                            v-model="filter_seller_location"
                                            :disabled="seller_locations.length == 0 || getDataFor_AdditionalFilters"
                                        >
                                            <!-- Select Seller Country -->
                                            {{seller_locations.length == 0 || getDataFor_AdditionalFilters ? 'Loading data...' : 'Select Seller Country'}}
                                        </ar-select>
                                    </div>
                                    <div class="ml-2" style="max-width: 100%; margin-bottom: 13px;">
                                        <div class="btn-group btn-group-toggle"
                                            data-toggle="buttons" style="margin-top: -27px;">
                                            <label class="btn btn-outline-secondary font-weight-normal"
                                                :class="{ 'active': filter_with_seller_country == '1' }" style="font-size: 12px;"
                                                @click="updateOption('filter_with_seller_country', '1')">
                                                <input type="radio" name="with_seller_country-options" id="with_seller_country-with" value="1">
                                                With
                                            </label>
                                            <label class="btn btn-outline-secondary font-weight-normal"
                                                :class="{ 'active': filter_with_seller_country == '0' }" style="font-size: 12px;"
                                                @click="updateOption('filter_with_seller_country', '0')">
                                                <input type="radio" name="with_seller_country-options" id="with_seller_country-without" value="0"> 
                                                Without
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <ar-textfield
                                        label="Date qualified (from)"
                                        a_type="date"
                                        a_class="remove-date-spinner"
                                        a_style="padding-right: 3px;"
                                        v-model="min_date_qualified"
                                        :max="today"
                                    />
                                </div>
                                <div class="col-md-3">
                                    <ar-textfield
                                        label="Date qualified (to)"
                                        a_type="date"
                                        a_class="remove-date-spinner"
                                        a_style="padding-right: 3px;"
                                        :disabled="min_date_qualified == ''"
                                        v-model="max_date_qualified"
                                        :min="min_date_qualified"
                                        :max="today"
                                    />
                                </div>
                                <div class="col-md-6 d-flex align-items-end">
                                    <div class="w-100">
                                        <ar-select
                                            label="Claim Type"
                                            :options="filter_claimtype.options"
                                            v-model="filter_claimtype.selected"
                                            :disabled="filter_claimtype.options.length == 0 || getDataFor_AdditionalFilters"
                                        >
                                            <!-- Select Claim Type -->
                                            {{filter_claimtype.options.length == 0 || getDataFor_AdditionalFilters ? 'Loading data...' : 'Select Claim Type' }}
                                        </ar-select>
                                    </div>
                                    <div class="ml-2" style="max-width: 100%; margin-bottom: 13px;">
                                        <div class="btn-group btn-group-toggle"
                                            data-toggle="buttons" style="margin-top: -27px;">
                                            <label class="btn btn-outline-secondary font-weight-normal"
                                                :class="{ 'active': filter_with_claimtype == '1' }" style="font-size: 12px;"
                                                @click="updateOption('filter_with_claimtype', '1')">
                                                <input type="radio" name="with_claimtype-options" id="with_claimtype-with" value="1">
                                                With
                                            </label>
                                            <label class="btn btn-outline-secondary font-weight-normal"
                                                :class="{ 'active': filter_with_claimtype == '0' }" style="font-size: 12px;"
                                                @click="updateOption('filter_with_claimtype', '0')">
                                                <input type="radio" name="with_claimtype-options" id="with_claimtype-without" value="0"> 
                                                Without
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 d-flex align-items-end">
                                    <div class="w-100">
                                        <ar-select
                                            label="Shipping Location"
                                            :options="filter_shipping_location.options"
                                            v-model="filter_shipping_location.selected"
                                            :disabled="filter_shipping_location.options.length == 0 || getDataFor_AdditionalFilters"
                                        >
                                            <!-- Select Shipping Location -->
                                            {{filter_shipping_location.options.length == 0 || getDataFor_AdditionalFilters ? 'Loading data...' : 'Select Shipping Location' }}
                                        </ar-select>
                                    </div>
                                    <div class="ml-2" style="max-width: 100%; margin-bottom: 13px;">
                                        <div class="btn-group btn-group-toggle"
                                            data-toggle="buttons" style="margin-top: -27px;">
                                            <label class="btn btn-outline-secondary font-weight-normal"
                                                :class="{ 'active': filter_with_shipping_location == '1' }" style="font-size: 12px;"
                                                @click="updateOption('filter_with_shipping_location', '1')">
                                                <input type="radio" name="with_shipping_location-options" id="with_shipping_location-with" value="1">
                                                With
                                            </label>
                                            <label class="btn btn-outline-secondary font-weight-normal"
                                                :class="{ 'active': filter_with_shipping_location == '0' }" style="font-size: 12px;"
                                                @click="updateOption('filter_with_shipping_location', '0')">
                                                <input type="radio" name="with_shipping_location-options" id="with_shipping_location-without" value="0"> 
                                                Without
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ar-textfield
                                        label   ="Date Captured"
                                        a_type  ="Date"
                                        v-model ="filterDateCaptured"
                                        :max    ="today"
                                    />
                                </div>

                                <div class="col-md-6">
                                    <ar-textfield
                                        label="Researcher"
                                        a_type="text"
                                        v-model="filter_researcher"
                                        placeholder="Input keywords here"
                                    />
                                </div>
                                <div class="col-md-6">
                                    <ar-textfield
                                        label="QA"
                                        a_type="text"
                                        v-model="filter_qa"
                                        placeholder="Input keywords here"
                                    />
                                </div>
                                <template v-if="['Filed', 'All'].includes($route.query.status)">
                                    <div class="col-md-6">
                                        <ar-textfield
                                            label="Case Number"
                                            a_type="text"
                                            v-model.trim="filter_case_number"
                                            placeholder="Input keywords here"
                                        />
                                    </div>
                                </template>

                                <!-- START Newly added feature ALIAS - 7/21/22-->


                                 <div class="col-md-6">
                                    <ar-textfield
                                        label="Alias"
                                        a_type="text"
                                        v-model="filter_alias"
                                        placeholder="Input Alias here"
                                    />
                                </div>
                                <!-- END Newly added feature ALIAS -->
                                
                                <!-- Remove  Roles and Employee-->
                                <!-- <div class="col-md-6">
                                    <label style="color: #383A3D; font-weight: 500; font-size: 13px;">Roles</label>
                                    <multiselect label="label" track-by="label" v-model="filter_roles.selected"
                                        :options="filter_roles.options" placeholder="Select here" :multiple="true"
                                        :close-on-select="false" :clear-on-select="false">
                                    </multiselect>
                                </div>
                                <div class="col-md-6">
                                    <ar-select 
                                        searchFieldId ="employee_name"
                                        v-model       ="filter_employee.selected" 
                                        label         ="Employee Name"
                                        placeholder   ="Search Employee Name"
                                        type          ="search"
                                        :options      ="filter_employee.options"> Select Employee
                                    </ar-select>
                                </div> -->
                                <div class="col-md-6">
                                    <ar-select
                                        label="Purchase Type"
                                        :options="purchaseTypeOptions"
                                        :with_subOptions="true"
                                        :val="filter_purchase_type"
                                        v-model="filter_purchase_type"
                                        :disabled="purchaseTypeOptions.length == 0 || getDataFor_AdditionalFilters"
                                    >
                                        <!-- Select Purchase Type -->
                                        {{purchaseTypeOptions.length == 0 || getDataFor_AdditionalFilters ? 'Loading data...' : 'Select Purchase Type'}}
                                    </ar-select>
                                </div>
                                <div class="col-md-12 d-flex align-items-end">
                                    <div class="w-100">
                                        <ar-select 
                                            searchFieldId ="item_class"
                                            v-model       ="filter_item_class" 
                                            label         ="Item Class"
                                            :placeholder   ="item_class.length == 0 || getDataFor_AdditionalFilters ? 'Loading data...' : 'Search Item Class'"
                                            type          ="search"
                                            :options      ="item_class"
                                            :disabled="item_class.length == 0 || getDataFor_AdditionalFilters"
                                        >
                                            <!-- Select Item Class -->
                                            {{item_class.length == 0 || getDataFor_AdditionalFilters ? 'Loading data...' : 'Select Item Class'}}
                                        </ar-select>
                                    </div>
                                    <div class="ml-2" style="max-width: 100%; margin-bottom: 13px;">
                                        <div class="btn-group btn-group-toggle"
                                            data-toggle="buttons" style="margin-top: -27px;">
                                            <label class="btn btn-outline-secondary font-weight-normal"
                                                :class="{ 'active': filter_with_item_class == '1' }" style="font-size: 12px;"
                                                @click="updateOption('filter_with_item_class', '1')">
                                                <input type="radio" name="with_item_type-options" id="with_item_type-with" value="1">
                                                With
                                            </label>
                                            <label class="btn btn-outline-secondary font-weight-normal"
                                                :class="{ 'active': filter_with_item_class == '0' }" style="font-size: 12px;"
                                                @click="updateOption('filter_with_item_class', '0')">
                                                <input type="radio" name="with_item_type-options" id="with_item_type-without" value="0"> 
                                                Without
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 d-flex mt-3">
                                    <div class="w-100">
                                        <fieldset class="fieldset-alt" :class="{'disabled': !enable_options.includes('brand_name_on_image_option')}">
                                            <div class="custom-control custom-checkbox legend-alt mb-2 ml-4 pl-3">
                                                    <input class="custom-control-input" type="checkbox" name="enable-options"
                                                        :id="`enable--brand_name_on_image-options`" v-model="enable_options" value="brand_name_on_image_option">
                                                        <label :for="`enable--brand_name_on_image-options`" class="mt-2 custom-control-label"
                                                            style="color: #383A3D; margin-left: 5px; font-weight: 500; font-size: 11px;">Brand Name on Image</label>
                                                </div>
                                            <div class="fieldset-alt-content">
                                                <div class="btn-group btn-group-toggle align-middle"
                                                    data-toggle="buttons" style="margin-top: -27px;">
                                                    <label class="btn btn-outline-secondary font-weight-normal"
                                                        :class="{ 'active': filter_brand_name_on_image == '1' }" style="font-size: 12px;"
                                                        @click="updateOption('filter_brand_name_on_image', '1')">
                                                        <input type="radio" name="brand_name_on_image-options" id="brand_name_on_image-with" value="1">
                                                        Yes
                                                    </label>
                                                    <label class="btn btn-outline-secondary font-weight-normal"
                                                        :class="{ 'active': filter_brand_name_on_image == '0' }" style="font-size: 12px;"
                                                        @click="updateOption('filter_brand_name_on_image', '0')">
                                                        <input type="radio" name="brand_name_on_image-options" id="brand_name_on_image-without" value="0">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div class="w-100 ml-3">
                                        <fieldset class="fieldset-alt" :class="{'disabled': !enable_options.includes('logo_on_image_option')}">
                                            <div class="custom-control custom-checkbox legend-alt mb-2 ml-4 pl-3">
                                                <input class="custom-control-input" type="checkbox" name="enable-options"
                                                    :id="`enable--logo_on_image-options`" v-model="enable_options" value="logo_on_image_option">
                                                    <label :for="`enable--logo_on_image-options`" class="mt-2 custom-control-label"
                                                        style="color: #383A3D; margin-left: 5px; font-weight: 500; font-size: 11px;">Logo on Image</label>
                                            </div>
                                            <div class="fieldset-alt-content">
                                                <div class="btn-group btn-group-toggle align-middle"
                                                    data-toggle="buttons" style="margin-top: -27px;">
                                                    <label class="btn btn-outline-secondary font-weight-normal"
                                                        :class="{ 'active': filter_logo_on_image == '1' }" style="font-size: 12px;"
                                                        @click="updateOption('filter_logo_on_image', '1')">
                                                        <input type="radio" name="logo_on_image-options" id="logo_on_image-with" value="1">
                                                        Yes
                                                    </label>
                                                    <label class="btn btn-outline-secondary font-weight-normal"
                                                        :class="{ 'active': filter_logo_on_image == '0' }" style="font-size: 12px;"
                                                        @click="updateOption('filter_logo_on_image', '0')">
                                                        <input type="radio" name="logo_on_image-options" id="logo_on_image-without" value="0"> 
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>

                                <div class="col-md-6 d-flex mt-3">
                                    <div class="w-100">
                                        <fieldset class="fieldset-alt" :class="{'disabled': !enable_options.includes('will_ship_to_us_option')}">
                                            <div class="custom-control custom-checkbox legend-alt mb-2 ml-4 pl-3">
                                                    <input class="custom-control-input" type="checkbox" name="enable-options"
                                                        :id="`enable--will_ship_to_us-options`" v-model="enable_options" value="will_ship_to_us_option">
                                                        <label :for="`enable--will_ship_to_us-options`" class="mt-2 custom-control-label"
                                                            style="color: #383A3D; margin-left: 5px; font-weight: 500; font-size: 11px;">Will Ship to US</label>
                                                </div>
                                            <div class="fieldset-alt-content">
                                                <div class="btn-group btn-group-toggle align-middle"
                                                    data-toggle="buttons" style="margin-top: -27px;">
                                                    <label class="btn btn-outline-secondary font-weight-normal"
                                                        :class="{ 'active': filter_will_ship_to_us == '1' }" style="font-size: 12px;"
                                                        @click="updateOption('filter_will_ship_to_us', '1')">
                                                        <input type="radio" name="will_ship_to_us-options" id="will_ship_to_us-with" value="1">
                                                        Yes
                                                    </label>
                                                    <label class="btn btn-outline-secondary font-weight-normal"
                                                        :class="{ 'active': filter_will_ship_to_us == '0' }" style="font-size: 12px;"
                                                        @click="updateOption('filter_will_ship_to_us', '0')">
                                                        <input type="radio" name="will_ship_to_us-options" id="will_ship_to_us-without" value="0">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div class="w-100 ml-3">
                                        <fieldset class="fieldset-alt" :class="{'disabled': !enable_options.includes('commission_option')}">
                                            <div class="custom-control custom-checkbox legend-alt mb-2 ml-4 pl-3">
                                                <input class="custom-control-input" type="checkbox" name="enable-options"
                                                    :id="`enable--commission-options`" v-model="enable_options" value="commission_option">
                                                    <label :for="`enable--commission-options`" class="mt-2 custom-control-label"
                                                        style="color: #383A3D; margin-left: 5px; font-weight: 500; font-size: 11px;">Commission</label>
                                            </div>
                                            <div class="fieldset-alt-content">
                                                <div class="btn-group btn-group-toggle align-middle"
                                                    data-toggle="buttons" style="margin-top: -27px;">
                                                    <label class="btn btn-outline-secondary font-weight-normal"
                                                        :class="{ 'active': filter_commission == '1' }" style="font-size: 12px;"
                                                        @click="updateOption('filter_commission', '1')">
                                                        <input type="radio" name="filter_commission-options" id="filter_commission-with" value="1">
                                                        With
                                                    </label>
                                                    <label class="btn btn-outline-secondary font-weight-normal"
                                                        :class="{ 'active': filter_commission == '0' }" style="font-size: 12px;"
                                                        @click="updateOption('filter_commission', '0')">
                                                        <input type="radio" name="filter_commission-options" id="filter_commission-without" value="0"> 
                                                        Without
                                                    </label>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                                <div class="mt-3 col-md-12 d-flex align-items-end">
                                    <div class="w-100 mb-3">
                                        <label
                                        style="color: #383A3D; font-weight: 500; font-size: 13px;">Item Types</label>
                                        <multiselect label="label" track-by="label" v-model="filter_item_type"
                                            :options="itemTypeOptions" placeholder="Select here" :multiple="true"
                                            :close-on-select="false" :clear-on-select="false">
                                        </multiselect>
                                    </div>
                                    <div class="ml-2" style="max-width: 100%; margin-bottom: 13px;">
                                        <div class="btn-group btn-group-toggle"
                                            data-toggle="buttons" style="margin-top: -27px;">
                                            <label class="btn btn-outline-secondary font-weight-normal"
                                                :class="{ 'active': filter_with_item_type == '1' }" style="font-size: 12px;"
                                                @click="updateOption('filter_with_item_type', '1')">
                                                <input type="radio" name="with_item_type-options" id="with_item_type-with" value="1">
                                                With
                                            </label>
                                            <label class="btn btn-outline-secondary font-weight-normal"
                                                :class="{ 'active': filter_with_item_type == '0' }" style="font-size: 12px;"
                                                @click="updateOption('filter_with_item_type', '0')">
                                                <input type="radio" name="with_item_type-options" id="with_item_type-without" value="0"> 
                                                Without
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <fieldset class="mb-4 mt-2 fieldset-alt">
                                <label class="mt-2 legend-alt" style="color: #383A3D; margin-left: 5px; font-weight: 500; font-size: 13px;">Payment Methods</label>
                                <div class="row">
                                    <div class="col-md-4 pl-3" v-for="method in payment_methods" :key="`payment-method--${method.value}`">
                                        <div class="custom-control custom-checkbox mb-2">
                                            <input class="custom-control-input" type="checkbox" name="method" :id="`payment-method--${method.value}`" v-model="selected_payment_methods" :value="method.label">
                                            <label :for="`payment-method--${method.value}`" class="custom-control-label font-weight-normal">{{method.label}}</label>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset class="mb-4 fieldset-alt">
                                <div class="mb-3">
                                    <label class="mt-2 d-inline-block align-middle legend-alt" style="color: #383A3D; margin-left: 5px; font-weight: 500; font-size: 13px;">Infringement Indicators</label>
                                    <div class="btn-group btn-group-toggle ml-3 align-middle legend-alt" data-toggle="buttons">
                                        <label class="btn btn-outline-secondary font-weight-normal" :class="{'active': infringement_option == '1'}" style="font-size: 13px;" @click="updateOption('infringement_option', '1')">
                                            <input type="radio" name="infringement-options" id="infringment-with" value="1"> With
                                        </label>
                                        <label class="btn btn-outline-secondary font-weight-normal" :class="{'active': infringement_option == '0'}" style="font-size: 13px;" @click="updateOption('infringement_option', '0')">
                                            <input type="radio" name="infringement-options" id="infringment-without" value="0"> Without
                                        </label>
                                    </div>
                                </div>
                                <div class="row pl-2 pb-2 pr-2">
                                    <div class="col-md-6 pl-2" v-for="reason in reasons" :key="`reason--${reason.value}`">
                                        <div class="custom-control custom-checkbox mb-2">
                                            <input class="custom-control-input" type="checkbox" name="reasons" :id="`reason--${reason.value}`" v-model="selected_reasons" :value="reason.value">
                                            <label :for="`reason--${reason.value}`" class="custom-control-label font-weight-normal">{{reason.label}}</label>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset class="fieldset-alt" v-show="filter_status_change_reason.options.length && currentStatus !== -1">
                                <div class="mb-3">
                                    <label class="mt-2 d-inline-block align-middle legend-alt"
                                        style="color: #383A3D; margin-left: 5px; font-weight: 500; font-size: 13px;">Status Change Reasons</label>
                                    <div class="btn-group btn-group-toggle ml-3 align-middle legend-alt"
                                        data-toggle="buttons">
                                        <label class="btn btn-outline-secondary font-weight-normal"
                                            :class="{ 'active': filter_with_status_change_reason == '1' }" style="font-size: 13px;"
                                            @click="updateOption('filter_with_status_change_reason', '1')">
                                            <input type="radio" name="with_status_change_reason-options" id="with_status_change_reason-with" value="1">
                                            With
                                        </label>
                                        <label class="btn btn-outline-secondary font-weight-normal"
                                            :class="{ 'active': filter_with_status_change_reason == '0' }" style="font-size: 13px;"
                                            @click="updateOption('filter_with_status_change_reason', '0')">
                                            <input type="radio" name="with_status_change_reason-options" id="with_status_change_reason-without"
                                                value="0"> Without
                                        </label>
                                    </div>
                                </div>
                                <div class="row pl-2 pb-2 pr-2">
                                    <div class="col-md-6 pl-2" v-for="reason in filter_status_change_reason.options" :key="`status-reason--${reason.value}`">
                                        <div class="custom-control custom-checkbox mb-2">
                                            <input class="custom-control-input" type="checkbox" name="status-reasons"
                                                :id="`status-reason--${reason.value}`" v-model="filter_status_change_reason.selected"
                                                :value="reason.value">
                                            <label :for="`status-reason--${reason.value}`"
                                                class="custom-control-label font-weight-normal">{{ reason.label }}</label>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        <div class="float-right mt-3 mr-3">
                            <ar-button id="reset-more-filter-btn" a_class="btn-invalid mr-2" @click="resetFilters">Reset</ar-button>
                            <ar-button
                                id="apply-more-filter-btn"
                                @click="applyFilters">
                                Apply
                                <!-- :disabled="computed_selected_campaign == 0 && filter_seller_url !== ''" -->
                            </ar-button>
                        </div>
                    </div>
                </template>
                <div class="qa-selected">
                    <template v-if="canProceedToExport">
                        <strong class="mr-4 with-separator">Export to:</strong>
                        <a
                            href="javascript:void(0);"
                            class="action-item mr-2 mr-5"
                            title="Export to Excel"
                            @click="downloadExcel(campaign.name, $route.params.campaign_id,selected_listings)"
                        >
                            <img src="/static/svg/Action_Excel.svg" alt="export">
                        </a>
                    </template>
                    <!-- v-if="canBulkMerge && checkSelectedForBulkMerge" -->
                    <a
                        v-if        ="checkSelectedForBulkMerge"
                        href        ="javascript:void(0);"
                        class       ="mr-2 action-item"
                        data-toggle="modal"
                        data-target="#merge-modal"
                        data-backdrop="static"
                        data-keyboard="false"
                        @click       ="resetMergeData()"
                        title       ="Merge selected listings' evidences">
                        <img src="/static/svg/Action_Merge.svg" alt="merge">
                    </a>
                    <a
                        v-show      ="!disabledReassignAccounts"
                        href        ="javascript:void(0);"
                        class="action-item mr-2 v-status-change-reassign"
                        v-restrict="'v-status-change-reassign'"
                        :class      ="{'disabled': !checkSelectedAuth || !checkSelectedStatusForReassignmentIsSame}"
                        @click      ="showReassignModal()"
                        v-tooltip    =" !checkSelectedAuth
                            ? 'You are not allowed to reassign'
                            : !checkSelectedStatusForReassignmentIsSame
                                ? 'Bulk reassignment disabled on selected listings with different statuses.' : 'Reassign Listing(s)'">
                        <img src="/static/svg/Action_Reassign.svg" alt="reassign">
                    </a>

                    <!-- <a
                        v-if        ="enableInvalidTagging"
                        href        ="javascript:void(0);"
                        class       ="mr-4 action-item v-status-change-invalid"
                        data-toggle ="modal"
                        data-target ="#set-invalid-modal"
                        title       ="Mark as Invalid"
                        @click      ="getChangeStatus(99, 'Invalid'); $store.dispatch('resetPredefinedReasonState')">
                        <img src="/static/svg/Action_Invalid.svg" alt="invalid">
                    </a> -->
                </div>
            </ar-titlebar>
        </div>

        <portal>
            <ArReassignmentUnsuccessfulModal :reassignment__data="reassign__unsuccessful" />
        </portal>

        <!--                             
            Deliverable #552: Removal of Invalid status from Artemis
        -->
        <!-- <ar-dialog
            id="set-invalid-modal"
            heading="Mark as Invalid"
            heading_style="color: #DC3C40;"
            sub_heading="Please select a reason for changing listing's status to invalid"
            custom_maxwidth="100%"
            custom_width="1200px"
        >
            < !-- <ar-textfield
                a_type="textarea"
                a_class="text-left"
                a_style="height: 100px; resize: none"
                label="Comment"
                placeholder="Comment here"
                v-model.trim="batch_comment"
            /> -- >
            <div
                class="float-left text-left w-100 overflow-auto"
                style="min-height: 50px; max-height: 350px"
            >
                <ar-reason-selection :reasons="predefined_reasons" key="search-invalid-modal-reasons"/>
            </div>
            <template v-slot:buttons>
                <ar-button
                    a_class="mr-2"
                    data-dismiss="modal"
                    id="close-invalid-modal"
                    :outlined="true"
                    @click="batch_comment = '';"
                    :disabled="is_loading"
                >
                    Cancel
                </ar-button>
                <ar-button
                    @click="batchInvalid"
                    :disabled="is_loading"
                >
                    {{is_loading ? 'Loading...' : 'Finish'}}
                </ar-button>
            </template>
        </ar-dialog> -->

        <!-- <ar-dialog
            id="reassign-searchpage-modal"
            :heading="'Reassign to another '+reassignment_mode"
            :custom_maxwidth="'100%'"
            :custom_width="isReassignWithReason ? '1200px' : '1200px'"
            v-if="!isCLAccount"
        >
            <template v-if="selected_listings.length > 0">
                <div class="row text-left">
                    <div class="col-md-4">
                        <div class="col-md-12" v-if="reassignment_mode == 'Researcher'">
                            <ar-select type="search" id="reassign-status-select" placeholder="Select Status" label="Status" v-model.number="reassign_status" :options="reassign_status_options"/>
                        </div>
                        <div class="col-md-12">
                            <ar-select type="search" id="reasssign-user" :placeholder="'Select '+reassignment_mode" :label="reassignment_mode" v-model.number="reassign_researcher"  @input="getResearchersCampaigns(reassign_researcher)" :options="filtered_reasearchers"/>
                        </div>
                        <div class="col-md-12" v-if="reassignment_mode == 'Researcher'">
                            <ar-select type="search" id="reassign-campaign" placeholder="Select Campaign" label="Campaign" v-model.number="reassign_campaign" :disabled="researchers_campaigns_select.length == 0" :options="researchers_campaigns_select"/>
                        </div>
                    </div>
                    <div class="col-md-12" v-if="reassignment_mode == 'Researcher'">
                        <ar-select type="search" id="reassign-campaign" placeholder="Select Campaign" label="Campaign" v-model.number="reassign_campaign" :disabled="!researchers_campaigns_select.length" :options="researchers_campaigns_select"/>
                    <div class="col-md-8">
                        <div
                            class="float-left text-left w-100 overflow-auto"
                            style="min-height: 50px; max-height: 350px"
                        >
                            <ar-reason-selection :reasons="predefined_reasons" key="reassign-search-modal-reasons"/>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:buttons>
                    <ar-button a_class="mr-2" data-dismiss="modal" :outlined="true">Cancel</ar-button>
                    <ar-button a_class="mr-2" @click="reassignListing(selected_listings,reassign_status,reassign_campaign,reassign_researcher)">Confirm</ar-button>
            </template>
            <template v-if="[439,271,221].includes(user.id)" v-slot:footer>
                <ar-select
                    v-model  ="reassignment_mode"
                    :val     ="reassignment_mode"
                    :options ="[{'value':'Researcher','label':'Researcher'},{'value':'QA','label':'QA'}]"
                    first_element_as_default
                />
            </template>
        </ar-dialog> -->

        <ar-dialog
            id="reassign-searchpage-modal"
            :custom_maxwidth="'100%'"
            :custom_width="isReassignWithReason || reassignStatuses.results.length ? '1200px' : '500px'"
            :mdStyle="{ 'transition' : '0.3s ease' }"
            :xxLarge="!!isReassignWithReason || !!reassignStatuses.results.length"
            :has_footer="false"
            data-backdrop="static"
        >
        <!-- && this.isLoading_existing_listings==false -->
            <template v-if="reassignStatuses.results.length">
                <h5 class="d-inline-block ml-2 align-middle">
                    Reassignment Status
                    <span class="d-block font-weight-normal mt-1">Below are the unsuccessful reassignment of listings in the request and their reasons.</span>
                </h5>
                <table class="table-scroll mb-2">
                    <thead>
                        <template v-if="!hasExisting_listings">
                            <tr>
                                <th class="col-md-1" scope="col">LID</th>
                                <th class="col-md-5" scope="col">Seller Name</th>
                                <th class="col-md-6" scope="col">Message (hover to see message)</th>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <th class="col-md-1" scope="col">LID</th>
                                <th class="col-md-2" scope="col">Seller Name</th>
                                <th class="col-md-6" scope="col">Message (hover to see message)</th>
                                <th class="col-md-3" scope="col">Existing Listing(s)</th>
                            </tr>
                        </template>
                        <!-- <tr>
                            <th class="col-md-1" scope="col">LID</th>
                            <th class="col-md-5" scope="col">Seller Name</th>
                            <th class="col-md-6" scope="col">Message (hover to see message)</th>
                        </tr>
                        <tr>
                            <th class="col-md-1" scope="col">LID</th>
                            <th class="col-md-2" scope="col">Seller Name</th>
                            <th class="col-md-6" scope="col">Message (hover to see message)</th>
                            <th class="col-md-3" scope="col">Existing Listing(s)</th>
                        </tr> -->
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in reassignStatuses.results" :key="index">
                            <template v-if="!hasExisting_listings">
                                <td class="col-md-1 text-bold" scope="row">
                                    <span class="status not_found">{{ item.reason.lid }}</span>
                                </td>
                                <td class="col-md-5">
                                    <span class="d-block text-truncate">
                                        {{ item.reason.seller }}
                                    </span>
                                </td>
                                <td class="col-md-6">
                                    <span class="d-block text-truncate" :title="item.reason.message.message">{{ item.reason.message.message }}</span>
                                </td>
                            </template>
                            <template v-else>
                                <td class="col-md-1 text-bold" scope="row">
                                    <span class="status not_found">{{ item.reason.lid }}</span>
                                </td>
                                <td class="col-md-2">
                                    <span class="d-block text-truncate">
                                        {{ item.reason.seller }}
                                    </span>
                                </td>
                                <td class="col-md-6">
                                    <span class="d-block text-truncate" :title="item.reason.message.message">{{ item.reason.message.message }}</span>
                                </td>
                                <td class="col-md-3 existing-listings-container">
                                    <div class="existing-listings-item-container">
                                        <!-- listing ID in the Title -->
                                        <span 
                                            v-for="(existing_listing, index) in item.existing_listings?.duplicate"
                                            class="d-block mb-1"
                                            :title="`Click to copy ${existing_listing.lid} or Control + Click to redirect to Search Page`"
                                            :key="index"
                                        >
                                            <a 
                                                href="javascript:void(0);"
                                                class="existing-btn text-truncate"
                                                @click.exact="existing_listing == undefined ? '' :  copytoClipboard(existing_listing.lid)"
                                                @click.ctrl="redirectTo_searchPage(existing_listing.lid, existing_listing.qflag_id_word)"
                                            >
                                                {{existing_listing == undefined ? `No Existing Listing(s)` : `${existing_listing.lid} (${existing_listing.qflag_id_word})`}}
                                            </a>
                                        </span>
                                    </div>
                                </td>
                            </template>
                        </tr>
                    </tbody>
                </table>

                <div class="btn-con float-right mt-3">
                    <ar-button
                        outlined
                        data-dismiss="modal"
                        style="color: #202229; border-color: #202229;"
                        @click="individualReassign_hasError = false"
                    >Cancel</ar-button>
                    <ar-button class="ml-2" @click="individualReassign_hasError ? resetIndividualReassignment() : resetReassignData()">
                        Go Back
                    </ar-button>
                </div>
            </template>
            <!-- v-else -->
            <div v-if="reassignStatuses.results.length == 0 && !individualReassign_hasError" class="d-flex text-left justify-content-between" :class="{'withReason': isReassignWithReason}">
                <div class="pr-4" :class="{'border-right': isReassignWithReason, 'w-100': !isReassignWithReason}">
                    <div class="mb-2 reassign-left-heading" :class="{'text-center': !isReassignWithReason}">
                        <!--<img :src="diha" class="d-inline-block align-middle reassign-campaign-img">-->
                        <h5 class="d-inline-block ml-2 align-middle">
                            <b :style="`color: #${reassignment_mode == 'Researcher' ? '2D599E' :'33ABA2'};`">
                                Reassign to other {{isQAAccount ? 'User' : reassignment_mode}}
                            </b>
                            <span class="mt-1 d-block font-weight-normal" :style=" !isReassignWithReason ? 'font-size:14px;' : ''">
                                Select from the list of {{isQAAccount ? 'users' : reassignment_mode }} below to reassign products(s).
                            </span>
                        </h5>
                    </div>

                    <div class="left-con">
                        <div class="row">
                            <!-- <div class="col-md-12"> -->
                                <!-- <multiselect
                                    v-if="isQAAccount"
                                    v-model="reassign_researcher"
                                    :options="filtered_campaign_users"
                                    group-values="groupValues"
                                    group-label="groupLabel"
                                    placeholder="Type to search"
                                    track-by="label"
                                    label="label"
                                    class="mt-3 w-100 mx-auto"
                                >
                                    <span slot="noResult">Oops! No users found. Consider changing the search query.</span>
                                </multiselect> -->
                                <!-- v-else -->
                                <!-- :placeholder ="`Select ${isQAAccount ? 'User' : reassignment_mode}`" -->
                                <!-- :label ="isQAAccount ? 'User' : reassignment_mode" -->
                                <ar-select
                                    v-restrict     ="'v-reassignment-account'"
                                    class          ="col-md-12 v-reassignment-account"
                                    type           ="search"
                                    id             ="reassign-user"
                                    placeholder    ="Select Account"
                                    label          ="Account"
                                    v-model.number ="reassign_researcher"
                                    :options       ="filtered_researchers"
                                />
                            <!-- </div> -->
                            <!-- <template v-if="reassignment_mode == 'Researcher'"> -->
                                <!-- <div class="col-md-12" v-if="canReassignWithCampaign"> -->
                                <div class="col-md-12 v-reassignment-campaign" v-restrict="'v-reassignment-campaign'">
                                    <ar-select
                                        type        ="search"
                                        id          ="reassign-campaign"
                                        placeholder ="Select Campaign"
                                        label       ="Campaign"
                                        v-model     ="reassign_campaign"
                                        :options    ="campaigns_select"
                                        :disabled   ="!campaigns_select.length"
                                    >
                                        Select Campaign
                                    </ar-select>
                                </div>
                                <!-- <div class="col-md-12" v-if="canReassignWithStatus"> -->
                                <div class="bulk-reassign-status-and-substatus-con w-100">
                                    <div class="col-md-12 v-reassignment-status" v-restrict="'v-reassignment-status'">
                                        <ar-select
                                            type        ="search"
                                            placeholder ="Select Status"
                                            class="mb-0"
                                            textfield_class="mb-0"
                                            label       ="Status"
                                            id          ="reassign-status-select"
                                            v-model     ="reassignStatuses.selected"
                                            :options    ="reassignStatusOptions"
                                            :disabled   =" !reassignStatusOptions.length"
                                        >
                                            Select Status
                                        </ar-select>
                                    </div>
                                    <template v-if="reassignmentBulkSubStatusOptions?.filter(s => s.id)?.length">
                                        <ar-toggle-btn>
                                            <div class="sub-statuses-container">
                                                <label class="ml-2">Select a sub status</label>
                                                <div 
                                                    v-for="(sub, sub_index) in reassignmentBulkSubStatusOptions"
                                                    class="btn sub-status d-flex align-items-center reassignment"
                                                    style="padding-left: 37px !important;"
                                                    :key="`bulkReassignSubStatus--${sub_index}`"
                                                    @click="toggleSubStatusReassignment($event, sub.id ?? -1)"
                                                >
                                                    <h5>{{sub.label}}</h5>
                                                    <input 
                                                        type ="radio" 
                                                        :name ="`bulkReassignSubStatus--sub-status`"
                                                        :id  ="`bulkReassignSubStatus--sub-status-${sub.id}`"
                                                        v-show="false"
                                                    >
                                                </div>
                                            </div>
                                        </ar-toggle-btn>
                                    </template>
                                </div>
                            <!-- </template> -->
                        </div>
                    </div>
                </div>

                <div class="pt-0 pl-4 reassign-right-con" v-if="isReassignWithReason">
                    <div class="reassign-right-heading d-flex justify-content-between align-items-center">
                        <h5> Selected ({{ selected_listings.length }}) listing(s)
                            <span class="mt-1 d-block font-weight-normal">Please select reason(s) to continue reassignment</span>
                        </h5>
                    </div>

                    <div class="right-con">
                        <div class="reassign-to-wip-notice mt-4 mb-4" v-show="isReassignWIP">
                            <a class="notice-img">
                                <img onerror="this.src='/static/svg/Notice_SVG.svg'" src="/static/svg/Notice_SVG.svg" alt="woman-smiling-with-notice">
                            </a>
                            <h6>Notice</h6>
                            <p>These data has already been processed and validated by a recognized third party.</p>
                        </div>
                        <ar-reason-selection :reasons="predefined_reasons" is-batch-modal key="reassign-to-wip-modal-reasons"/>
                    </div>
                </div>
            </div>
            <template v-slot:buttons v-if="!reassignStatuses.results.length">
                <ar-button
                    a_class      ="mr-2"
                    data-dismiss ="modal"
                    :outlined    ="true"
                >
                    Cancel
                </ar-button>
                <ar-button :disabled="reassignStatuses.loading" @click="doNewReassign()">
                    Reassign
                </ar-button>
            </template>
            <!-- <template v-slot:footer>
                <ar-select
                    v-if     ="['Reworked','Recheck'].includes($route.query.status) && isAllowedQAReassignment"
                    v-model  ="reassignment_mode"
                    :val     ="reassignment_mode"
                    :options ="[{'value':'Researcher','label':'Researcher'},{'value':'QA','label':'QA'}]"
                    first_element_as_default
                />
            </template> -->
        </ar-dialog>

        <ar-dialog
            id ="duplicate-listing-modal"
            heading ="Duplicate Listing Found"
            heading_style ="color: #DC3C40"
            sub_heading ="Listing(s) below will not be updated"
            :has_footer ="false"
            :has_button ="false"
            large
        >
            <div class="overflow-auto" style="max-height: 400px;">
                <div id="duplicate-listing-accordion" class="accordion">
                    <div v-for="(listing, index) in duplicate_listings" class="card mb-0" :key="listing.id">
                        <div
                            :id ="`heading-${listing.id}`"
                            class ="card-header collapsed"
                            data-toggle ="collapse"
                            :data-target ="`#collapse-${listing.id}`"
                            :aria-expanded ="!index"
                            :aria-controls ="`#collapse-${listing.id}`"
                        >
                            <h6 class="text-left mb-0">
                                {{ listing.id }}
                                <span class="ml-1 text-primary">( {{ listing.duplicates.length }} )</span>
                            </h6>
                        </div>
                        <div
                            :id ="`collapse-${listing.id}`"
                            class ="slow p-0 collapse"
                            :class ="{'show': !index}"
                            :aria-labelledby ="`heading-${listing.id}`"
                            data-parent ="#duplicate-listing-accordion"
                        >
                            <div class="card-body p-0">
                                <div class="row">
                                    <div v-for="duplicate in listing.duplicates" class="col-md-4" :key="duplicate.id">
                                        <div class="a-modal-seller-info-item">
                                            <h5>
                                                <span class="text-truncate text-center">{{ duplicate.name }}</span>
                                                <span class="text-primary text-center cursor-pointer" @click="searchListing(duplicate.id)">{{ duplicate.id }}</span>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ar-button
                @click ="duplicateListingModalOnContinue"
                data-dismiss ="modal"
                class ="mt-1 float-right"
                outlined
            >
                Continue
            </ar-button>
        </ar-dialog>

        <ar-dialog id="merge-modal" large>
            <div class="text-left">
                <h5 class="heading">
                    <template v-if="mergeStatus.ongoing">
                        <b style="font-weight: 500; color: #2D599E;">Now Merging</b>
                        <ar-progressbar
                            size="lg"
                            background="#8B9DC3"
                            :percent="merge_percentage_status"
                            class="mt-1"
                        />
                    </template>
                    <template v-else-if="mergeStatus.error">
                        <b style="font-weight: 500; color: #DC3C40">There was an error encountered when trying to merge</b> <span>You can retry merging the failed processes.</span>
                    </template>
                    <template v-else-if="mergeStatus.done">
                        Merging Completed <span>You can Download each file now or choose to Download All.</span>
                    </template>
                    <template v-else>
                        Generate Full Merged Files <span>Selected {{ selected_listings.length }} Listing(s)</span>
                    </template>
                </h5>
                <hr class="mt-0"/>
                <div class="list-con p-1">
                    <div
                        v-for          ="(list, index) in for_merging_lists"
                        :key           ="index"
                        class          ="list-item-con position-relative"
                    >
                        <ar-list
                            v-if           ="!error_merge_only || (error_merge_only && list.merge_data != 200)"
                            :product_img   ="list.product_img"
                            :product_url   ="list.product_url"
                            :seller_name   ="list.seller_name"
                            :if_reassigned ="list.if_reassigned"
                            :if_commission ="list.commission"
                            :rid           ="list.rid"
                            :progress      ="list.progress"
                            :status        ="list.status"
                            :details       ="list.details"
                            :platform      ="list.platform"
                            :platform_img  ="list.platform_img"
                            :campaign_img  ="list.campaign_img"
                            :campaign      ="list.campaign"
                            :seller_url    ="list.seller_url"
                            :owner         ="list.owner"
                            :qualifier     ="list.qualifier"
                            :case_number   ="list.case_number"
                            :has_checkbox  ="false"
                            class          ="animated fadeIn fast"
                            :has_platform="false"
                            :has_researcher="false"
                            :invalid       ="list.merge_status && ![102, 200, 205].includes(list.merge_status)"
                        />
                        <a href="javascript:void(0);" v-if="list.merge_status == 200" @click="downloadMerged(list)" :disabled="downloading_merge_file" class="download-merge" title="Download">
                            <img src="/static/svg/DownloadMerge.svg" alt="download">
                        </a>
                        <span v-else-if="list.merge_status == 102" class="fa-2x download-merge loading-icon d-inline-block">
                            <div class="lds-ripple"><div></div><div></div></div>
                        </span>
                        <a href="javascript:void(0);" v-else-if="list.merge_status && ![102, 200, 205].includes(list.merge_status)" @click="retryMerge(list)" class="download-merge" title="Retry">
                            <img src="/static/svg/Retry.svg" alt="retry">
                        </a>
                    </div>
                </div>
            </div>
            <template v-slot:buttons>
                    <ar-button a_class="mr-2" v-if="!mergeStatus.ongoing" data-dismiss="modal" :outlined="true">Cancel</ar-button><!-- for closing the modal -->
                    <ar-button a_class="mr-2" v-else @click="cancelMerging()" :disabled="cancel_merging" :outlined="true">Cancel</ar-button><!-- for cancelling the merging process -->
                    <ar-button a_class="mr-2" v-if="mergeStatus.error && !mergeStatus.ongoing" @click="error_merge_only = !error_merge_only" :outlined="!error_merge_only">{{ error_merge_only ? 'Errors Only' : 'All Listings' }}</ar-button>
                    <ar-button a_class="mr-2" v-if="mergeStatus.error && !mergeStatus.ongoing" style="background: #29ABA2" @click="downloadAllMerged()">Download All Successful</ar-button>
                    <ar-button a_class="mr-2" v-if="mergeStatus.error && !mergeStatus.ongoing" @click="startBulkMerge()">Retry Merging</ar-button>
                    <ar-button a_class="mr-2" v-else-if="mergeStatus.done" :disabled="downloading_merge_file" style="background: #29ABA2" @click="downloadAllMerged()">{{ downloading_merge_file ? 'Processing...' : 'Download Alls' }}</ar-button>
                    <ar-button a_class="mr-2" v-else @click="startBulkMerge()" :disabled="mergeStatus.ongoing">{{ mergeStatus.ongoing ? 'Processing...' : 'Start Merging Files' }}</ar-button>
            </template>
        </ar-dialog>

        <ar-modal id="listing-modal">
            <template v-slot:top-btn>
                <ar-button
                    a_class="a-btn-text mr-2"
                    data-dismiss="modal"
                    icon="fas fa-chevron-left"
                >
                    <span>{{active_campaign}}</span>
                </ar-button>
                <template v-if="isListingInfoReady && active_listing_info[0]?.qflag_id == 99 && ![7, 5].includes(access_level)"></template>
                <!-- <template v-if="checkAuthorization"> -->
                <template v-else-if="isListingInfoReady">
                    <ar-button
                        v-restrict="'v-status-change-reassign'"
                        class="v-status-change-reassign"
                        v-show = !disabledReassignAccounts
                        :a_class="`a-btn-text mr-3 float-right ${!status_history_tab ? 'active' : ''}`"
                        icon="fas fa-user-tag"
                        @click="switchHistoryAndReassign(true)">
                        Reassign
                    </ar-button>
                    <ar-button
                        :a_class="`a-btn-text mr-3 float-right ${status_history_tab ? 'active' : ''}`"
                        icon="fas fa-redo"
                        @click="switchHistoryAndReassign(false)">
                        Status History
                    </ar-button>
                </template>
                <ar-button
                    v-if    ="isListingInfoReady"
                    a_class ="a-btn-text mr-3 float-right"
                    icon    ="fas fa-redo"
                    @click  ="refreshData(active_listing_id)"
                >
                    Refresh Data
                </ar-button>
            </template>
            <template v-if="!isListingInfoReady">
                <div class="listing-loading-state">
                    <img src="/static/img/Modal-Loader.gif" alt="loading">
                </div>
            </template>
            <template v-else>
                <div v-for="(list, index) in active_listing_info" :key="index" class="animated fadeIn">
                    <section>
                        <div class="row">
                            <div v-show="!show_predefined_reasons" class="col-md-7 col-xs-12">
                                <div class="a-modal-seller-info">
                                    <a :href="preview()" target="_blank">
                                        <div class="img-container">
                                            <img :src="preview()" onerror="this.src='/static/svg/IMG_BROKEN.svg'" alt="default listing photo">
                                            <img class="campaign-preview" :src="$parent.getImage('campaigns', list.campaign|| 'Default')" alt="default campaign photo">
                                        </div>
                                    </a>
                                    <div class="seller-details">
                                        <a :href="list.seller_url" :target="list.seller_url != 'javascript:void(0);' || list.seller_url != '' ? '_blank' : '_self'">
                                            <img :src="$parent.getImage('platforms', list.list_platform || 'Default')" alt="default campaign photo">
                                        </a>
                                        <h5>{{list.seller_name}} <span>{{list.rid}} - {{list.status}} {{ `${list.status == 'Filed' ? '- Case Number: ' + list.case_number : ''}` }}</span></h5>
                                        <p class="mb-4">{{list.list_title}}</p>
                                        <a class="mb-4" :href="list.list_url" target="_blank">{{list.list_url}}</a>
                                    </div>
                                    <span  title="Researcher" class="text-danger ml-2">
                                        <img data-v-31aba694="" src="/static/svg/Users_Researchers.svg" alt="researchers" style="width: 11px; position: relative; top: -2px; margin: 0px 2px 0px 4px;" />
                                        {{list.owner}}
                                    </span>
                                    <span title="Date Captured" class="has-bullet-separator ml-2 pl-3" v-show="Object.entries(list.evidences).length > 0">
                                        <!-- <img data-v-31aba694="" src="/static/svg/Users_Researchers.svg" alt="researchers" style="width: 11px; position: relative; top: -2px; margin: 0px 2px 0px 4px;" /> -->
                                        <b>Date Captured:</b> {{list.date_captured || 'Not Set'}}
                                    </span>
                                    <!-- <span class="badge badge-pill badge-secondary font-weight-normal text-capitalize" title="Researcher" style="font-size:100%"><i class="fas fa-user-circle"></i> {{list.owner}}</span> -->
                                </div>
                            </div>
                            <div :class="show_predefined_reasons ? 'col-md-12' : 'col-md-5'" class="col-xs-12" > <!--History and Status Container-->
                                <div class="a-modal-status-history-con">
                                    <div v-if="listingNote.status">
                                        <h5 class="mb-4">Notes</h5>
                                        <div class="a-modal-status-history mb-3" :class="{ 'text-center': !listingNote.data.length }">
                                            <template v-if="listingNote.is_loading">
                                                <div class="text-center mt-5">
                                                    <div v-for="(spin,index) in [1,2,3]" :key="index" class="spinner-grow text-dark" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                    <span class="d-block mt-3">Getting Notes..</span>
                                                </div>
                                            </template>
                                            <template v-else-if="listingNote.data.length">
                                                <ar-list
                                                    type         ="history"
                                                    v-for        ="(note, index) in filterHistory(listingNote.data)"
                                                    :key         ="index"
                                                    :subject     ="name(note.user[0] != null && note.user[0].first_name != null ? note.user[0].first_name : '', note.user[0] != null && note.user[0].last_name != null ? note.user[0].last_name : '')"
                                                    :details     ="note.text"
                                                    :date        ="note.created_at"
                                                    status_icon ="/static/svg/Status_Add.svg"
                                                    action      ="insert"
                                                    :field       ="note.field"
                                                ></ar-list>
                                            </template>
                                            <img src="/static/svg/NoData.svg" class="mt-5" alt="empty" v-else>
                                        </div>
                                        <div class="pr-4">
                                            <ar-textfield
                                                v-model.trim="listingNote.value"
                                                a_type="textarea"
                                                a_style="height: 100px; resize: none;"
                                                placeholder="Your notes here.."
                                                :disabled="listingNote.is_loading"
                                            />
                                            <ar-button class="float-right" :disabled="!listingNote.value || listingNote.is_loading" @click="addListingNote()">Add</ar-button>
                                        </div>
                                    </div>
                                    <template v-else>
                                        <div class="float-left position-relative" style="width:58%; border-right:1px solid #6EB4F95C" v-show="show_predefined_reasons">
                                            <div class="reassign-to-wip-notice mr-2 mb-5" v-show="isReassignWIP">
                                                <a class="notice-img">
                                                    <img onerror="this.src='/static/svg/Notice_SVG.svg'" src="/static/svg/Notice_SVG.svg" alt="woman-smiling-with-notice">
                                                </a>
                                                <h6>Notice</h6>
                                                <p>These data has already been processed and validated by a recognized third party.</p>
                                            </div>
                                            <div class="overflow-auto" style="max-height:450px;" v-show="!reworkTimeline">
                                                <div v-if="fetching_reasons">
                                                    <h5 class="mb-4">Mark as {{predefined_status}}</h5>
                                                    <p>A selection of predefined reasons are available below. Select <b>Others</b> to provide a custom reason. <br/> Confirm by clicking on the 'Confirm' button.</p>

                                                    <ar-reason-selection
                                                        :reasons ="predefined_reasons"
                                                        key      ="listing-modal-reasons"
                                                        is-batch-modal
                                                    />
                                                </div>
                                                <div v-else class="col-xs-1 text-center" style="margin-top: 25%">
                                                    <p>Fetching Predefined Reasons</p>
                                                    <div
                                                        v-for ="count in [1,2,3]"
                                                        :key  ="count"
                                                        class ="spinner-grow text-dark"
                                                        role  ="status"
                                                    >
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- :style="!show_predefined_reasons || 'width:39.2%'" -->
                                        <div
                                            v-if="individualReassign.show || change_status"
                                            :style="show_predefined_reasons ? 'width:39.2%' : 'width: 100%'"
                                            :class="{'float-right': isReassignWithReason}"
                                        >
                                            <template v-if="isListingInfoReady">
                                                <!-- <h5 class="mb-4">Reassign to another {{ isQAAccount ? 'User' : reassignment_mode }}</h5> -->
                                                <h5 class="mb-4">Reassign to another Account</h5>
                                                <div class="pr-3">
                                                    <div class="col-md-12">
                                                        <!-- <multiselect
                                                            v-if="isQAAccount"
                                                            v-model="individualReassign.user.selected"
                                                            :options="filtered_researchers"
                                                            group-values="groupValues"
                                                            group-label="groupLabel"
                                                            placeholder="Type to search"
                                                            track-by="label"
                                                            label="label"
                                                            class="mb-3"
                                                        >
                                                            <span slot="noResult">Oops! No users found. Consider changing the search query.</span>
                                                        </multiselect> -->
                                                        <ar-select
                                                            v-restrict="'v-reassignment-account'"
                                                            class="v-reassignment-account"
                                                            type="search"
                                                            id="individualReassign-user"
                                                            placeholder="Select Account"
                                                            :options="filtered_researchers"
                                                            v-model.number="individualReassign.user.selected"
                                                            :default_val="{label:list.owner, value:list.user_id}"
                                                            label="Account"
                                                        />

                                                    </div>
                                                    <!-- <template v-if="reassignment_mode == 'Researcher'"> -->
                                                        <!-- <div class="col-md-12" v-if="canReassignWithCampaign"> -->
                                                        <div class="col-md-12 v-reassignment-campaign" v-restrict="'v-reassignment-campaign'">
                                                            <ar-select
                                                                type="search"
                                                                placeholder="Select Campaign"
                                                                id="reassignment-campaigns"
                                                                :options="campaigns_select"
                                                                :disabled=" !campaigns_select.length"
                                                                v-model.number="individualReassign.campaign.selected"
                                                                :default_val="{label:list.campaign, value:list.campaign_id}"
                                                                label="Campaigns"
                                                            >
                                                                Select Campaigns
                                                            </ar-select>
                                                        </div>
                                                        <!-- <div class="col-md-12" v-if="canReassignWithStatus"> -->
                                                        <div class="individual-reassign-status-and-substatus-con w-100">
                                                            <div class="col-md-12 v-reassignment-status" v-restrict="'v-reassignment-status'">
                                                                <ar-select
                                                                    type="search"
                                                                    id="individualReassign-status"
                                                                    placeholder="Select Status"
                                                                    class="mb-0"
                                                                    textfield_class="mb-0"
                                                                    v-model.number="individualReassign.status.selected"
                                                                    :options="reassignStatusOptions"
                                                                    :disabled=" !reassignStatusOptions.length"
                                                                    label="Status"
                                                                />
                                                            </div>
                                                            <template v-if="reassignmentSubStatusOptions?.filter(s => s.id)?.length">
                                                                <ar-toggle-btn>
                                                                    <div class="sub-statuses-container ml-2" style="width: 97.6%;">
                                                                        <label class="ml-2">Select a sub status</label>
                                                                        <div 
                                                                            v-for="(sub, sub_index) in reassignmentSubStatusOptions"
                                                                            class="btn sub-status d-flex align-items-center reassignment"
                                                                            style="padding-left: 37px !important;"
                                                                            :key="`reassignSubStatus--${sub_index}`"
                                                                            @click="toggleSubStatusReassignment($event, sub.id ?? -1)"
                                                                        >
                                                                            <h5>{{sub.label}}</h5>
                                                                            <input 
                                                                                type ="radio" 
                                                                                :name ="`reassignSubStatus--sub-status`"
                                                                                :id  ="`reassignSubStatus--sub-status-${sub.id}`"
                                                                                v-show="false"
                                                                            >
                                                                        </div>
                                                                    </div>
                                                                </ar-toggle-btn>
                                                            </template>
                                                        </div>
                                                    <!-- </template> -->
                                                    <!-- <div
                                                        class="col-md-12"
                                                        v-if="['Reworked','Recheck'].includes($route.query.status) && isAllowedQAReassignment"
                                                    >
                                                        <ar-select
                                                            v-model  ="reassignment_mode"
                                                            :val     ="reassignment_mode"
                                                            :options ="[{'value':'Researcher','label':'Researcher'},{'value':'QA','label':'QA'}]"
                                                            first_element_as_default
                                                        />
                                                    </div> -->
                                                    <div class="col-md-12">
                                                        <ar-button a_class="float-right mt-3" @click="doNewReassign(true, list)">Confirm</ar-button>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                        <div :class="{'d-none' : show_predefined_reasons}" v-else-if="!change_status">
                                            <template>
                                                <h5 class="mb-4">Status History</h5>
                                                <div class="a-modal-status-history">
                                                    <ar-list
                                                        type         ="history"
                                                        v-for        ="(history, index) in filterHistory(list.general_history)"
                                                        :key         ="index"
                                                        :subject     ="name(history.user[0] != null && history.user[0].first_name != null ? history.user[0].first_name : '', history.user[0] != null && history.user[0].last_name != null ? history.user[0].last_name : '')"
                                                        :details     ="history.text"
                                                        :date        ="history.created_at"
                                                        :status_icon ="getStatusIcon(history.value, history.field)"
                                                        :action      ="history.action"
                                                        :field       ="history.field"
                                                    ></ar-list>

                                                </div>
                                            </template>
                                        </div>
                                    </template>
                                </div>
                            </div> <!--History and Status Container-->
                        </div>
                    </section>
                    <div v-show="!show_predefined_reasons"> <!--Lower Modal-->

                        <section>
                            <!-- <hr/> -->
                            <div
                                class="row mb-4"
                                v-show="Object.entries(list.evidences).length > 0"
                            >
                                <div class="col-md-12">
                                    <h5>Evidences</h5>
                                </div>
                                <div class="col-md-12">
                                    <div
                                        class="d-inline-block"
                                        v-for="(evidence,index) in uploadedEvidence(list.evidences)"
                                        :key="index"
                                    >
                                        <a
                                            v-if="evidence.image != ''"
                                            :href="host+'/files/'+evidence.image"
                                            class="a-modal-evidence mr-2"
                                            target="_blank"
                                        >
                                            <img
                                                :src="showPreview(evidence.type,evidence.image)"
                                                :alt="evidence.type"
                                                :onerror="showMissing(evidence.image)"
                                            >
                                            <span class="text-capitalize">{{evidence.type}}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <h5>Listing Information</h5>
                                </div>
                                <div class="col-md-4">
                                    <div class="a-modal-seller-info-item">
                                        <img src="/static/svg/SellerInfo_Platform.svg" alt="icon">
                                        <h5>{{returnDefault(list.list_platform)}} <span>Platform</span></h5>
                                    </div>
                                    <div class="a-modal-seller-info-item">
                                        <img src="/static/svg/ListingInfo_ItemType.svg" alt="icon">
                                        <h5>{{returnDefault(list.item_class)}}<span>Item Class</span></h5>
                                    </div>
                                    <div class="a-modal-seller-info-item">
                                        <img src="/static/svg/Trademark_Claim.svg" alt="icon">
                                        <h5>{{returnDefault(list.claim_type)}}<span>Claim Type</span></h5>
                                    </div>
                                    <div class="a-modal-seller-info-item">
                                        <img src="/static/svg/ListingInfo_History.svg" alt="icon">
                                        <h5>{{returnDefault(list.trans_history)}}<span>Transaction History</span></h5>
                                    </div>
                                    <div class="a-modal-seller-info-item">
                                        <img src="/static/svg/SellerInfo_ShippingLocation.svg" alt="icon">
                                        <h5>{{returnDefault(list.listing_location)}}<span>Item Location</span></h5>
                                    </div>
                                    <div class="a-modal-seller-info-item">
                                        <img src="/static/svg/SellerInfo_ShippingLocation.svg" alt="icon">
                                        <h5>{{returnDefault(list.shipping_location)}}<span>Shipping Location</span></h5>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="a-modal-seller-info-item">
                                        <img src="/static/svg/ListingInfo_SellerURL.svg" alt="icon">
                                        <h5>
                                            <a :href="list.seller_url" :target="list.seller_url != '' ? '_blank' : '_self'">
                                                {{returnDefault(list.seller_url)}}
                                            </a>
                                            <span>Seller Store URL</span>
                                        </h5>
                                    </div>
                                    <div class="a-modal-seller-info-item">
                                        <img src="/static/svg/ListingInfo_ItemType.svg" alt="icon">
                                        <h5>[Class {{ list.item_class_number }}]{{returnDefault(list.item_type_id)}}<span>Item Type</span></h5>
                                    </div>
                                    <div class="a-modal-seller-info-item">
                                        <img src="/static/svg/ListingInfo_SampleDummy.svg" alt="icon">
                                        <h5>{{returnDefault(list.dummy_name)}}<span>Dummy Account Used</span></h5>
                                    </div>
                                    <div class="a-modal-seller-info-item">
                                        <img src="/static/svg/Conversation_Script.svg" alt="icon">
                                        <h5>{{returnDefault(list.keyword)}}<span>Keywords</span></h5>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div class="row" v-show="list.reasons_list.length">
                                <div class="col-md-12">
                                    <h5 class="mb-3">Infringement Indicators</h5>
                                    <div class="a-modal-check-info-item col-md-5"  v-for="(reason,index) in list.reasons_list" :key="index">
                                        <img src="/static/svg/I.Reasons_Check.svg" alt="icon">
                                        {{reason}}
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div class="row">
                            <div class="col-md-12">
                                <h5>Seller Contact Information</h5>
                            </div>
                            <div class="col-md-4">
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/SellerInfo_ContactPerson.svg" alt="icon">
                                    <h5>{{list.contact_person}} <span>Contact Person</span></h5>
                                </div>
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/Contact_Mobile.svg" alt="icon">
                                    <h5>{{list.mobile_no}}<span>Mobile Number</span></h5>
                                </div>
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/SellerInfo_Contact.svg" alt="icon">
                                    <h5>{{list.telephone}}<span>Telephone Number</span></h5>
                                </div>
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/SellerInfo_Email.svg" alt="icon">
                                    <h5>{{list.e_mail}}<span>Defendant Email</span></h5>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/SellerInfo_ContactPerson.svg" alt="icon">
                                    <h5>{{list.aliases.length ? list.aliases.map(alias=>{return alias.alias}).toString() : "None"}}<span>Alias</span></h5>
                                </div>
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/Conversation_Date.svg" alt="icon">
                                    <h5>{{list.first_contact}}<span>First Contact Date</span></h5>
                                </div>
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/Contact_VPN.svg" alt="icon">
                                    <h5>{{list.vpn_location}}<span>VPN Location Used</span></h5>
                                </div>
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/Conversation_Method.svg" alt="icon">
                                    <h5>{{list.convo_method ? list.convo_method : "None"}}<span>Conversation Methods</span></h5>
                                </div>
                            </div>
                            <div class="col-md-12 pr-4">
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/Conversation_Script.svg" alt="icon">
                                    <h5>{{list.script}}<span>Script</span></h5>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div class="row">
                            <div class="col-md-12">
                                <h5>Payment Accounts</h5>
                            </div>
                            <div class="col-md-4" v-for="(account,index) in list.payment_accounts" :key="index">
                                <div class="a-modal-seller-info-item" :style="active_listing_info[0].seller_account_id == account.id? 'border:1px solid rgb(41, 171, 162)' : ''">
                                    <img src="/static/svg/Payment_Paypal.svg" alt="icon">
                                    <h5 :style="active_listing_info[0].seller_account_id == account.id? 'color:#29ABA2' : ''"><span class="mb-1">Payment Type: {{account.type}}</span>Email/Name: {{account.name_email != "" ? account.name_email : "None"}}<span>Account Number: {{account.number != "" ? account.number : "None"}}</span></h5>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div class="row">
                            <div class="col-md-12">
                                <h5>Seller Country</h5>
                            </div>
                            <div class="col-md-4">
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/SellerInfo_Location.svg" alt="icon">
                                    <h5>{{returnDefault(list.country_name)}}<span>Country</span></h5>
                                </div>
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/SellerInfo_Location.svg" alt="icon">
                                    <h5>{{returnDefault(list.region_name)}}<span>Region</span></h5>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/SellerInfo_Address.svg" alt="icon">
                                    <h5>{{returnDefault(list.seller_address)}}<span>Address</span></h5>
                                </div>
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/SellerInfo_Shipping.svg" alt="icon">
                                    <h5>{{list.ship_to_us ? "Will ship to USA" : "Will not ship to USA"}}<span>Shipping Infos</span></h5>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div class="row">
                            <div class="col-md-12">
                                <h5>Listing Price Details</h5>
                            </div>
                            <div class="col-md-4">
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/Transaction_ItemPrice.svg" alt="icon">
                                    <h5>{{returnDefault(list.listing_price)}}<span>Initial Price</span></h5>
                                </div>
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/Transaction_Currency.svg" alt="icon">
                                    <h5>{{returnDefault(list.currency)}}<span>Price Currency</span></h5>
                                </div>
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/Trademark_Brand.svg" alt="icon">
                                    <h5>{{list.brand_on_item ? "Seen on Image" : "Not Seen on image"}}<span>Brand Name</span></h5>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/Transaction_ItemPrice.svg" alt="icon">
                                    <h5>{{returnDefault(list.max_price)}}<span>Maximum Price</span></h5>
                                </div>
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/Transaction_Unit.svg" alt="icon">
                                    <h5>{{returnDefault(list.listing_price_unit)}}<span>Price Unit</span></h5>
                                </div>
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/Trademark_Brand.svg" alt="icon">
                                    <h5>{{list.logo_on_item ? "Seen on Image" : "Not Seen on image"}}<span>Logo</span></h5>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div class="row">
                            <div class="col-md-12">
                                <h5>Purchase Details</h5>
                            </div>
                            <div class="col-md-4">
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/Conversation_Purchase.svg" alt="icon">
                                    <h5>{{returnDefault(list.purchase_type)}}<span>Purchase Type</span></h5>
                                </div>
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/Purchase_TransacNum.svg" alt="icon">
                                    <h5>{{returnDefault(list.transaction_number)}}<span>Transaction Number</span></h5>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/Transaction_ItemPrice.svg" alt="icon">
                                    <h5>{{returnDefault(list.pymnt_platformname)}}<span>Payment Platform</span></h5>
                                </div>
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/Transaction_ItemPrice.svg" alt="icon">
                                    <h5>{{returnDefault(list.purchase_price)}}<span>Purchase Price</span></h5>
                                </div>
                            </div>
                        </div>
                        <!-- <hr v-show="Object.entries(list.evidences).length > 0" /> -->
                        <!-- <div class="row">
                            <div class="col-md-12">
                                <h5 class="mb-3">Checkout List</h5>
                                <div class="a-modal-check-info-item d-block">
                                    <img src="/static/svg/Checklist_Yes.svg" alt="icon">
                                    Shipping Address: Ships to NDIL (with accounts), and address is complete (street address, city, state, zip code present)?
                                </div>
                                <div class="a-modal-check-info-item d-block">
                                    <img src="/static/svg/Checklist_Yes.svg" alt="icon">
                                    Shipping Address: Ships to NDIL (no accounts), and address is complete (street address, city, state, zip code present)?
                                </div>
                                <div class="a-modal-check-info-item d-block">
                                    <img src="/static/svg/Checklist_No.svg" alt="icon">
                                    Shipping Address: Zip code matches city/state?
                                </div>
                                <div class="a-modal-check-info-item d-block">
                                    <img src="/static/svg/Checklist_NA.svg" alt="icon">
                                    Shipping Address: Only one clear shipping address present?
                                </div>
                            </div>
                        </div>-->
                        <!-- <br/> -->
                        <!-- <div class="row">
                            <div class="col-md-12">
                                <h5 class="mb-3">Conversation List</h5>
                                <div class="a-modal-check-info-item d-block">
                                    <img src="/static/svg/Checklist_Yes.svg" alt="icon">
                                    Transaction History: Consider "a lot of US customers" (and equivalent terms) in convo?
                                </div>
                                <div class="a-modal-check-info-item d-block">
                                    <img src="/static/svg/Checklist_No.svg" alt="icon">
                                    Proforma Invoice: If invoice has a different seller name, did researcher confirm alternate name in conversation?
                                </div>
                                <div class="a-modal-check-info-item d-block">
                                    <img src="/static/svg/Checklist_NA.svg" alt="icon">
                                    [SOCIAL MEDIA] Confirmed shipping to complete NDIL address?
                                </div>
                            </div>
                        </div> -->
                        <!-- <br/> -->
                        <!-- <div class="row">
                            <div class="col-md-12">
                                <h5 class="mb-3">General List</h5>
                                <div class="a-modal-check-info-item d-block">
                                    <img src="/static/svg/Checklist_Yes.svg" alt="icon">
                                    Captured evidence set to A3 portrait, and between 80% to 100% scaling?
                                </div>
                            </div>
                        </div> -->
                        <!-- <br/> -->
                        <!-- <div class="row">
                            <div class="col-md-12">
                                <h5 class="mb-3">Multi-Evidence List</h5>
                                <div class="a-modal-check-info-item d-block">
                                    <img src="/static/svg/Checklist_Yes.svg" alt="icon">
                                    Transaction History: present and includes US transactions (where sorting is NOT possible)?
                                </div>
                                <div class="a-modal-check-info-item d-block">
                                    <img src="/static/svg/Checklist_No.svg" alt="icon">
                                    Transaction History: present and filtered to US transactions (where possible)?
                                </div>
                                <div class="a-modal-check-info-item d-block">
                                    <img src="/static/svg/Checklist_NA.svg" alt="icon">
                                    Transaction History: no more than 5 pages of screenshots?
                                </div>
                                <div class="a-modal-check-info-item d-block">
                                    <img src="/static/svg/Checklist_Yes.svg" alt="icon">
                                    [EBAY] Evidence from regional website (ex: ebay.co.uk) BUT both item and seller locations are from Asia?
                                </div>
                                <div class="a-modal-check-info-item d-block">
                                    <img src="/static/svg/Checklist_No.svg" alt="icon">
                                    Headers (date) and footers (URLs) are visible across all relevant evidence?
                                </div>
                                <div class="a-modal-check-info-item d-block">
                                    <img src="/static/svg/Checklist_NA.svg" alt="icon">
                                    Chinese seller name (untranslated) in evidence accepted?
                                </div>
                            </div>
                        </div> -->
                        <!-- <br/> -->
                        <!-- <div class="row">
                            <div class="col-md-12">
                                <h5 class="mb-3">Product List</h5>
                                <div class="a-modal-check-info-item d-block">
                                    <img src="/static/svg/Checklist_Yes.svg" alt="icon">
                                    Shows "Ships to US/United States"?
                                </div>
                                <div class="a-modal-check-info-item d-block">
                                    <img src="/static/svg/Checklist_Yes.svg" alt="icon">
                                    "Vintage" in title/description but item status is New and/or product image looks new?
                                </div>
                                <div class="a-modal-check-info-item d-block">
                                    <img src="/static/svg/Checklist_NA.svg" alt="icon">
                                    [EBAY] Submission is from non-Asian seller BUT item is shipping from Asia?
                                </div>
                                <div class="a-modal-check-info-item d-block">
                                    <img src="/static/svg/Checklist_Yes.svg" alt="icon">
                                    [EBAY] No item condition but item description indicates item is New?
                                </div>
                                <div class="a-modal-check-info-item d-block">
                                    <img src="/static/svg/Checklist_No.svg" alt="icon">
                                    [EBAY] Active bid items set to 60302 and item location is from Asia?
                                </div>
                                <div class="a-modal-check-info-item d-block">
                                    <img src="/static/svg/Checklist_No.svg" alt="icon">
                                    [ADDITIONAL EVIDENCE] First page(s) containing image and seller name of seller's other infringing listings (max 10) uploaded to Additional Evidence category?
                                </div>
                                <div class="a-modal-check-info-item d-block">
                                    <img src="/static/svg/Checklist_No.svg" alt="icon">
                                    [ADDITIONAL EVIDENCE] [ALIEXPRESS] [EBAY] Business License / Business Information page included?
                                </div>
                                <div class="a-modal-check-info-item d-block">
                                    <img src="/static/svg/Checklist_NA.svg" alt="icon">
                                    [ADDITIONAL EVIDENCE] [WISH] Product-based feedback uploaded to Additional Evidence, and preferrably filtered to negative feedback?
                                </div>
                            </div>
                        </div> -->
                        <!-- <br/> -->
                        <!-- <div class="row">
                            <div class="col-md-12">
                                <h5 class="mb-3">Campaign or Lawyer Specs</h5>
                                <div class="a-modal-check-info-item d-block">
                                    <img src="/static/svg/Checklist_Yes.svg" alt="icon">
                                    Brand name on Listing Title?
                                </div>
                                <div class="a-modal-check-info-item d-block">
                                    <img src="/static/svg/Checklist_Yes.svg" alt="icon">
                                    No brand name in title but any combination of the ff is present: logo on image / brand name on image?
                                </div>
                                <div class="a-modal-check-info-item d-block">
                                    <img src="/static/svg/Checklist_NA.svg" alt="icon">
                                    [AMAZON] Seller is NOT one of the following: "Prime" and/or "Fulfilled by Amazon"?
                                </div>
                                <div class="a-modal-check-info-item d-block">
                                    <img src="/static/svg/Checklist_Yes.svg" alt="icon">
                                    [AMAZON] ASIN does not show US/EU/Oceania locations?
                                </div>
                                <div class="a-modal-check-info-item d-block">
                                    <img src="/static/svg/Checklist_No.svg" alt="icon">
                                    Seller comes from the following location(s): Asia (All Sites)?
                                </div>
                                <div class="a-modal-check-info-item d-block">
                                    <img src="/static/svg/Checklist_No.svg" alt="icon">
                                    Product matches this category: Apparel (shirts)?
                                </div>
                                <div class="a-modal-check-info-item d-block">
                                    <img src="/static/svg/Checklist_No.svg" alt="icon">
                                    Evidence comes from the following website(s): Any Site?
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </template>
        </ar-modal>

        <potential-whitelists
            :whitelists ="$store.state.potentialWhitelists"
            @click      ="acknowledgeWhitelists"
            @close      ="onModalClose"
        />

        <main class="main-con" :style="[rids.length ? {'paddingTop': '300px'} : '', selected_listings.length > 0 ? {'paddingTop': '160px'} : '']">
            <div class="card-con">
                <ar-list
                    v-for          ="(list, index) in attached_lists"
                    v-show         ="!show_error || (show_error && list.error)"
                    :key           ="index"
                    :product_img   ="list.product_img"
                    :product_url   ="list.product_url"
                    :seller_name   ="list.seller_name"
                    :if_reassigned ="list.if_reassigned"
                    :if_commission ="list.commission"
                    :rid           ="list.rid"
                    :progress      ="list.progress"
                    :status        ="list.status"
                    :details       ="list.details"
                    :platform      ="list.platform"
                    :platform_img  ="list.platform_img"
                    :campaign_img  ="list.campaign_img"
                    :campaign      ="list.campaign"
                    :seller_url    ="list.seller_url"
                    :date          ="list.date"
                    :last_rework   ="list.last_rework"
                    :claim_type    ="list.claim_type"
                    :payment_type  ="list.payment_type"
                    :owner         ="list.owner"
                    :invalid       ="list.error"
                    :qualifier     ="list.qualifier"
                    :case_number   ="list.case_number"
                    target         ="#listing-modal"
                    class          ="animated fadeIn fast"
                    @change        ="getSelectListing"
                    @click         ="getListingInfo(list.rid, list.user_id)"
                />
                <div v-if="!attached_lists.length && !initialized" class="empty-listing-con">
                    <img src="/static/svg/SearchPage_Photo.svg" alt="No listings">
                </div>
                <div v-else-if="!attached_lists.length && loaded && initialized" class="empty-listing-con">
                    <img src="/static/svg/NoListings_Photo.svg" alt="No listings">
                </div>
            </div>
        </main>

        <footer class="search-footer">
            <div class="d-flex justify-content-between">
                <ar-pagination
                    class="animated fadeIn mr-3 d-inline-block"
                    :page_no="current_page"
                    :last_page="last_page"
                    v-if="!show_error"
                    @first-click="updatePage(1)"
                    @prev-click="updatePage(prev_page)"
                    @next-click="updatePage(next_page)"
                    @last-click="updatePage(last_page)"
                    @enter="updateListingPage"
                    @keypress.native="isNumber"
                />
                <ar-select
                    v-if="!show_error"
                    v-model="show_number_of_items"
                    a_style="width: 200px"
                    :options="options">
                    Show 10 listings
                </ar-select>
            </div>
        </footer>
    </div>
</template>

<script>
    function lazyload(view){
        return () => import(`../../../components/layouts/${view}.vue`)
    }
    import dialog from '../../layouts/Dialog'
    import chip from '../../layouts/Chip'
    import pagination from '../../layouts/Pagination'
    import axios from '../../../axiosMime'
    import moment from 'moment'
    import {mapActions, mapState, mapGetters} from 'vuex'
    import { isValidHttpUrl } from '@/utils/common'
    import MultiSelect from 'vue-multiselect';
    import qs from 'qs';
    import { ListingStatus } from '../../../utils/constants';
    import { useSubStatusesStore } from '../../../store/pinia/substatuses';
    import { send as globalSend } from '../../../events'

    export default {
        name: 'ar-search',
        components: {
            'ar-titlebar'  : lazyload('TitleBar'),
            'ar-textfield' : lazyload('Textfield'), //textfield,
            'ar-button'    : lazyload('Button'),//button,
            'ar-list'      : lazyload('List'),//list,
            'ar-select'    : lazyload('Select'),//select,
            'ar-modal'     : lazyload('Modal'),//modal,
            'ar-toggle-btn': lazyload('ButtonToggle'),
            // 'ar-action-bar': lazyload('ActionBar'),//actionbar,
            'ar-chip'      : chip,
            'ar-pagination': pagination,
            'ar-dialog'    : dialog,
            // ArMoreFilters: () => import('../../layouts/MoreFilters'),
            'ar-progressbar': lazyload('Progressbar'),
            ArReasonSelection  : () => import('../verification/listing/ReasonsSelection'),
            PotentialWhitelists: () => import('@/components/layouts/modals/PotentialWhitelists.vue'),
            'multiselect': MultiSelect,
            ArReassignmentUnsuccessfulModal: () => import("@/components/layouts/modals/ReassignmentUnsuccessfulModal"),
        },
        data() {
            return {
                reassign__unsuccessful: {
                    hasActive: [],
                    notAssignedToCampaign: [],
                    duplicatesFound: [],
                    others: []
                },
                // list for id in existing listings
                hasExisting_listings: false,
                existing_listings_id: [],
                isLoading_existing_listings: true,
                existing_and_duplicate_reasons: [
                    'Seller already have an active listing on the requested campaign.',
                    'The seller of the listing already has a working item in this campaign. You cannot proceed.'
                ],
                reassignmentFailed_meta_data: [],
                individualReassign_hasError: false,
                entered_rid       : '',
                rids              : [],
                searched_lid      : this.$route.params.lid ? this.$route.params.lid: "",
                selected_campaign : 0,
                specific_url      : '',
                campaigns_select  : [],
                filtered_platforms: [],
                filtered_status   : "",
                selected          : false,
                can_status        : false,
                change_status     : false,
                qa                : false,
                sidebar_campaigns : [],
                statuses          : '',
                platforms         : ['11Street', 'Alibaba', 'Amazon', 'DHGate', 'Wish', 'Ebay', 'Qoo10'],
                lists             : [],
                lists_meta        : { no_of_pages: 1 },
                lists_links       : {},
                histories: [{
                    subject: "Marvin",
                    object: "Seller Name",
                    status: "Work in Progress",
                    action: "insert",
                    details: "Seller Name: Store1",
                    date: "12/01/19"
                },
                {
                    subject: "Marvin",
                    object: "Seller Name",
                    status: "Work in Progress",
                    details: "From: Store1 To: Store2",
                    date: "12/01/19"
                },
                {
                    subject: "Marvin",
                    object: "Seller Name",
                    action: "delete",
                    status: "Researched",
                    details: "Seller Name: Store1",
                    date: "12/01/19"
                }],
                status_change_options: [{
                    heading: "Mark as Invalid",
                    details: "Not conforming to valid specifications",
                    img: "/static/svg/Icon_Invalid.svg",
                    id: "invalid"
                },
                {
                    heading: "Mark as Has Potential",
                    details: "Possibility for qualification",
                    img: "/static/svg/Icon_HasPotential.svg",
                    id: "haspo"
                },
                /**
                 * Deliverable: Hide all For Rework/Reworked elements across all GUI
                 * Deliverable Number: 454
                 */
                // {
                //     heading: "Mark as Reworked",
                //     details: "Need some fixes",
                //     img: "/static/svg/Icon_ForRework.svg",
                //     id: "reworked"
                // },
                // {
                //     heading: "Mark as For Rework",
                //     details: "Fixes have been implemented",
                //     img: "/static/svg/Icon_Reworked.svg",
                //     id: "for_rework"
                // },
                {
                    heading: "Mark as Qualified",
                    details: "Suitable for a law case",
                    img: "/static/svg/Icon_Qualified.svg",
                    id: "qualified"
                },
                {
                    heading: "For Commission",
                    details: "Variable-pay for reward",
                    img: "/static/svg/Icon_Commission.svg",
                    id: "commission"
                },
                {
                    heading: "Remove Commission",
                    details: "Detach reward",
                    img: "/static/svg/Icon_NoCommission.svg",
                    id: "remove-commission"
                }],
                options: [{
                    label: "Show 25 listings",
                    value: 1
                },
                {
                    label: "Show 50 listings",
                    value: 2
                },
                {
                    label: "Show 100 listings",
                    value: 3
                },
                {
                    label: "Show 150 listings",
                    value: 4
                }],
                campaign: {
                    img: '/static/svg/Campaign Default Photo.svg',
                    name: localStorage.getItem("campaign_name")
                },
                searched_option:"RID",
                search_options: [{
                    label: "Search by RID",
                    value: "RID"
                },
                {
                    label: "Search by Seller Name",
                    value: "Seller Name"
                },
                {
                    label: "Search by Payment Types",
                    value: "Payment Types"
                },
                {
                    label: "Search by Product URL",
                    value: "Product URL"
                },
                {
                    label: "Search by Store URL",
                    value: "Store URL"
                }],
                researcher_name_options: [{
                    label: "Sample Researcher",
                    value: "Sample Researcher"
                }],
                reassign_status_options: [{
                    label: "Work in Progress",
                    value: 4
                }
                /**
                 * Deliverable: Hide all For Rework/Reworked elements across all GUI
                 * Deliverable Number: 454
                 */
                // ,
                // {
                //     label: "For Rework",
                //     value: 2
                // }
                ],
                listing_info                : [],
                loaded                      : false,
                initialized                 : false,
                page_size                   : 10,
                current_page                : 1,
                input_disabled              : false,
                search_by                   : 0,
                order_by                    : "desc",
                order_by_option    : [{
                    label: "Descending",
                    value: "desc"
                },
                {
                    label: "Ascending",
                    value: "asc"
                }],
                sort_by            : "id",
                sort_by_option    : [{
                    label: "LID",
                    value: "id"
                },
                {
                    label: "Researcher",
                    value: "researcher"
                },
                {
                    label: "QA",
                    value: "qa"
                },
                // {
                //     label: "Payment Method",
                //     value: "payment_method"
                // },
                {
                    label: "Date Added",
                    // value: "date_added"
                    value: "created_at"
                },
                {
                    label: "Date Qualified",
                    // value: "date_qualified"
                    value: "qualified_at"
                },
                // {
                //     label: "Infringement Reasons",
                //     value: "reasons"
                // }
                ],
                active_listing_id           : "",
                selected_listings           : [],
                reassign_campaign           : 0,
                reassign_researcher         : 0,
                reassign_status             : 0,
                single_reassign_campaign    : 0,
                single_reassign_researcher  : 0,
                single_reassign_status      : 0,
                researchers                 : [],
                select_all_text             : "Select",
                is_select_all               : true,
                show_number_of_items        : 0,
                modal_comment               : "",
                searched_seller             : "",
                selected_change_status      : 0,
                selected_comm               : 0,
                researchers_campaigns_select: [],
                campaigns_researchers_select: [],
                status_history_tab          : true,
                searching                   : false,
               expanded                    : false,
                min_date_added: "",
                max_date_added: "",
                min_date_qualified: "",
                max_date_qualified: "",
                payment_methods: [],
               selected_payment_methods: [],
                reasons                 : [],
                selected_reasons        : [],
                infringement_option     : "1",
                filter_item_location    : "",
                filter_seller_location  : "0",
                seller_locations        : [],
                modal_campaign          : "",
                filter_case_number      : "",
                filter_researcher       : "",
                filter_qa               : "",
                filter_email            : "",   // New added v-model for EMAIL
                filter_alias            : "",   // New added v-model for ALIAS
                filter_seller_url              : "",
                filter_product_url             : "",
                filter_claimtype               : {
                    options: [],
                    selected: 0
                },
                filter_shipping_location       : {
                    options: [],
                    selected: 0
                },
                filter_roles                   : {
                    options: [],
                    selected: []
                },
                filter_employee                : {
                    options: [],
                    selected: 0
                },
                filter_brand_name_on_image     : 1,
                filter_logo_on_image           : 1,
                filter_will_ship_to_us         : 1,
                enable_options                 : [],
                filter_with_claimtype          : 1,
                filter_with_shipping_location  : 1,
                filter_with_item_type          : 1,
                filter_with_seller_country     : 1,
                filter_with_item_class         : 1,
                filter_item_type_options       : [],
                filter_commission              : 1,
                filter_purchase_type           : 0,
                filter_with_status_change_reason: 1,
                filter_status_change_reason    : {
                    options: [],
                    selected: []
                },
                filters__: {
                    item_location: {
                        options: [],
                        selected: 0,
                        search: ''
                    }
                },
                reassignment_mode       : "Researcher",
                applied_filters_count   : 0,
                selected_campaign_name  : "",
                cancel_merging          : false,
                downloading_merge_file  : false,
                error_merge_only        : false,
                input_key               : {
                    reassign: {
                        campaigns: "",
                        researcher: ""
                    }
                },
                active_listing_info: [],
                batch_comment      : '',
                is_loading         : false,
                item_class         : [],
                filter_item_class  : 0,
                filter_item_type               : [],
                filterDateCaptured : '',
                filters_set: '',
                predefined_reasons             : [],
                show_predefined_reasons        : false,
                clicked_statuses               : [],
                predefined_status              : '',
                selected_predefined_reasons    : [],
                selected_predefined_sub_reasons: [],
                other_reason                   : "",
                selected_other_reason          : [],
                fetching_reasons               : false,
                modal_evidences                : {},
                post_qa_status                 : ['Submitted', 'Accepted', 'Rejected', 'Monitoring', 'Filed'],
                prev_route                     : {},
                uploaded_evidences             : [],
                is_uploaded_evidences_ready    : false,
                already_worked_lid             : '',
                detected_whitelist             : {},
                duplicate_seller_found         : {},
                duplicate_listings             : [],
                all_predefined_reasons         : {},
                batch_change_status            : 2,
                duplicateListingModalOnContinue: () => ({}),
                acknowledgeWhitelists          : () => ({}),
                isCheckingPotentials           : false,
                hasAcknowledged                : false,
                onModalClose                   : () => ({}),
                reworkTimeline                 : false,
                listingNote                    : {
                    data      : [],
                    status    : false,
                    value     : "",
                    is_loading: false
                },
                reworkCommission  : null,
                reworkLevel       : null,
                forCommission: null,
                hasCrucial        : false,
                reassignStatuses:  {
                    loading: false,
                    results: [],
                    selected: 0,
                    campaign: {
                        selected: 0,
                    }
                },
                individualReassign: {
                    show: false,
                    status: {
                        selected: 0,
                        data: []
                    },
                    campaign: {
                        selected: 0,
                        data: []
                    },
                    user: {
                        selected: 0,
                        data: []
                    }
                },
                batchInvalidReasons: [],
                getDataFor_AdditionalFilters: false,
            }
        },
        computed:{
            ...mapState([/*"active_listing_info",*/
                "shown_data",
                "show_error",
                'relatedCampaignBypass'
            ]),
            ...mapGetters(['isHoliday', 'themeListingPreview']),
            computed_isLoading_existing_listings() {
                return this.isLoading_existing_listings
            },
            checkSelectedStatusForReassignmentIsSame() {
                const listings = this.lists.filter(l => this.selected_listings.includes(l.rid))
                return listings.every(l => l.qflag_id == listings[0].qflag_id)
            },
            subStatusesStore() {
                return useSubStatusesStore()
            },
            subStatuses() {
                return this.subStatusesStore.subStatuses ?? {}
            },
            reassignmentSubStatusOptions() {
                const selectedReassignmentStatus = this.individualReassign.status.selected
                if (selectedReassignmentStatus == 4) 
                    return this.wipSubStatuses
                
                if (selectedReassignmentStatus == 1)
                    return this.researchedSubStatuses

                if (selectedReassignmentStatus == 3)
                    return this.qualifiedSubStatuses

                if (selectedReassignmentStatus == 98)
                    return this.hasPotentialSubStatuses

                if (selectedReassignmentStatus == 66)
                    return this.archivedSubStatuses

                return []
            },
            reassignmentBulkSubStatusOptions() {
                const selectedReassignmentStatus = this.reassignStatuses.selected
                if (selectedReassignmentStatus == 4) 
                    return this.wipSubStatuses

                if (selectedReassignmentStatus == 1)
                    return this.researchedSubStatuses

                if (selectedReassignmentStatus == 3)
                    return this.qualifiedSubStatuses

                if (selectedReassignmentStatus == 98)
                    return this.hasPotentialSubStatuses

                if (selectedReassignmentStatus == 66)
                    return this.archivedSubStatuses

                return []
            },
            autoQualifiedSubStatuses() {
                const sub = this.subStatuses?.["Hacked"] ?? [];
                return sub.length === 1 ? sub.filter(s => s.id) : sub.map(s => ({ ...s, id: s.id ?? -1}));
            },
            hasPotentialSubStatuses() {
                const sub = this.subStatuses?.["Has potential"] ?? [];
                return sub.length === 1 ? sub.filter(s => s.id) : sub.map(s => ({ ...s, id: s.id ?? -1}));
            },
            qualifiedSubStatuses() {
                const sub = this.subStatuses?.["Qualified"] ?? [];
                return sub.length === 1 ? sub.filter(s => s.id) : sub.map(s => ({ ...s, id: s.id ?? -1}));
            },
            acceptedSubStatuses() {
                const sub = this.subStatuses?.["Accepted"] ?? [];
                return sub.length === 1 ? sub.filter(s => s.id) : sub.map(s => ({ ...s, id: s.id ?? -1}));
            },
            archivedSubStatuses() {
                const sub = this.subStatuses?.["Archived"] ?? [];
                return sub.length === 1 ? sub.filter(s => s.id) : sub.map(s => ({ ...s, id: s.id ?? -1}));
            },
            wipSubStatuses() {
                const sub = this.subStatuses?.["Work in Progress"] ?? [];
                return sub.length === 1 ? sub.filter(s => s.id) : sub.map(s => ({ ...s, id: s.id ?? -1}));
            },
            researchedSubStatuses() {
                const sub = this.subStatuses?.["Researched"] ?? [];
                return sub.length === 1 ? sub.filter(s => s.id) : sub.map(s => ({ ...s, id: s.id ?? -1}));
            },
            isAllowedQAReassignment() {
                return [439,271,221].includes(this.user.id)
            },
            isReassignWithReason() {
                /* return !!((this.individualReassign.status.selected && ([2,8,3,98].includes(this.currentStatus) || !([4].includes(this.individualReassign.status.selected) && [1, 99].includes(this.currentStatus)) || ([4].includes(this.individualReassign.status.selected) && this.access_level != 1)))
                    || (this.reassignStatuses.selected && ([2,8,3,98].includes(this.currentStatus) || !([4].includes(this.reassignStatuses.selected) && [1, 99].includes(this.currentStatus)) || ([4].includes(this.reassignStatuses.selected) && this.access_level != 1)))) */

                return !this.isFeatureRestricted('v-reassignment-reasons-selection');
            },
            isReassignWIP() {
                return !!((this.individualReassign.status.selected && [4].includes(this.individualReassign.status.selected) && [2,8,3,98].includes(this.currentStatus))
                    || (this.reassignStatuses.selected && [4].includes(this.reassignStatuses.selected) && [2,8,3,98].includes(this.currentStatus)))
            },
            currentStatus() {
                return this.$route.query.status == 'All' ? -1 : this.$parent.getQFlagId(this.$route.query.status)
            },
            canReassignWithCampaign() {
                return this.isCLAccount || this.isSuperAdmin
            },
            canReassignWithStatus() {
                return ![1, 2].includes(this.access_level);
            },
            active_li() {
                return this.active_listing_info[0] ?? {}
            },
            attached_lists() {
                var lid = this.searched_lid
                var lists = this.lists
                // if(lid.trim() == ""){
                //     return lists
                // }else{
                //     return lists.filter(list=>{
                //         return list.rid.toLowerCase().startsWith(lid.trim())
                //     })
                // }
                return lists
            },
            checkDuplicateRids() {
                return this.rids.includes(this.entered_rid.trim())
            },
            next_page() {
                var page = this.current_page
                if (this.current_page < this.last_page) page = +page + 1
                return page
            },
            prev_page() {
                var page = this.current_page
                if (this.current_page > 1) page = +page - 1
                return page
            },
            last_page() {
                return this.lists_meta.no_of_pages
            },
            listing_from_count() {
                return this.lists.length > 0
                    ? (this.current_page > 1)
                        ? ((this.current_page * this.page_size) - this.page_size) + 1
                        :  1
                    : 0
            },
            listing_to_count() {
                return this.lists.length > 0
                    ? ((this.current_page * this.page_size) - this.page_size + this.lists_meta.items_in_this_page)
                    : 0
            },
            host(){
                return process.env.VUE_APP_URL
            },
            access_level(){
                return JSON.parse(localStorage.getItem('ver')).access_level
            },
            checkAuthorization() {
                const current = this.active_listing_info[0]
                return this.$store.state.is_active_list_ready &&
                    // (this.access_level == 1 && [4, 2].includes(current?.qflag_id) && current?.user_id == JSON.parse(localStorage.getItem('ver')).id) || // New condition - restrict researcher to reassign in searchpage = Marvin 08/04/2022
                    // (this.access_level == 2 && [2].includes(current?.qflag_id)) ||
                    // (this.access_level == 5 && [2,4,1,8,10,98,99].includes(current?.qflag_id)) || // Old Condition
                    (this.access_level == 5 && [4, 8, 98, 99, 66].includes(current?.qflag_id)) || // New Condition - Allow to reassign CLs if current listing is "Researched", "Reworked", "Has Potential", "Invalid" = Marvin 08/04/2022
                    // (this.access_level == 7 && [4,2,1,8,3,10,98,99].includes(current?.qflag_id))  // Old condition
                    (this.access_level == 7 && [1,2,3,4,8,98,99,66].includes(current?.qflag_id))  //  New Condition - Allow to reassign CLs if current status/tab is "Work in Progress", "Recheck", "Reworked", "Researched", "Qualified", "Has Potential", "Invalid" = Marvin 08/04/2022
            },
            checkSelectedAuth() {
                let authorize = true
                var listings = this.lists.filter(list => this.selected_listings.includes(list.rid))
                for (var j = 0; j < listings.length; j++) {
                    if (
                        // (this.access_level == 1 && (![4, 2].includes(listings[j].qflag_id) || listings[j].user_id != JSON.parse(localStorage.getItem('ver')).id)) || // New condition - restrict researcher to reassign in searchpage = Marvin 08/04/2022
                        // (this.access_level == 2 && (![2].includes(listings[j].qflag_id))) ||
                        (this.access_level == 2) ||
                        // (this.access_level == 5 && (![2,4,1,8,10,98,99].includes(listings[j].qflag_id))) || // Old condition
                        (this.access_level == 5 && (![4, 8, 98, 99, 66].includes(listings[j].qflag_id))) || // New Condition - Allow to reassign CLs if current listing is "Researched", "Reworked", "Has Potential", "Invalid" = Marvin 08/04/2022
                        // (this.access_level == 7 && (![4,2,1,8,3,98,99].includes(listings[j].qflag_id))) // Old condition
                        (this.access_level == 7 && (![1,2,3,4,8,98,99,66].includes(listings[j].qflag_id))) || //  New Condition - Allow to reassign CLs if current status/tab is "Work in Progress", "Recheck", "Reworked", "Researched", "Qualified", "Has Potential", "Invalid" = Marvin 08/04/2022
                        (this.access_level == 6)
                    ) {
                        authorize = false
                        break
                    }
                    // commented Marvin 08/18/2022
                    // else if([221, 439].includes(this.user.id)) {
                    //     authorize = ([2,8].includes(listings[j].qflag_id))
                    //     break
                    // }
                }
                return authorize
            },
            canChangeStatus() {
                return this.$store.state.is_active_list_ready &&
                    (([1].includes(this.access_level) && [4, 2].includes(this.active_listing_info[0].qflag_id)) ||
                    [2,5,7].includes(this.access_level))
            },
            isListingInfoReady() {
                return this.$store.state.is_active_list_ready
            },
            today() {
                return new Date().toISOString().split('T')[0]
            },
            has_date_qualified_filter() {
                // return ['Qualified', 'Monitoring', 'Accepted', 'Rejected', 'Filed'].includes(this.$route.query.status)
                return true
            },
            dem_platforms() {
                // to call the filter drawer essence and its function. hehehe - Marvin 01/30/20
                let filter_drawer = this.$parent.$children.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]
                return filter_drawer.platforms.filter(platform => this.$route.query.platform.split(',').includes(platform.value.toString()))
            },
           required_filters_has_value() {
                if ([this.searched_seller, this.specific_url].includes('')) return false
                if (this.rids.length == 0 || this.selected_campaign == 0) return false
                return true
            },
            active_campaign() {
                    let campaign = this.modal_campaign
                    if(this.isListingInfoReady)
                        campaign = this.active_listing_info[0]?.campaign || ''
                    return campaign
            },
            sort_by_display() {
                let display = ""
                switch (this.sort_by) {
                    case "researcher":
                        display = "Researcher"
                        break
                    case "qa":
                        display = "QA"
                        break
                    case "created_at":
                        display = "Date Added"
                        break
                    case "qualified_at":
                        display = "Date Qualified"
                        break
                    default:
                        display = "LID"
                        break
                }
                return display
            },
            filtered_researchers(){//List of QA or Researcher based on Reassignment Mode (reassignment_mode)
                let researchers = this.researchers
                let mode        = this.reassignment_mode
                // if(mode == 'Researcher')
                //     return researchers.filter(f=> [1,5].includes(f.access_level))
                // else
                //     return researchers.filter(f=> [7,2].includes(f.access_level))

                if (this.isQAAccount) {
                    let tempOptions = [{groupLabel: "Researcher", groupValues: []}, {groupLabel: "QA", groupValues: []}]

                    researchers = researchers.filter(u => [1, 2].includes(u.access_level))
                    researchers.forEach(c => tempOptions[c.access_level - 1].groupValues.push(c))
                    researchers = tempOptions
                }

                if (this.isResearcher || this.isCLAccount)
                    researchers = researchers.filter(u => [1].includes(u.access_level))

                if (this.isSuperAdmin)
                    researchers = researchers.filter(u => [1, 5].includes(u.access_level))

                return researchers
            },
            user(){
                return JSON.parse(localStorage.getItem("ver"))
            },
            for_merging_lists() {
                return this.lists.filter(list => this.selected_listings.includes(list.rid));
            },
            checkSelectedForBulkMerge() {
                /* if (this.access_level == 1)
                    return false */

                if (this.isFeatureRestricted('v-button-merge-evidence')) {
                    return false;
                }

                let authorize = true
                var listings = this.lists.filter(list => this.selected_listings.includes(list.rid))
                for (var j = 0; j < listings.length; j++) {
                    if (![1,3,2,8,98,31,32,33,34].includes(listings[j].qflag_id) || listings[j].no_of_evidence <= 2) {
                        authorize = false
                        break
                    }
                }
                return authorize
            },
            mergeStatus() {
                return {
                    ongoing: this.for_merging_lists.find(l => l.merge_status == 102) != null,//102 = processing
                    error: this.for_merging_lists.find(l => l.merge_status && ![102, 200, 205].includes(l.merge_status)) != null,//205 = reset/cancelled
                    done: this.for_merging_lists.every(l=> l.merge_status == 200)
                };
            },
            merge_percentage_status() {
                return 100 * (this.for_merging_lists.filter(l => l.merge_status == 200).length / this.for_merging_lists.length);
            },
            enableInvalidTagging() {
                let status     = this.$route.query.status.toLowerCase()
                /**
                 * Deliverable: Hide all For Rework/Reworked elements across all GUI
                 * Deliverable Number: 454
                 */
                const statuses = ['work in progress', 'has potential'/*, 'recheck', 'reworked'*/]
                // return ([5, 2].includes(+this.access_level) && statuses.includes(status)) || [5].includes(+this.access_level)

                return !this.isFeatureRestricted('v-status-change-invalid') && statuses.includes(status);
            },
            canProceedToExport() {
                let __selected_listings_campaigns = [...new Set(this.lists.filter(l => this.selected_listings.includes(l)).map(c => c.campaign_id))]
                if (this.selected_listings.length > 1000 && __selected_listings_campaigns.length > 1) {
                    this.$parent.showSnackbar("Exporting limit is 1000 listings only for different campaigns", true, 'warning', 'fas fa-exclamation-triangle', true)
                    return false
                }
                return true
            },
            reworkTabIcon() {
                let reasons_icon = this.reworkTimeline ? "/static/svg/rework-modifications/Tab_PredefinedReason_Inactive.svg" : "/static/svg/rework-modifications/Tab_PredefinedReason_Active.svg"
                let timeline_icon = this.reworkTimeline ? "/static/svg/rework-modifications/Tab_ReworkTimeline_Active.svg" : "/static/svg/rework-modifications/Tab_ReworkTimeline_Inactive.svg"
                return { reasons: reasons_icon, timeline: timeline_icon }
            },
            allSelectedReasons() {
                return {
                    reasons    : this.selected_predefined_reasons,
                    reasonsInfo: this.selected_predefined_sub_reasons,
                    otherReason: this.selected_other_reason
                }
            },
            isCLAccount() {
                return [5].includes(this.access_level)
            },
            isSuperAdmin() {
                return [7].includes(this.access_level)
            },
            isQAAccount() {
                return [2].includes(this.access_level)
            },
            isResearcher() {
                return [1].includes(this.access_level)
            },
            reassignStatusOptionsOld() {
                let statuses = []
                if ([5, 7].includes(this.access_level)) {
                    statuses = [{
                        label: "Work in Progress",
                        value: 4
                    },
                    /**
                     * Deliverable: Hide all For Rework/Reworked elements across all GUI
                     * Deliverable Number: 454
                     */
                    // {
                    //     label: "For Rework",
                    //     value: 2,
                    // },
                    {
                        label: "Invalid",
                        value: 99,
                    },
                    {
                        label: "Researched",
                        value: 1,
                    },
                    /**
                     * Deliverable: Hide all For Rework/Reworked elements across all GUI
                     * Deliverable Number: 454
                     */
                    // {
                    //     label: "Reworked",
                    //     value: 8,
                    // },
                    {
                        label: "Qualified",
                        value: 3,
                    },
                    {
                        label: "Has Potential",
                        value: 98,
                    }]

                    if ([5].includes(this.access_level)) {
                        // if ([1,98,99].includes(this.currentStatus))
                        //     statuses = statuses.filter(s => s.value != 4)

                        // if ([8].includes(this.currentStatus))
                        //     statuses = statuses.filter(s => s.value != 2)

                        // If current status/tab or active listing modal is "Researched", "Has Potential", "Invalid" then option is only "Work in Progress"
                        if ([this.currentStatus, this.active_listing_info[0]?.qflag_id ?? 0].some(s => [4, 98, 99].includes(s)))
                            statuses = statuses.filter(s => s.value == 4)

                        /**
                         * Deliverable: Hide all For Rework/Reworked elements across all GUI
                         * Deliverable Number: 454
                         */
                        // // If current status/tab or active listing modal is "Reworked" then option is only "For Rework"
                        // else if ([this.currentStatus, this.active_listing_info[0]?.qflag_id ?? 0].includes(8))
                        //     statuses = statuses.filter(s => s.value == 2)
                    }
                }

                return statuses
            },
            reassignStatusOptions() {
                let statuses = [
                    { label: 'Work in Progress', value: ListingStatus.WIP },

                    /**
                     * Deliverable #552: Removal of Invalid status from Artemis
                     */
                    // { label: 'Invalid', value: ListingStatus.Invalid },
                    
                    { label: 'Researched', value: ListingStatus.Researched },
                    { label: 'Qualified', value: ListingStatus.Qualified },
                    { label: 'Has Potential', value: ListingStatus.HasPotential },
                    { label: 'Archived', value: ListingStatus.Archived },
                ];

                if ([ListingStatus.Researched, ListingStatus.Archived].includes(this.currentStatus)) {
                    return statuses.filter(status => status.value === ListingStatus.WIP);
                }

                /**
                 * Remove one status from the statuses with the given parameter.
                 * @param { number } statusToRemove 
                 */
                const filterStatus = (statusToRemove) => {
                    const filteredStatuses = statuses.filter(status => status.value !== statusToRemove);
                    return filteredStatuses;
                }

                if (this.isFeatureRestricted('v-reassignment-wip-status')) {
                    statuses = filterStatus(ListingStatus.WIP);
                }

                if (this.isFeatureRestricted('v-reassignment-invalid-status')) {
                    statuses = filterStatus(ListingStatus.Invalid);
                }

                if (this.isFeatureRestricted('v-reassignment-researched-status')) {
                    statuses = filterStatus(ListingStatus.Researched);
                }

                if (this.isFeatureRestricted('v-reassignment-qualified-status')) {
                    statuses = filterStatus(ListingStatus.Qualified);
                }

                if (this.isFeatureRestricted('v-reassignment-haspo-status')) {
                    statuses = filterStatus(ListingStatus.HasPotential);
                }

                return statuses.filter(status => encodeURI(status.label) !== encodeURI(this.$route.query.status));
            },
            get_existing_listings() {
                let result = null
                if(this.hasExisting_listings){
                    result = this.computed_reassignmentFailed_meta_data.map(item => {
                        return { 
                            duplicate : item.duplicate.map(dup => {
                                return {
                                    lid: dup.id,
                                    qflag_id_word: this.$parent.getQFlag(dup.qflag_id)
                                }
                            }),
                        }
                    })
                }
                return result
            },
            computed_reassignmentFailed_meta_data() {
                return this.reassignmentFailed_meta_data
            },
            itemTypeOptions() {
                return [{label: "No Item Type", value: "No Item Type"}, ...this.filter_item_type_options.map(f => ({label: f.type, value: f.type}))]
            },
            purchaseTypeOptions() {
                /**
                 * Update purchase type values in Artemis
                 * Deliverable #: 506
                 */

                // return [
                //     {label: "No Purchase Type", value: -1 },
                //     {label: "Successfully purchased and allowed to ship", value: "Successfully purchased and allowed to ship"},
                //     {label: "Successfully purchased and cancelled/refunded", value: "Successfully purchased and cancelled/refunded"},
                //     {label: "Tried purchasing but failed", value: "Tried purchasing but failed"},
                //     {label: "Tried purchasing and got account", value: "Tried purchasing and got account"},
                //     {label: "No need to purchase", value: "No need to purchase"}
                // ]
                return [
                    {label: "No need to purchase", value: "No need to purchase", sub: []},
                    {label: "Purchase attempt failed", value: "Purchase attempt failed", sub: []}, // done
                    {label: "Successfully purchased and cancelled/refunded", value:'Successfully purchased and cancelled/refunded',
                        sub: [
                            // Breakdown
                            {label: "Purchase cancelled by buyer", value: "Purchase cancelled by buyer"},
                            {label: "Purchase cancelled by seller", value: "Purchase cancelled by seller"},
                            {label: "Purchase cancelled by platform", value: "Purchase cancelled by platform"},
                            {label: "Purchase refunded", value: "Purchase refunded"},
                        ],
                    },
                    // new entry
                    {label: "Pending seller confirmation", value: "Pending seller confirmation", sub: []},
                    {label: "Purchased, shipping pending", value: "Purchased, shipping pending", sub: []},
                    {label: "Successfully purchased and allowed to ship", value: "Successfully purchased and allowed to ship",
                        sub: [
                            // Breakdown
                            {label: "Purchased and shipped", value: "Purchased and shipped", sub: []},
                            {label: "Delivery delayed", value: "Delivery delayed", sub: []},
                            {label: "Delivery failed/unsuccessful", value: "Delivery failed/unsuccessful", sub: []},
                            {label: "Delivered", value: "Delivered", sub: []},
                        ]
                    },
                    // {label: "Tried purchasing and got account", value: "Tried purchasing and got account"}, // remove 
                    {label: "No Purchase Type", value: -1, sub: [] },
                ]
            },
            disabledReassignAccounts() {
                const ids = [938, 243, 861, 221, 878, 249, 927, 355, 453, 1017, 241, 937, 389, 939, 237, 940]
                const current = this.user?.id
                let isDisabled = false

                if (ids.includes(current)) {
                    isDisabled = true
                }
                else isDisabled = false

                return isDisabled
            },
            selectedStatusChangeReasons() {
                const statusChangeReasons = this.filter_status_change_reason.options.map(reason => reason.value);
                return this.filter_status_change_reason.selected.filter(reasonId => statusChangeReasons.includes(reasonId));
            }
        },
        beforeCreate(){
            let ver = JSON.parse(localStorage.getItem("ver"))
            if(ver == null){
                this.$router.push('/verification')
            }
        },
        created() {
            this.getResearchers()
            this.getCampaigns()
            // this.getPaymentMethods()
            this.getReasons()
            // this.getSellerLocations()
            // this.getClaimTypes()
            // this.getItemTypes()
            // // this.getRoles()
            // // this.getEmployees()
            // this.getShippingLocations()
        },
        mounted() {
            document.title = this.$route.meta.title
             //Removing searched Listing IDs when pressing Alt + X
            document.addEventListener('keydown', event => {
                if (event.altKey && event.key === 'x' || event.altKey && event.key === 'X') {
                    this.rids = []
                }
            })
            let filter_drawer_dom = document.querySelector('.a-sidebar.a-sidebar-filter')
            filter_drawer_dom.style.paddingBottom = "63px"
            this.adjustChipInputHeight()
            if(this.$route.query.lid != null) {//Search the working listing from error when changing status
                this.entered_rid = this.$route.query.lid
                this.appendRids()
                this.getListing()
            }
            // this.getItemClasses()
            this.getPredefinedReasons(this.$parent.getQFlagId(this.$route.query.status))
        },
        methods: {
            ...mapActions(["enableTabs","getActiveListingInfo", "changeShowError", 'setRelatedCampaignDuplicates', 'setRelatedCampaignDuplicateBypass', 'setRelatedCampaignDuplicateContinueFunction']),
            toggleSubStatusReassignment(e, id) {
                this.subStatusesStore.currentSelectedSubStatus = null
                this.subStatusesStore.currentSelectedSubStatus = id
            },
            async getItemLocations() {
                const queryStatus = this.$route.query.status
                const selectedStatus = (queryStatus == 'All') ? 0 : this.$parent.getQFlagId(this.$route.query.status)
                try {
                    const { data } = await this.$http.get(`/api/listing-locations/campaigns/${this.selected_campaign}/status/${selectedStatus}`, this.$session.get('ver_headers'))

                    let result = data.data.map(d => ({ label: `${d.value} [${d.count}]`, value: d.value, count: d.count }))
                    result.sort((a, b) => (a.label > b.label)? 1 : ((b.label > a.label)? -1 : 0))
                    this.filters__.item_location.options = result

                    if (this.filters__.item_location.options.length) {
                        const countNoItemLocation = data.meta.noLocationCount
                        this.filters__.item_location.options.unshift({ label: `No Item Location [${countNoItemLocation}]`, value: '---', count: countNoItemLocation })
                    }
                } catch (ex) {
                    console.log(ex)
                }
            },
            async getClaimTypes() {
                const { data } = await this.$http.get("claimtype?page_size=10000", this.$session.get("ver_headers"));
                
                this.filter_claimtype.options = [{label: "No Claimtype", value: -1}, ...data.data.map(d => ({ label: d.claim_type, value: d.id }))]
            },
            async getShippingLocations() {
                const { data } = await this.$http.get("shipping_locations?page_size=10000", this.$session.get("ver_headers"));

                // this.filter_shipping_location.options = [{label: "No Shipping Location", value: -1}, ...data.data.filter(d => d.active).map(d => ({ label: d.location, value: d.id }))] // old options - Marvin 5/16/2023
                this.filter_shipping_location.options = [...data.data.filter(d => d.active).map(d => ({ label: d.location, value: d.location }))]
            },
            async getItemTypes() {
                try {
                    const { data } = await this.$http.get(`classification_types?page_size=100000`, this.$session.get('ver_headers'))

                    this.filter_item_type_options = data.data
                } catch (ex) {
                    console.log(ex)
                }
            },
            async getRoles() {
                const { data } = await this.$http.get('roles/multi?page_size=1000', this.$session.get('ver_headers'))

                this.filter_roles.options = data.data.map(d => ({ label: d.name, value: d.id}))
            },
            getEmployeeName(first, last) {
                return (first + " " + last).trim()
            },
            async getEmployees() {
                try {
                    const { data } = await this.$http.get('users?page_size=100000', this.$session.get('ver_headers'))

                    this.filter_employee.options = data.data.filter(d => d.status).map(d => ({label: this.getEmployeeName(d.first_name, d.last_name), value: d.id}))
                } catch (ex) {
                    console.log(ex)
                }
            },
            appendRids(e){
                if (this.entered_rid != "" && !this.checkDuplicateRids) {
                    this.entered_rid.replace(/\s+/g, ',').split(',').filter(rid =>
                    {
                        if (!this.rids.includes(rid) && rid != "")
                            this.rids.push(rid.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, ''))
                    })
                    this.entered_rid = e.target.value = ""
                    this.selected_listings = []
                }
                if (this.show_error) {
                    this.changeShowError();
                }
            },
            removeLastRid() {
                if (this.entered_rid == "") this.rids.pop()
                this.selected_listings = []
                if (this.show_error) {
                    this.changeShowError();
                }
            },
            removeChip(remove_rid){
                this.rids = this.rids.filter(rid => { return rid != remove_rid })
                this.selected_listings = []
            },
            cleanPasteInput() {
                let value = this.entered_rid.replace(/[^0-9,]+s/g, '');
                this.entered_rid = value;
                $('#input-rids').val(value);
            },
            getCampaigns(){
                // Loading Snackbar
                this.$parent.showSnackbar("Gathering quite a lot of campaigns.. Please wait..", true)
                this.disableInputs()
                let headers = JSON.parse(localStorage.getItem('ver')).headers
                axios.get(process.env.VUE_APP_URL+"/campaigns/main?page_size=1000", JSON.parse(headers))
                    .then(response=>{
                        let results = response.data
                        this.campaigns_select = results.data.map(campaign=>{
                            return {value:campaign.id,label:campaign.name}
                        })
                        if (this.campaigns_select.length > 0){
                            this.campaigns_select = this.campaigns_select.filter(campaign => campaign != null).sort((a, b) => {
                                return ((a || {}).label || "").localeCompare((b || {}).label || "")
                            })
                        }
                        this.$parent.hideSnackbar()
                        this.disableInputs(false)
                    })
            },
            async getResearchersCampaigns(user_id) {
                user_id = user_id?.value ?? user_id

                this.researchers_campaigns_select = []

                if (!user_id) return
                this.$parent.showSnackbar("Getting selected user's campaign(s). Please wait..", true)
                let headers = JSON.parse(JSON.parse(localStorage.getItem('ver')).headers)
                return await axios.get(process.env.VUE_APP_URL+`/campaigns/main/user/${user_id}?page_size=1000`, headers)
                    .then(response=>{
                        let results = response.data
                        this.researchers_campaigns_select = results.data.map(campaign=> ({value:campaign.id,label:campaign.name}))
                        this.$parent.showSnackbar("User's campaign(s) successfully fetched.", false, 'success', 'fas fa-check-circle');
                    })
            },
            getCampaignsResearchers(campaign_id) {
                let headers = JSON.parse(JSON.parse(localStorage.getItem('ver')).headers)
                if (campaign_id == 0) {
                    this.campaigns_researchers_select = []
                    return;
                }
                axios.get(process.env.VUE_APP_URL+`/users/in/campaign/${campaign_id}?page_size=1000`, headers)
                    .then(response=>{
                        let results = response.data
                        this.campaigns_researchers_select = results.data.filter(user => user != null).map(user=>{
                            return {value:user.id,label:(user.first_name != null ? user.first_name.toString().trim() : "")+" "+(user.last_name != null ? user.last_name.toString().trim() : "")}
                        })
                        this.input_key.reassign.researcher = this.getRandomKey();
                    })
            },
            async getListing(){
                // cancel incoming request if searching flag is true - Marvin 01/30/20
                if (this.searching == true) return

                if (this.rids.length > 500) {
                    this.$parent.showSnackbar("Simultaneous lid search limited to only 500. Please try again.", false, 'warning', 'fas fa-exclamation-triangle');
                    this.rids = []
                    return
                }

                let headers = JSON.parse(JSON.parse(localStorage.getItem('ver')).headers)
                let order_by = 0
                let conditions = {}
                this.searching = true
                // to call the filter drawer essence and its function. hehehe - Marvin 01/30/20
                let filter_drawer = this.$parent.$children.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]
                filter_drawer.applyPlatformFilters()
                if (this.selected_campaign != 0) {
                    conditions.campaign_id = this.selected_campaign
                }
                if (this.rids.length != 0) {
                    conditions.id = this.rids.join()
                }
                if (this.specific_url != '') {
                    conditions.url = this.specific_url.trim()
                }

                if (this.$route.query.status != 'All') {
                    conditions.qflag_id = this.$parent.getQFlagId(((this.$route.query.status == 'For Rework') ? 'Recheck' : this.$route.query.status).toLowerCase())
                }
                if (this.$route.query.platform != 0) {
                    conditions.platform_id = this.$route.query.platform.split(",")
                }
                if (this.min_date_added != "")
                    conditions.created_at = { from: `${this.min_date_added} 00:00:00`, to: `${this.max_date_added} 23:59:59`}
                if (this.has_date_qualified_filter && this.min_date_qualified != "")
                    // conditions.date_qualified = { from: this.min_date_qualified, to: this.max_date_qualified }
                    conditions.qualified_at = { from: `${this.min_date_qualified} 00:00:00`, to: `${this.max_date_qualified} 23:59:59` }
                if (this.filter_case_number != "" && ['Filed', 'All'].includes(this.$route.query.status))
                    // conditions.case_number = this.filter_case_number.trim()
                    conditions["case.case_number"] = this.filter_case_number.trim()
                
                
                if (this.selected_campaign != 0 && this.filters__.item_location.selected != 0)
                    conditions.location = this.filters__.item_location.selected
                else if (this.filter_item_location != "") {
                    // conditions.listing_location = this.filter_item_location.trim()
                    conditions.location = this.filter_item_location.trim()
                } 
                
                if (this.filter_seller_location != "0")
                    conditions.seller_location = { with: this.filter_with_seller_country, value: this.filter_seller_location }
                if (this.selected_payment_methods.length > 0)
                    // conditions.payment_methods = this.selected_payment_methods
                    conditions.seller_payment_type = this.selected_payment_methods
                if (this.selected_reasons.length > 0)
                    // conditions.reasons = { with: this.infringement_option, value: this.selected_reasons }
                    conditions.infringement_indicators = { with: this.infringement_option, value: this.selected_reasons }
                if (this.filter_researcher != '')
                    conditions.researcher = this.filter_researcher.trim()
                if (this.filter_qa != '')
                    conditions.qa = this.filter_qa.trim()
                if (this.filter_email != '')    // added feature EMAIL
                    // conditions.email = this.filter_email.trim()
                    conditions.generic_email = this.filter_email.trim()
                if (this.filter_alias != '')    // // added feature ALIAS
                    // conditions.alias = this.filter_alias.trim()
                    conditions.seller_alias = this.filter_alias.trim()
                if (this.filter_seller_url)
                    conditions.store_url = this.filter_seller_url
                if (this.filter_item_class != "" && this.filter_item_class != "0")
                    conditions.item_class = { with: this.filter_with_item_class, value: this.filter_item_class }
                if (this.filter_item_type.length > 0)
                    // conditions.item_types = { with: this.filter_with_item_type, value: this.filter_item_type.map(f => f.label) }
                    conditions.item_type = { with: this.filter_with_item_type, value: this.filter_item_type.map(f => f.label) }
                if (this.shown_data.includes("RD")) {
                    // conditions.my_researcher_data = "1"
                    let id = JSON.parse(localStorage.getItem('ver')).id
                    conditions.user_id = id
                }
                if (this.shown_data.includes("QD")) {
                    // conditions.my_qa_data = "1"
                    let id = JSON.parse(localStorage.getItem('ver')).id
                    conditions.qa_user_id = id
                }
                
                if (this.filter_claimtype.selected != 0)
                    // conditions.claimtype = { with: this.filter_with_claimtype, value: this.filter_claimtype.selected }
                    conditions.claim_type = { with: this.filter_with_claimtype, value: this.filter_claimtype.selected }
                
                if (this.filter_shipping_location.selected != 0)
                    conditions.shipping_location = { with: this.filter_with_shipping_location, value: this.filter_shipping_location.selected }

                if (this.filter_roles.selected.length) {
                    let roles =[]
                    this.filter_roles.selected.forEach(s => {
                        roles.push(s.value)
                    })
                    // conditions.roles = this.filter_roles
                    conditions.roles = roles
                }

                // if (this.filter_employee.selected != 0)
                //     conditions.employee = this.filter_employee.selected

                if (this.filter_purchase_type != 0)
                    conditions.purchase_type = this.filter_purchase_type

                if (this.selectedStatusChangeReasons.length) {
                    conditions.status_change_reason = { with: this.filter_with_status_change_reason, value: this.selectedStatusChangeReasons }
                }
                
                if (this.enable_options.includes('brand_name_on_image_option'))
                    // conditions.brand_on_item_image = !!(+this.filter_brand_name_on_image)
                    conditions.brand_on_item_image = this.filter_brand_name_on_image
                
                if (this.enable_options.includes('logo_on_image_option'))
                    // conditions.logo_on_item_image = !!(+this.filter_logo_on_image)
                    conditions.logo_on_item_image = this.filter_logo_on_image

                if (this.enable_options.includes('will_ship_to_us_option'))
                    // conditions.will_ship_to_us = !!(+this.filter_will_ship_to_us)
                    conditions.will_ship_to_us = this.filter_will_ship_to_us

                if (this.enable_options.includes('commission_option'))
                    // conditions.commission = !!(+this.filter_commission)
                    conditions.commission = this.filter_commission

                if(this.filterDateCaptured !== '') conditions.date_captured = this.filterDateCaptured
                
                // headers.headers.Sort = `${this.sort_by == '0' ? 'lid' : this.sort_by},${this.order_by == '0' ? 'desc' : this.order_by}`
                // headers.headers.Where = JSON.stringify(conditions)
                this.filters_set = JSON.stringify(conditions)
                filter_drawer.getPlatforms()
                this.searched_seller = this.searched_seller.trim()
                if (this.searched_seller != '') {
                    // headers.headers['Seller-name'] = this.searched_seller
                    conditions.seller_name = this.searched_seller
                }
                if (this.selected_campaign != 0 || this.rids.length > 0 || this.specific_url != '' || this.searched_seller != '') {
                    this.initialized = true
                    // Loading overlay
                    this.$parent.showBackdrop()
                    this.disableInputs()

                    // await axios.get(process.env.VUE_APP_URL + `/listings/main/search?page_size=${this.page_size}&page=${this.current_page}`,headers)
                    //         .then(response=>{
                    //             let results = response.data
                    //             this.lists = results.data.map(list => {
                    //                 return {
                    //                     product_img   : list.evidences.preview != null ? list.evidences.preview : list.evidences.Preview,
                    //                     seller_name   : list.seller[0] !== undefined ? list.seller[0].name != "" || list.seller[0].name !== null ? list.seller[0].name: '------': [],
                    //                     seller_url    : list.seller[0] !== undefined ? list.seller[0].url != "" || list.seller[0].url !== null ? list.seller[0].url : '------': [],
                    //                     if_reassigned : false,
                    //                     rid           : list.id,
                    //                     progress      : 90,
                    //                     status        : list.status == "Recheck" ? "For Rework" : list.status,
                    //                     platform      : list.platform,
                    //                     platform_img  : this.$parent.getImage('platforms', list.platform || 'Default'),
                    //                     product_url   : list.url,
                    //                     details       : list.listing_info.listing_title,
                    //                     qflag_id      : list.qflag_id,
                    //                     commission    : list.for_commission,
                    //                     user_id       : list.user_id,
                    //                     campaign      : list.campaign,
                    //                     campaign_id   : list.campaign_id,
                    //                     campaign_img  : this.$parent.getImage('campaigns', list.campaign),
                    //                     date          : moment(list.created_at).format('YYYY-MM-DD'),
                    //                     last_rework   : moment(list.last_rework).format('YYYY-MM-DD'),
                    //                     claim_type    : list.claim_type,
                    //                     payment_type  : list.payment_type,
                    //                     owner         : list.verifier_owner != null
                    //                                   ? (list.verifier_owner.first_name != null ? list.verifier_owner.first_name.trim(): "")+" "+(list.verifier_owner.last_name != null ? list.verifier_owner.last_name.trim(): "")
                    //                                   : "",
                    //                     qualifier     : list.qualifier,
                    //                     case_number   : list.case_number,
                    //                     error         : false,
                    //                     merge_status  : null,
                    //                     merge_data    : null,
                    //                     no_of_evidence: list.no_of_evidence
                    //                 }
                    //             })
                    //             this.lists_meta  = results.meta
                    //             this.lists_links = results.links
                    //             this.loaded      = true
                    //             this.searching   = false
                    //             this.disableInputs(false)
                    //             setTimeout(() => {
                    //                 document.querySelector('.custom-chip-textfield input').focus()
                    //             });
                    //             // hide Loading overlay
                    //             this.$parent.hideBackdrop()
                    //         })

                    let sort
                    if(this.sort_by == 0) {
                        this.sort_by = 'id'
                    }

                    if(this.order_by == 0 || this.order_by == 'desc') {
                        sort = `-${this.sort_by}`
                    } else {
                        sort = `${this.sort_by}`
                    }

                    let queries = {
                        include: ["campaign", "case", "claimType", "evidences", "info", "seller.accounts", "platform", "qualifier", "user", "qflag"],
                        includeFilter: {
                            // evidences: {
                            //     type: "preview"
                            // },
                            info: {
                                field: ["date_captured", "item_type", "listing_title"]
                            },
                        },
                        filter: conditions,
                        sort: sort,
                    }

                    if(this.$route.query.status.toLowerCase() == 'filed') {
                        queries.include.push('caseSeller')
                        queries.include = queries.include.filter(inc => inc != 'seller.accounts')
                    }

                    await axios.get(`${process.env.VUE_APP_URL}/api/v2/listings?${qs.stringify(queries, { encodeValuesOnly: true })}&page_size=${this.page_size}&page=${this.current_page}`,headers)
                            .then(response=>{
                                let results = response.data
                                this.lists = results.data.map(list => {
                                    // listing info mapping
                                    let info = {}
                                    list.info.forEach(i => {
                                        info[i.field] = i.value
                                    })
                                    list.listing_info = info

                                    // qualifier mapping
                                    if(list.qualifier !== null) {
                                        let qualifier = `${list.qualifier.first_name} ${list.qualifier.last_name}`
                                        list.qualifier = qualifier
                                    }

                                    // get product_img or preview
                                    let product_img
                                    if(list.evidences !== null) {
                                        product_img = list.evidences.filter(e => e.type == 'preview')
                                    }

                                    return {
                                        product_img   : product_img !== null && product_img.length > 0 ? product_img[0].file_name2 : '',
                                        seller_name   : this.$route.query.status.toLowerCase() == 'filed' ? (list.case_seller && list.case_seller[0] && list.case_seller[0].name) : (list.seller !== undefined && list.seller !== null ? list.seller.name != "" || list.seller.name !== null ? list.seller.name: '------': null),
                                        seller_url    : this.$route.query.status.toLowerCase() == 'filed' ? (list.case_seller && list.case_seller[0] && list.case_seller[0].url) : (list.seller !== undefined && list.seller !== null ? list.seller.url != "" || list.seller.url !== null ? list.seller.url : '------': null),
                                        if_reassigned : false,
                                        rid           : list.id,
                                        progress      : 90,
                                        status        : this.$parent.getQFlag(list.qflag_id),
                                        platform      : list.platform !== null && list.platform && list.platform.name,
                                        platform_img  : this.$parent.getImage('platforms', list.platform !== null && list.platform && list.platform.name || 'Default'),
                                        product_url   : list.url,
                                        details       : list.listing_info.listing_title,
                                        qflag_id      : list.qflag_id,
                                        commission    : list.for_commission,
                                        user_id       : list.user_id,
                                        campaign      : list.campaign !== null && list.campaign.name,
                                        campaign_id   : list.campaign_id,
                                        campaign_img  : this.$parent.getImage('campaigns', list.campaign !== null && list.campaign.name),
                                        date          : moment(list.created_at).format('YYYY-MM-DD'),
                                        last_rework   : moment(list.last_rework).format('YYYY-MM-DD'),
                                        claim_type    : list.claim_type !== null ? list.claim_type.claim_type : list.claim_type,
                                        payment_type  : list.payment_type,
                                        owner         : list.user != null
                                                      ? (list.user.first_name != null ? list.user.first_name.trim(): "")+" "+(list.user.last_name != null ? list.user.last_name.trim(): "")
                                                      : "",
                                        qualifier     : list.qualifier,
                                        case_number   : list.case && list.case !== null && list.case.case_number,
                                        error         : false,
                                        merge_status  : null,
                                        merge_data    : null,
                                        no_of_evidence: list.evidences !== null && list.evidences.length
                                    }
                                })

                                let results_copy = {}
                                let for_meta = ['last_page','current_page','per_page','total']
                                let for_meta_equivalent = {
                                    last_page: 'no_of_pages',
                                    current_page: 'page',
                                    per_page: 'page_size',
                                    total: 'total_no_of_items',
                                }
                                
                                Object.entries(results).forEach(r => {
                                    const [key, value] = r
                                    if(for_meta.includes(key))  {
                                        results_copy[for_meta_equivalent[key]] = value
                                    }
                                    // console.log(key,value)
                                    // console.log(key, for_meta_equivalent[index])
                                })
                                // results_copy['items_in_this_page'] = this.lists.length
                                results_copy['items_in_this_page'] = results.data.length

                                this.lists_meta = results_copy
                                this.lists_links = results.links
                                this.loaded      = true
                                this.searching   = false
                                this.disableInputs(false)
                                setTimeout(() => {
                                    document.querySelector('.custom-chip-textfield input').focus()
                                });
                                // hide Loading overlay
                                this.$parent.hideBackdrop()
                            })
                } else {
                    this.lists       = []
                    this.lists_meta  = {
                        total_no_of_items : 0,
                        no_of_pages : 0
                    }
                    this.loaded      = false
                    this.initialized = false
                    this.searching   = false
                }
            },
            preview(){
                let evidences = this.active_listing_info[0].evidences
                for(let key in evidences){
                    if(key.toLowerCase().includes("preview")){
                        var preview = evidences[key]
                    }
                }
                return preview != null && preview.trim() != ''
                    ? `${process.env.VUE_APP_URL}/files/${preview}`
                    : this.themeListingPreview
            },
            refreshData() {
                this.getListingInfo(this.active_listing_id, 0, true)
            },
            async getListingInfo(listing_id, user_id = 0, refresh = false) {
                this.active_listing_id            = listing_id
                this.change_status                = false
                this.status_history_tab           = true
                // this.getCampaignsResearchers(campaign_id)
                this.emptyReassignFields()
                this.resetReassignData()
                // this.getResearchersCampaigns(user_id)
                //Check if listing_id has already loaded listing_info in this.lists
                let active_listing_info = this.lists.find(list => list.rid == listing_id)?.active_listing_info || []
                if(active_listing_info.length > 0 && !refresh)
                    this.active_listing_info = active_listing_info
                else {
                    this.active_listing_info = await this.getActiveListingInfo(listing_id)
                    this.lists = this.lists.map(list => {
                        if(list.rid == listing_id)
                            list.active_listing_info = this.active_listing_info
                        return list
                    })
                }
            },
            navigate(page) {
                this.$router.push({name: page})
            },
            /*
            * Used to put css on rid input programmatically, I can do it in css only but sometimes
            * conflicts on the component style, so programmatic it is - Dev: Marvin 12/14/2019
            */
            adjustChipInputHeight() {
                setTimeout(() => {
                    var chipInput = document.getElementsByClassName('custom-chip-textfield')[0]
                    chipInput.style.minHeight = "40px"
                    chipInput.style.maxHeight = "80px"
                    chipInput.style.overflow  = "auto"
                })
            },
            /*
            * Function to check if the value or the value entered is a number, dot, or comma, if not, then no no no. hahaha - Dev: Marvin 12/14/2019
            */
            isNumber(e) {
                var event = e || window.event;
                var keyCode = event.keyCode || event.which;
                var key = String.fromCharCode(keyCode);
                var regex = /^[0-9.,\b]+$/;
                if (key.length == 0) return;
                if(keyCode == 188 || keyCode == 190) return;
                else {
                    if (!regex.test(key)) {
                        event.returnValue = false;
                        if (event.preventDefault) event.preventDefault();
                    }
                }
            },
            disableInputs(status = true) {
                setTimeout(() => {
                    var chip_input = document.querySelector('.custom-chip-textfield')
                    var inputs = document.querySelectorAll('.titlebar-con input')
                    for(var i = 0; i < inputs.length; i++) {
                        if (status) {
                            chip_input.classList.add('disabled')
                            inputs[i].setAttribute('disabled', 'disabled')
                            this.input_disabled = true
                        } else {
                            chip_input.classList.remove('disabled')
                            inputs[i].removeAttribute('disabled')
                            this.input_disabled = false
                        }
                    }
                });
            },
            updateListingPage() {
                var page_input = document.getElementById('pagination_input')
                if (page_input.value > this.last_page) page_input.value = this.last_page
                else if (page_input.value < 1) page_input.value = 1
                this.updatePage(page_input.value)
            },
            getSelectListing(rid){
                if(!this.selected_listings.includes(rid)){
                    this.selected_listings.push(rid)
                    document.getElementById(rid).parentNode.parentNode.parentNode.parentNode.classList.add('checked')
                }else{
                    this.selected_listings = this.selected_listings.filter(list=>{return list != rid})
                    document.getElementById(rid).parentNode.parentNode.parentNode.parentNode.classList.remove('checked')
                }
                this.researchers_campaigns_select = []
            },
            getResearchers(){
                let header = JSON.parse(JSON.parse(localStorage.getItem("ver")).headers)
                header.headers.where = JSON.stringify({"access_level":[1,2,5,7]})
                axios.get(process.env.VUE_APP_URL+`/users/multi?page_size=1000`,header)
                .then(response=>{
                    let results = response.data
                    if(results.meta.code == 200){
                        // this.researchers.push(...results.data.map(researcher=>{return {"value":researcher.id,"label":researcher.first_name+" "+(researcher.last_name != null ? researcher.last_name : ""),"access_level":researcher.access_level} }))
                        this.researchers = results.data
                            .map(researcher => ({
                                "access_level":researcher.access_level,
                                "value":researcher.id,
                                "label":researcher.first_name+" "+(researcher.last_name != null ? researcher.last_name : "")
                            }))
                    }
                }).catch(err=> this.$parent.showSnackbar(err.response.data.meta + 'Please contact system admin.', false, 'invalid', 'fas fa-times-circle') )
            },
            resetReassignData() {
                this.reassign_campaign = 0
                this.show_predefined_reasons = false
                this.reassign_researcher = 0
                this.reassignStatuses.selected = 0
                this.individualReassign.status.selected = 0
                this.individualReassign.user.selected = 0
                this.individualReassign.campaign.selected = 0
                $('#reassign-user .input-icon')?.click()
                $('#reassign-campaign .input-icon')?.click()
                $('#reassign-status-select .input-icon')?.click()
                $('#individualReassign-user .input-icon')?.click()
                $('#individualReassign-status .input-icon')?.click()
                this.reassignStatuses.results = []
                this.individualReassign.show = false
                this.reassignmentFailed_meta_data = []
                this.hasExisting_listings = false
                this.individualReassign_hasError = false
            },
            resetIndividualReassignment() {
                $('#listing-modal').modal('show')
                $('#reassign-searchpage-modal').modal('hide')
                this.resetReassignData()
                this.switchHistoryAndReassign(false)

                // this.reassignStatuses.results = []
                // this.reassignmentFailed_meta_data = []
                // this.hasExisting_listings = false
                // this.individualReassign.show = false
                // this.individualReassign_hasError = false
            },
            showReassignModal() {
                if (!this.checkSelectedAuth || !this.checkSelectedStatusForReassignmentIsSame)
                    return
                $('#reassign-searchpage-modal').modal('show')
                if(!this.individualReassign_hasError){
                    this.resetReassignData()
                }
            },
            /**
             * @param {Array<number>} lids
             * @param {number} status
             * @param {string} initiator
             * @param {string} modal
             * @returns {boolean} Has potential whitelist sellers
             */
            async checkPotentialWhitelists(lids, status, initiator = 'changeStatus', modal = 'listing-modal') {
                if(this.isCheckingPotentials) return
                this.isCheckingPotentials = true
                this.onModalClose = () => $(`#${modal}`).modal('show')
                const sellers = this.lists.filter(({rid}) => lids.includes(rid))
                    .map(({seller_name, seller_url}) => ({name: seller_name, url: seller_url}))
                const {success, data, message} = await this.$store.dispatch('checkPotentialWhitelists', sellers)
                if(!success) {
                    this.$parent.showSnackbar(message, false, 'invalid', 'fas fa-times-circle')
                    return false
                }
                if(data.length === 0) return false
                await this.$store.dispatch('setPotentialWhitelists', data)
                /* $(`#${modal}`).hide() */
                $(`#${modal}`).modal('hide')
                $('#potential-whitelists').modal('show')
                this.acknowledgeWhitelists = async () => {
                    const acknowledgement = {feature: 'status change', status: 1, notes: ''}
                    await this.$store.dispatch('acknowledgeWhitelist', acknowledgement)
                    $('#potential-whitelists').modal('hide')
                    $(`#${modal}`).modal('show')
                    this.hasAcknowledged = true

                    initiator === 'changeStatus'
                        ? this.changeStatus(lids[0], status)
                        : initiator === 'batchChangeStatus'
                            ? this.batchChangeStatus(lids, status)
                            : initiator === 'newReassignListing'
                                ? this.doNewReassign(true, { rid: lids[0], qflag_id: status })
                                : initiator === 'batchNewReassignListing'
                                    ? this.doNewReassign()
                                    : null

                }
                return true
            },
            async newReassignListing(lid, current_qflag, user_id, campaign_id, isIndividual = false) {
                const listing_ids = isIndividual ? [lid] : this.selected_listings

                // Check if user is QA and get the value of the selected user object
                user_id = user_id?.value ?? user_id

                // const seller = this.lists.find(l => lid === l.rid).seller_name
                const qflag = isIndividual ? this.individualReassign.status.selected : this.reassignStatuses.selected
                const header = this.$session.get('ver_headers')
                header.headers['Campaign-id'] = this.$route.params.campaign_id

                this.$parent.showSnackbar('Processing request...')

                let predefined_data = {
                    "main_reasons"   :this.selected_predefined_reasons,
                    "sub_reasons"    :this.selected_predefined_sub_reasons,
                    "listing_id"     :listing_ids,
                    "status"         :this.individualReassign.status.selected,
                    "campaign_id"    :campaign_id,
                    "other_reason_id":this.selected_other_reason,
                    "other_reason"   :this.other_reason
                }

                if (!this.isResearcher && [user_id, qflag, campaign_id].every(s => s == 0)) {
                    this.is_loading = false
                    this.reassignStatuses.loading = false
                    return this.$parent.showSnackbar('Please select user, status, or campaign first', false, "invalid", "fas fa-times-circle")
                } 
                
                // const canSelectUser = !this.isFeatureRestricted('v-reassignment-user');

                // if (canSelectUser && [user_id].includes(0)) {
                //     this.is_loading = false
                //     this.reassignStatuses.loading = false
                //     return this.$parent.showSnackbar('Please select user first', false, "invalid", "fas fa-times-circle")
                // }

                if (this.confirmedLFCNote == false && this.checkIndividualLFC && (this.selected_change_status == 99 || this.batch_change_status == 99)) {
                    this.is_loading = false
                    this.reassignStatuses.loading = false
                    return $('#lfc-confirm-invalidation').modal('show')
                }

                if(qflag && !predefined_data.main_reasons.length && !predefined_data.sub_reasons.length && !predefined_data.other_reason_id.length && this.canReassignWithStatus) {
                    this.is_loading = false
                    this.reassignStatuses.loading = false
                    return this.$parent.showSnackbar('Please select reason(s)', false, 'invalid', 'fas fa-times-circle')
                }

                if(predefined_data.other_reason_id.length && predefined_data.other_reason.trim() == '') {
                    this.is_loading = false
                    this.reassignStatuses.loading = false
                    return this.$parent.showSnackbar('Please add comment.', false, 'invalid', 'fas fa-info-circle')
                }

                // const exceptedStatus = [99, 33]
                // if(!this.hasAcknowledged && !exceptedStatus.includes(+qflag)) {
                //     const hasPotentials       = await this.checkPotentialWhitelists([lid], current_qflag, 'newReassignListing')
                //     this.isCheckingPotentials = false
                //     this.is_loading           = false

                //     if(hasPotentials) return
                // }

                const payload = {
                    listing_ids: listing_ids,
                    target_campaign_id: campaign_id == 0 ? null : campaign_id,
                    target_substatus_id: this.subStatusesStore.currentSelectedSubStatus ?? -1,
                    target_user_id: user_id == 0 ? null : user_id,
                    target_qflag_id: qflag == 0 ? null : qflag,
                    comment: "", //Always set comment to empty since there is predefined-reason to include
                    reasons: this.setPredefinedReasons(),
                }

                Object.keys(payload).forEach(k => payload[k] == null && delete payload[k])

                // if (this.showRestrictedReasons()) return;

                await this.$http.patch(`listings/bulk-reassign`, payload, header)
                .then(response=>{
                    const result = response.data

                    const isNotSuccessful = result.filter(r => !r.success)

                    if (isNotSuccessful.length) {
                        this.reassign__unsuccessful = {
                            hasActive: [],
                            notAssignedToCampaign: [],
                            duplicatesFound: [],
                            others: []
                        }
                        
                        for (let i = 0; i < isNotSuccessful.length; i++) {
                            const current = isNotSuccessful[i]
                            this.reassign__unsuccessful[current.errorMessage.includes("Seller already have an")
                                ? "hasActive"
                                : current.errorMessage.includes("Error. The researcher aren")
                                    ? "notAssignedToCampaign"
                                    : current.errorMessage == "Duplicates found"
                                        ? "duplicatesFound"
                                        : "others"
                            ].push(current)
                        }

                        $('.modal').modal('hide')

                        return $("#reassignment-unsuccessful--modal").modal('show')
                    }

                    let filter_drawer = this.$parent.$children.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]
                    filter_drawer.dem_statuses = []
                    filter_drawer.initializeStatuses()

                    this.closeModal()

                    // trigger notification to users
                    globalSend({ type: 'notification', data: { updater_id: this.user.id, researcher_id: this.active_li.user_id, qa_user_id: this.active_li.qa_user_id, } });

                    this.getListing()
                    
                    this.is_select_all = false
                    this.checkAllListing()

                    this.$parent.showSnackbar('Request processed successfully!', false, 'success', 'fas fa-check')
                    
                    this.setRelatedCampaignDuplicateBypass(false)
                    $('#related-campaign-duplicate-sellers-modal').modal('hide')

                }).catch(async err=>{
                    if (err?.response?.meta.code == 403) {
                        this.$parent.showSnackbar(`${err.response.meta.message}`,false,'invalid','fas fa-times-circle')
                        return
                    }

                    this.$parent.showSnackbar(`${err?.response?.data?.message}`,false,'invalid','fas fa-times-circle')
                })
                .finally(async() => {
                    this.is_loading = false
                    this.reassignStatuses.loading = false

                    let trigger_payload = {
                        label: 'isTriggerOn_reassigningResearcherSide',
                        value: true,
                    }
                    await this.$store.dispatch('setTrigger', trigger_payload)
                    console.log('trigger in reassign listing ver')
                })
            },
            newReassignListingX(lid, current_qflag, user_id, campaign_id, isIndividual = false) {
                return new Promise(async (resolve, reject) => {
                    // Check if user is QA and get the value of the selected user object
                    user_id = user_id?.value ?? user_id

                    const listingInfo = this.lists.find(l => lid === l.rid)
                    const seller = listingInfo.seller_name
                    const campaign = listingInfo.campaign_id
                    const qflag = isIndividual ? this.individualReassign.status.selected : this.reassignStatuses.selected
                    let header = JSON.parse(JSON.parse(localStorage.getItem("ver")).headers)
                    header.headers['Campaign-id'] = this.$route.params.campaign_id
                    if (isIndividual) this.$parent.showSnackbar('Processing request...')
                    let patch   = {}
                    let allow_research = true
                    if(qflag == 1){
                        for(let type in this.required_fields){
                            for(let field in this.required_fields[type]){
                                if(this.required_fields[type][field] == false){
                                    allow_research = false
                                }
                            }
                        }
                    }
                    let payload = {"comment":""}
                    let predefined_data = {
                        "main_reasons"   :this.selected_predefined_reasons,
                        "sub_reasons"    :this.selected_predefined_sub_reasons,
                        "listing_id"     :lid,
                        "status"         :isIndividual ? this.individualReassign.status.selected : this.reassignStatuses.selected,
                        "campaign_id"    :campaign_id,
                        "other_reason_id":this.selected_other_reason,
                        "other_reason"   :this.other_reason
                    }
                    if (isIndividual) {
                        if(!allow_research) {
                            this.is_loading = false
                            reject(new Error("Please fill all the required information before tagging this as researched."))
                            return this.$parent.showSnackbar("Please fill all the required information before tagging this as researched.", false, "invalid", "fas fa-times-circle")
                        }

                        if (!this.isResearcher && [user_id, qflag, this.individualReassign.campaign.selected].every(s => s == 0)) {
                            this.is_loading = false
                            return this.$parent.showSnackbar('Please select user, status, or campaign first', false, "invalid", "fas fa-times-circle")
                        }

                        if (this.isResearcher && [user_id].includes(0))  {
                            this.is_loading = false
                            return this.$parent.showSnackbar('Please select user first', false, "invalid", "fas fa-times-circle")
                        }

                        // if (!user_id) {
                        //     this.is_loading = false
                        //     reject(new Error('Please select user first'))
                        //     return this.$parent.showSnackbar('Please select user first', false, "invalid", "fas fa-times-circle")
                        // }

                        // if (!qflag) {
                        //     this.is_loading = false
                        //     reject(new Error('Please select status first'))
                        //     return this.$parent.showSnackbar('Please select status first', false, "invalid", "fas fa-times-circle")
                        // }

                        // if (!this.individualReassign.campaign.selected) {
                        //     this.is_loading = false
                        //     reject(new Error('Please select campaign first'))
                        //     return this.$parent.showSnackbar('Please select campaign first', false, "invalid", "fas fa-times-circle")
                        // }

                        // if (qflag == 3 || qflag == 98) {
                        //     if (this.selected_comm !== '') {
                        //         payload.for_commission = this.selected_comm == 1 ? 'yes' : (this.selected_comm == 0 ? 'no' : null)
                        //     } else {
                        //         this.is_loading = false
                        //         reject(new Error("Please select whether with or without commission"))
                        //         return this.$parent.showSnackbar("Please select whether with or without commission", false, 'invalid', 'fas fa-info-circle')
                        //     }
                        // }
                        // if(predefined_data['main_reasons'].length || predefined_data['sub_reasons'].length || predefined_data['other_reason_id'].length) {
                        // if(!predefined_data.main_reasons.length && !predefined_data.sub_reasons.length && !predefined_data.other_reason_id.length && !([4].includes(qflag) && [1, 99].includes(this.currentStatus))) {
                        if(qflag && !predefined_data.main_reasons.length && !predefined_data.sub_reasons.length && !predefined_data.other_reason_id.length && this.canReassignWithStatus) {
                            this.is_loading = false
                            reject(new Error('Please select reason(s)'))
                            return this.$parent.showSnackbar('Please select reason(s)', false, 'invalid', 'fas fa-times-circle')
                        }
                        // if(predefined_data['other_reason_id'].length && predefined_data['other_reason'].trim() == '') {
                        if(predefined_data.other_reason_id.length && predefined_data.other_reason.trim() == '') {
                            this.is_loading = false
                            reject(new Error('Please add comment.'))
                            return this.$parent.showSnackbar('Please add comment.', false, 'invalid', 'fas fa-info-circle')
                        }
                        // if(+qflag === 2) {
                        //     if(this.reworkLevel === null) {
                        //         this.is_loading = false
                        //         reject(new Error('Please select Severity Level'))
                        //         return this.$parent.showSnackbar('Please select Severity Level', false, 'invalid', 'fas fa-info-circle')
                        //
                        //     }
                        //     if(this.reworkCommission === null){
                        //         this.is_loading = false
                        //         reject(new Error('Please select Rework Commission'))
                        //         return this.$parent.showSnackbar('Please select Rework Commission', false, 'invalid', 'fas fa-info-circle')
                        //     }
                        // }
                        // this.$parent.showSnackbar('Updating status...')
                        const deduplicatedListings = await this.checkForDuplicateListing(''+lid, qflag, () => this.newReassignListing(lid, current_qflag, user_id, campaign_id, isIndividual))
                        if (deduplicatedListings == 'oh it is raining in manila, di ka ba nilalamig') {
                            this.is_loading = false
                            return
                        }

                        if(deduplicatedListings === '') {
                            this.is_loading = false
                            reject(new Error('has duplicate'))
                            return
                            // return this.$parent.hideSnackbar()
                        }
                        const exceptedStatus = [99, 33]
                        if(!this.hasAcknowledged && !exceptedStatus.includes(+qflag) && this.$route.params.campaign_id != null) {
                            const hasPotentials       = await this.checkPotentialWhitelists([lid], current_qflag, 'newReassignListing')
                            this.isCheckingPotentials = false
                            this.is_loading           = false
                            if(hasPotentials) {
                                reject(new Error('has potential whitelist'))
                                return
                            }
                        }
                    }
                    //Always set comment to empty since there is predefined-reason to include
                    payload.comment           = ''
                    payload.reasons           = this.setPredefinedReasons()
                    if(![3, 98].includes(+qflag)) {
                        payload.rework_level      = this.reworkLevel
                        payload.rework_commission = this.reworkCommission
                    }
                    await this.$http.patch(`listings/${lid}/statuschange/reassignment/${qflag}/${user_id}/${campaign_id}`, payload, header)
                    .then(response=>{
                        this.setRelatedCampaignDuplicateBypass(false)

                        let result = response.data
                        if (isIndividual) {
                            let filter_drawer = this.$parent.$children.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]
                            filter_drawer.dem_statuses = []
                            filter_drawer.initializeStatuses()
                            this.closeModal()
                            this.getListing()
                            this.is_select_all = false
                            this.checkAllListing()
                        }
                        if (isIndividual)
                            this.$parent.showSnackbar('Request processed successfully!', false, 'success', 'fas fa-check')
                        resolve(result)
                    }).catch(async err=>{
                        console.log(err)
                        const duplicate_logs = err?.response?.data?.duplicate_logs
                        if (duplicate_logs) {
                            $('.modal').modal('hide')

                            await sleep(500)

                            this.setRelatedCampaignDuplicateContinueFunction(() => this.newReassignListing(lid, current_qflag, user_id, campaign_id, isIndividual))
                            this.setRelatedCampaignDuplicates(duplicate_logs)
                            this.showSnackbar(`${err.response.data.message}`,false,'invalid','fas fa-times-circle')
                            reject({ message: new Error(err.response.data.meta.message), lid, seller })
                            return    
                        }

                        if(this.existing_and_duplicate_reasons.includes(err.response.data.meta.message)){
                            this.hasExisting_listings = true
                        }
                        this.reassignmentFailed_meta_data.push(err.response.data.meta)
                        reject({ message: new Error(err.response.data.meta.message), lid, seller })
                        if (isIndividual) {
                            console.error(err);
                            // let message = err.response.data.meta.message
                            // this.$parent.showSnackbar(message, false, 'invalid', 'fas fa-times-circle')

                            this.$parent.showSnackbar('Request processed successfully, encountered problems.', false, 'warning', 'fas fa-exclamation-triangle')
                                
                            this.reassignStatuses.results[0] = {
                                reason: { message: new Error(err.response.data.meta.message), lid, seller },
                                status: 'rejected'
                            }
                            let result_unfulfilled = this.reassignStatuses.results.filter(r => r.status === 'rejected')
                            if(this.hasExisting_listings) {
                                this.transferExistingTo_reassign(this.get_existing_listings, result_unfulfilled)
                            }
                            this.individualReassign_hasError = true
                            $('#listing-modal').modal('hide')
                            this.showReassignModal()
                        }
                    })
                    .finally(() => {
                        this.is_loading = false
                    })
                })
            },
            async batchNewReassignListing() {
                const qflag = this.reassignStatuses.selected
                const campaign_id = this.reassign_campaign
                const user_id = this.reassign_researcher

                this.reassignStatuses.loading = true

                await this.newReassignListing(null, null, user_id, campaign_id, false)
            },
            async batchNewReassignListingX() {
                let promises = []
                const qflag = this.reassignStatuses.selected
                const campaign_id = this.reassign_campaign
                const user_id = this.reassign_researcher?.value ?? this.reassign_researcher

                this.reassignStatuses.loading = true
                this.$parent.showSnackbar('Processing request...')
                for (let index = 0; index < this.selected_listings.length; index++) {
                    let lid = this.selected_listings[index]
                    let listing = this.lists.find(l => l.rid === lid)
                    let patch   = {}
                    let allow_research = true
                    // if(qflag == 1){
                    //     for(let type in this.required_fields){
                    //         for(let field in this.required_fields[type]){
                    //             if(this.required_fields[type][field] == false){
                    //                 allow_research = false
                    //             }
                    //         }
                    //     }
                    // }

                    if(!allow_research) {
                        this.reassignStatuses.loading = false
                        return this.$parent.showSnackbar("Please fill all the required information before tagging this as researched.", false, "invalid", "fas fa-times-circle")
                    }
                    let payload = {"comment":""}
                    let predefined_data = {
                        "main_reasons"   :this.selected_predefined_reasons,
                        "sub_reasons"    :this.selected_predefined_sub_reasons,
                        "listing_id"     :lid,
                        "status"         :this.reassignStatuses.selected,
                        "campaign_id"    :campaign_id,
                        "other_reason_id":this.selected_other_reason,
                        "other_reason"   :this.other_reason
                    }

                    // if ([this.reassign_researcher, this.reassignStatuses.selected, campaign_id].includes(0)) {
                    //     this.reassignStatuses.loading = false
                    //     return this.$parent.showSnackbar('Please select user, status, and campaign first', false, "invalid", "fas fa-times-circle")
                    // }

                    if (!this.isResearcher && [user_id, qflag, campaign_id].every(s => s == 0)) {
                        this.reassignStatuses.loading = false
                        return this.$parent.showSnackbar('Please select user, status, or campaign first', false, "invalid", "fas fa-times-circle")
                    }

                    if (this.isResearcher && [user_id].includes(0))  {
                        this.reassignStatuses.loading = false
                        return this.$parent.showSnackbar('Please select user first', false, "invalid", "fas fa-times-circle")
                    }

                    // if (!user_id) {
                    //     this.reassignStatuses.loading = false
                    //     return this.$parent.showSnackbar('Please select user first', false, "invalid", "fas fa-times-circle")
                    // }

                    // if (!qflag && this.canReassignWithStatus) {
                    //     this.reassignStatuses.loading = false
                    //     return this.$parent.showSnackbar('Please select status first', false, "invalid", "fas fa-times-circle")
                    // }

                    // if (!campaign_id && this.canReassignWithCampaign) {
                    //     this.reassignStatuses.loading = false
                    //     return this.$parent.showSnackbar('Please select campaign first', false, "invalid", "fas fa-times-circle")
                    // }

                    // if(predefined_data['main_reasons'].length || predefined_data['sub_reasons'].length || predefined_data['other_reason_id'].length) {
                    // if(!predefined_data.main_reasons.length && !predefined_data.sub_reasons.length && !predefined_data.other_reason_id.length && !([4].includes(qflag) && [1, 99].includes(this.currentStatus))) {
                    if(qflag && !predefined_data.main_reasons.length && !predefined_data.sub_reasons.length && !predefined_data.other_reason_id.length && this.canReassignWithStatus) {
                        this.reassignStatuses.loading = false
                        return this.$parent.showSnackbar('Please select reason(s)', false, 'invalid', 'fas fa-times-circle')
                    }
                    // if(predefined_data['other_reason_id'].length && predefined_data['other_reason'].trim() == '') {
                    if(predefined_data.other_reason_id.length && predefined_data.other_reason.trim() == '') {
                        this.reassignStatuses.loading = false
                        return this.$parent.showSnackbar('Please add comment.', false, 'invalid', 'fas fa-info-circle')
                    }
                    const deduplicatedListings = await this.checkForDuplicateListing(''+lid, qflag, () => this.batchNewReassignListing())
                    if(deduplicatedListings === '' || deduplicatedListings == 'oh it is raining in manila, di ka ba nilalamig') {
                        this.reassignStatuses.loading = false
                        return
                    }
                    const exceptedStatus = [99, 33]
                    if(!this.hasAcknowledged && !exceptedStatus.includes(+qflag) && this.$route.params.campaign_id != null) {
                        const hasPotentials       = await this.checkPotentialWhitelists([lid], listing.qflag_id, 'batchNewReassignListing', 'reassign-searchpage-modal')
                        this.isCheckingPotentials = false
                        this.is_loading           = false
                        this.reassignStatuses.loading = false

                        if(hasPotentials) return
                    }

                    promises.push(this.newReassignListing(this.selected_listings[index], listing.qflag_id, user_id, campaign_id))
                }
                const results = await Promise.allSettled(promises)
                console.log(results)
                if (results.every(r => r.status == 'fulfilled')) {
                    this.$parent.showSnackbar('Request processed successfully!', false, 'success', 'fas fa-check')
                    // this.is_select_all = false
                    this.closeModal()
                } else {
                    this.$parent.showSnackbar('Request processed successfully, encountered problems.', false, 'warning', 'fas fa-exclamation-triangle')
                    let result_fulfilled = results.filter(r => r.status == 'fulfilled')
                    console.log(result_fulfilled)
                    // this.selected_listings = this.selected_listings.filter(listing => results.some(r => r.status == 'fulfilled' && r.reason.lid == listing) )

                    let result_unfulfilled = results.filter(r => r.status === 'rejected')
                    if(this.hasExisting_listings) {
                        this.transferExistingTo_reassign(this.get_existing_listings, result_unfulfilled)
                    }
                }
                let filter_drawer = this.$parent.$children.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]
                filter_drawer.dem_statuses = []
                filter_drawer.initializeStatuses()
                this.getListing()
                this.is_select_all = false
                this.checkAllListing()

                let result_unfulfilled_length = results.filter(r => r.status === 'rejected').length
                if(this.hasExisting_listings && this.get_existing_listings.length == result_unfulfilled_length){
                    this.reassignStatuses.results = this.reassignStatuses.results.filter(r => r.status === 'rejected')
                }else {
                    this.reassignStatuses.results = results.filter(r => r.status === 'rejected')
                }
                this.reassignStatuses.loading = false
                console.log(this.reassignStatuses.results)
            },
            doNewReassign(isIndividual = false, list = null) {
                const user = isIndividual ? this.individualReassign.user.selected : this.reassign_researcher
                if (this.isSelectedUserQA(user))
                    this.reassignQA(isIndividual)
                else if (isIndividual && list != null)
                    this.newReassignListing(list.rid, list.qflag_id, user, this.individualReassign.campaign.selected, true)
                else
                    this.batchNewReassignListing()
            },
            isSelectedUserQA(data) {
                return [2].includes(data?.access_level ?? 0)
            },
            async reassignQA(isIndividual = false) {
                const selected = isIndividual ? this.individualReassign.user.selected?.value ?? this.individualReassign.user.selected : this.reassign_researcher?.value ?? this.reassign_researcher
                if(selected != 0){
                    this.$parent.showSnackbar("Updating QA Owner..")
                    let payload = {"ids":isIndividual ? this.active_listing_info[0]?.rid : this.selected_listings}
                    await axios.patch(process.env.VUE_APP_URL+`/listing/qa_user_id/${selected}`,payload,header).
                    then(response=>{
                        if(response.status == 200){
                            this.closeModal()
                            this.is_select_all = false
                            this.checkAllListing()
                            this.getListing()
                            this.$parent.showSnackbar("Successfully updated QA Owner.",false, "success", "fas fa-check-circle")
                        }
                    }).catch(err=>{
                        this.closeModal()
                        this.is_select_all = false
                        this.checkAllListing()
                        this.$parent.showSnackbar("Something went wrong. Please contact system admin.",false, "invalid", "fas fa-times-circle")
                    })
                }else{
                    this.$parent.showSnackbar("Please select QA to reassign.", false, "invalid", "fas fa-times-circle")
                }
                let filter_drawer = this.$parent.$children.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]
                filter_drawer.dem_statuses = []
                filter_drawer.initializeStatuses()
            },
            /**
             * Check for duplicate listings.
             * Show modal if has duplicate listings.
             * Set value for vm.duplicate_seller_found.
             *
             * @param {string} listing_ids
             * @param {number} qflag
             * @return {string | boolean} Listing Ids with no duplicate. False when server error occurs
             */
            async checkForDuplicateListing(listing_ids, qflag, sourceFunction = () => {}) {
                if (typeof listing_ids == 'string')
                {
                    listing_ids = listing_ids.split(',');
                }
                else if (typeof listing_ids == 'number')
                {
                    listing_ids = [listing_ids];
                }

                const header = this.$session.get('ver_headers')
                const result = await this.$http.post(`/duplicate_listings/${qflag}`, { listing_ids: listing_ids }, header)
                    .catch(err => err)

                console.log(result)

                const status = result.status
                const data = result.data

                if (data.data && data.data[0]?.duplicate_logs != null) {
                    const d = data.data[0]
                    const duplicate_logs = { location: d.duplicate_logs, data: d.duplicates }
                    $('.modal').modal('hide')
                    await sleep(500)
                    this.setRelatedCampaignDuplicateContinueFunction(() => sourceFunction())
                    this.setRelatedCampaignDuplicates(duplicate_logs)
                    this.$parent.showSnackbar(`${data.meta.message}`,false,'invalid','fas fa-times-circle')

                    return 'oh it is raining in manila, di ka ba nilalamig' // just some string to return that is not empty
                }
                
                if(status !== 200) {
                    this.closeModal()
                    this.$parent.showSnackbar('Something went wrong. Please contact System Admin', false, 'invalid', 'fas fa-times-circle')
                    return false
                }
                //Remove listing id from listing_ids that has duplicate
                // const response = data.data
                // const withDuplicateListingIds = response.map(({ reference }) => reference.id)
                // this.duplicate_listings = response.map(({reference, duplicates}) => (
                //     {
                //         id: reference.id,
                //         duplicates: duplicates.map(duplicate => (
                //             {
                //                 id   : duplicate.id,
                //                 name : duplicate.name,
                //                 qflag: duplicate.qflag_id
                //             }
                //         ))
                //     }
                // ))
                // if(this.duplicate_listings.length) {
                //     this.closeModal()
                //     $('#duplicate-listing-modal').modal('show')
                // }
                // this.setRelatedCampaignDuplicateBypass(false)
                return listing_ids
                    // .split(',')
                    // .filter(listing => !withDuplicateListingIds.includes(+listing))
                    // .join()
            },
            setPredefinedReasons() {
                /* const {listing_id} = data */
                let reasons = []
                if(this.selected_predefined_reasons.length) {
                    for(const { id } of this.selected_predefined_reasons) {
                        reasons = [...reasons, {
                            id,
                            reasons_info: [],
                            other_reason: ''
                        }]
                    }
                }
                const selectedSubReasons = this.selected_predefined_sub_reasons
                if(selectedSubReasons.length) {
                    const reasonIds = [...new Set(selectedSubReasons.map(m => m.reason_id))]
                    for(const id of reasonIds) {
                        const tempIds = selectedSubReasons.filter(f => f.reason_id === id)
                            .map(m => m.id)
                        reasons = [...reasons, {id, reasons_info: tempIds, other_reason: ''}]
                    }
                }
                if(this.selected_other_reason.length) {
                    reasons = [...reasons, {
                        id: this.selected_other_reason[0],
                        reasons_info: [],
                        other_reason: this.other_reason
                    }]
                }
                /* const headers = this.$session.get('ver_headers') */
                /* return {reasons, listing_id, qflag_id: this.batch_change_status} */
                return reasons
                /* this.$http.post(`http://localhost:8000/predefined_reasons`, payload, headers) */
            },
            setPredefinedReasonsX(data) {
                let {
                    main_reasons,
                    sub_reasons,
                    listing_id,
                    status,
                    other_reason_id,
                    other_reason
                } = data
                if(main_reasons.length || sub_reasons.length || other_reason_id.length) {
                    let _reasons = [...main_reasons]
                    if(sub_reasons.length) {
                        const set = Array.from(new Set(sub_reasons.map(r => r.reason_id)))
                        for(const reason of set) {
                            _reasons.push([reason,...sub_reasons.filter(sub => sub.reason_id == reason ).map(sub => sub.id)].join('⋮'))
                        }
                    }
                    const payload = {
                        listing_id   : listing_id,
                        qflag_id     : status,
                        reasons      : _reasons,
                        other_reason : other_reason_id.length ? {[other_reason_id[0].toString()]: other_reason.trim()} : ''
                    }
                    let allow = true
                    if(other_reason_id.length) {
                        if(other_reason.trim() === '') {
                            this.$parent.showSnackbar('Please add comment', false, 'invalid', 'fas fa-times-circle')
                            allow = false
                        }
                    }
                    if(allow) {
                        const headers = JSON.parse(JSON.parse(localStorage.getItem('ver')).headers)
                        headers.headers['Campaign-id'] = this.$route.params.campaign_id
                        this.$http.post('/predefined_reasons', payload, headers)
                            .then(response => {
                                if(response.status === 200) {
                                    this.resetData()
                                    return true
                                }
                            })
                            .catch(err => {
                                console.error(err)
                                this.$parent.showSnackbar('Something went wrong while inserting predefined reasons. Please contact system admin.', false, 'invalid', 'fas fa-times-circle')
                            })
                    }
                } else {
                    this.$parent.showSnackbar('Please select status and reason(s)', false, 'invalid', 'fas fa-times-circle')
                    return false
                }
            },
            // reassignListing(listings,status,campaign_id,researcher) {
            //     let header = JSON.parse(JSON.parse(localStorage.getItem("ver")).headers)
            //     let body   = {"listings":{}}
            //     header.headers['Campaign-id'] = campaign_id
            //     if(this.reassignment_mode === "Researcher"){
            //         if ([+status, +campaign_id, +researcher].includes(0)) {
            //             this.$parent.showSnackbar("Please select campaign and researcher to continue reassigning", false, 'info', 'fas fa-info-circle')
            //             return
            //         }
            //     }else{
            //         if(+researcher === 0){
            //             this.$parent.showSnackbar("Please select Researcher to continue reassigning", false, "info", "fas fa-info-circle")
            //         }
            //     }
            //     for(let list of listings) {
            //         body.listings[list] = this.reassignment_mode === 'Researcher' ? {"campaign_id":campaign_id,"user_id":researcher,"qflag_id":status} : {"qa_user_id":researcher}
            //     }
            //     // axios.patch('http://localhost:8000/multi', body, header)
            //     axios.patch(process.env.VUE_APP_URL+'/multi', body, header)
            //         .then(response => {
            //             let failed_listing = response.data.filter(l => +l.meta.code === 400);
            //             this.emptyReassignFields()
            //             this.closeModal()
            //             this.is_select_all = false
            //             this.checkAllListing()
            //             if(+failed_listing.length === 0){
            //                 this.$parent.showSnackbar("Successfully reassigned selected listing(s)", false, "success", "fas fa-check-circle")
            //                 setTimeout(() => {
            //                     this.getListing()
            //                     // document.querySelector('.card-con .card.a-list').click()
            //                 }, 2000)
            //             }
            //             else {
            //                 setTimeout(async () => {
            //                     this.getListing();
            //                     while (this.searching) {
            //                         await new Promise(r => setTimeout(r, 500));//delay
            //                     }
            //                     failed_listing.map(f => this.lists.filter(l => l.rid == f.id).map(l => l.error = true));
            //                     this.$parent.showSnackbar("Some listing(s) were not reassigned. Seller may already have an active listing to the requested campaign", false, "invalid", 'fas fa-times-circle')
            //                     this.changeShowError();
            //                 }, 1500);
            //             }
            //         })
            //         .catch(err => {
            //             console.log(err)
            //             this.$parent.showSnackbar("Something went wrong. Please contact system admin.")
            //         })
            // },
            ucFirst(string) {
                if (string == null || string == "")
                    return ""
                if (string.length == 1)
                    return string.charAt(0).toUpperCase()
                return string.charAt(0).toUpperCase() + string.slice(1);
            },
            name: function(first,last){
                return this.ucFirst(first)+" "+this.ucFirst(last)
            },
            isEmpty: function(value){
                if(value == null || value.length > 0){
                    return 'Not Set'
                }
                return value
            },
            // getChangeStatus(lid, status){//use in modal
            //     this.lid_to_change = {}
            //     this.selected_change_status = 0
            //     this.lid_to_change = {[lid]:status}
            //     this.selected_change_status = status
            // },
            changeStatus(){
                if(Object.keys(this.lid_to_change).length !== 0){
                    let header = JSON.parse(JSON.parse(localStorage.getItem("ver")).headers)
                    header.headers['Campaign-id'] = this.$route.params.campaign_id
                    let lid    = Object.keys(this.lid_to_change)
                    let status = this.lid_to_change[lid]
                    axios.patch(process.env.VUE_APP_URL+`/listings/${lid}/statuschange`,{"qflag_id":status},header)
                        .then(response=>{
                            if(response.data.meta.code == 200){
                                let campaign_id = this.$route.params.campaign_id
                                this.$router.push({name:"listing",params:{campaign_id}})
                            }
                        }).catch(err=>{
                            this.$parent.showSnackbar("Something went wrong. Please contact system admin.")
                        })
                }
            },
            switchHistoryAndStatus(bool){
                if(this.access_level == 1){
                    let qflag_id = this.active_listing_info[0].qflag_id
                    if(qflag_id == 4 || qflag_id == 2){
                        this.change_status = bool
                    }
                    this.selected_change_status = 0
                }else{
                    this.change_status = bool
                    this.selected_change_status = 0
                }
                document.querySelector('.modal.show').scrollTo(0, 0)
            },
            switchHistoryAndReassign(bool) {
                this.change_status = bool
                this.status_history_tab = !bool

                if (!this.isResearcher) this.individualReassign.show = bool

                document.querySelector('.modal.show').scrollTo(0, 0)
            },
            closeModal() {
                $('.modal').modal('hide')
            },
            emptyReassignFields() {
                this.single_reassign_researcher = 0
                this.single_reassign_campaign = 0
                this.single_reassign_status = 0
                this.reassign_researcher = 0
                this.reassign_campaign = 0
                this.reassign_status = 0
                this.researchers_campaigns_select = []
                setTimeout(() => {
                    var fields = document.querySelectorAll('.modal .search-select input[type=text]')
                    if (fields.length > 0) {
                        fields.forEach(field => {
                            field.value = ""
                        })
                    }
                })
            },
            checkAllListing(){
                // this.select_all_text = this.is_select_all ? "Unselect" : "Select"
                this.selected_listings = []
                var inputs = document.querySelectorAll("input[name='listings']");
                for(let i = 0; i < inputs.length; i++) {
                    this.selected_listings.push(parseInt(inputs[i].value))
                    inputs[i].checked = this.is_select_all;
                    if (this.is_select_all)
                        inputs[i].parentNode.parentNode.parentNode.parentNode.classList.add('checked')
                    else
                        inputs[i].parentNode.parentNode.parentNode.parentNode.classList.remove('checked')
                }
                if(this.is_select_all == false){
                    this.selected_listings = []
                }
                this.is_select_all = !this.is_select_all
            },
            filterHistory(history) {
                return history.map(x=> {
                    if(!x.user.length)
                        x.user.push({first_name:"Undefined",last_name:"User"})
                        return x
                })
            },
            arrangeList(order) {
                this.order_by = order
            },
            updatePage(page) {
                this.current_page = page
                this.is_select_all = false
                this.checkAllListing()
                this.getListing()
                window.scrollTo(0, 0)
            },
            updateShowNumberItems() {
                switch (this.show_number_of_items) {
                    case "1":
                        this.page_size = 25
                        break
                    case "2":
                        this.page_size = 50
                        break
                    case "3":
                        this.page_size = 100
                        break
                    case "4":
                        this.page_size = 150
                        break
                    default:
                        this.page_size = 10
                        break
                }
            },
            returnDefault(value){
                return value ? value : "-----"
            },
            uploadedEvidence(evidences){
                /*let rearranged   = []
                let product      = []
                let store        = []
                let conversation = []
                let checkout     = []
                let add_evidence = []
                let asin         = []
                let google       = []
                let cover        = []
                // console.log(evidences)
                for(let key in evidences){
                        let index = key.toLowerCase()
                    if(index.includes("preview")){
                        rearranged.push({"image":evidences[key],"type":key})
                    }else if(index.includes("lf")){
                        rearranged.push({"image":evidences[key],"type":key})
                    }else if(index.includes("product")){
                        product.push({"image":evidences[key],"type":key})
                    }else if(index.includes("store")){
                        store.push({"image":evidences[key],"type":key})
                    }else if(index.includes("conversation")){
                        conversation.push({"image":evidences[key],"type":key})
                    }else if(index.includes("checkout")){
                        checkout.push({"image":evidences[key],"type":key})
                    }else if(index.includes("additional")){
                        add_evidence.push({"image":evidences[key],"type":key})
                    }else if(index.includes("asin")){
                        asin.push({"image":evidences[key],"type":key})
                    }else if(index.includes("google")){
                        google.push({"image":evidences[key],"type":key})
                    }else{
                        cover.push({"image":evidences[key],"type":key})
                    }
                }
                return  [...rearranged,...product,...store,...conversation,...checkout,...add_evidence,...asin,...google,...cover].filter(image=>{
                        return image != null
                    })*/
                let arr     = []
                const types = ['preview', 'lf review', 'product', 'store', 'conversation', 'checkout', 'additional evidence', 'asin location info', 'seller name google search', 'cover', 'merge']
                let getEvidence = type => arr.filter(evidences => evidences.type.toLowerCase().includes(type))
                for(let key in evidences) {
                    arr.push({type: key, image: evidences[key]})
                }
                let new_evidences = []
                for (let type of types) {
                    let temp = getEvidence(type)
                    if(temp.length > 1) {
                        temp.forEach(each => new_evidences.push(each))
                        continue
                    }
                    new_evidences.push(temp[0])
                }
                return new_evidences
                    .filter(evidence => evidence !== undefined)
            },
            editListing(){
                var lid         = this.active_listing_id
                var campaign_id = this.selected_campaign
                this.closeModal()
                this.$router.push({name:"listinginfo",params:{campaign_id},query:{action:"edit","lid":lid,"qflag_id":this.qflag}})
                // location.reload()
            },
            getStatusIcon(id, field) {
                if (field == "qflag_id")
                    return this.$parent.getQFlagIcon(parseInt(id))
                return ""
            },
           showPreview(type,file) {
                if(file != null){
                    let ext = file.split(".")[1]
                    if("pdf".includes(ext))
                        return '/static/svg/PDF Default.svg'
                    else {
                        if(type == 'lf review' && file.includes('Missing')) {
                            file = file.replace(' | Missing', '')
                            if(file.includes('LF Review'))
                                file = file.replace('LF Review', 'lf review')
                            else
                                file = file.replace('lf review', 'LF Review')
                        }
                        return this.host+'/files/'+file
                    }
                }
            },
            showMissing(type){
                if(type.includes("Missing")){
                    let ext = type.split(".")[1].split("|")[0].trim()
                    if(["png","jpg","jpeg"].includes(ext))
                        return "this.src='/static/svg/IMG_BROKEN.svg'"
                    else
                        return "this.src='/static/svg/PDF_BROKEN.svg'"
                }
            },
            downloadExcel(campaign, campaign_id,listings){
                // let campaign_name = document.getElementsByClassName('search-campaign')[1].value
                // let campaign_name = this.selected_campaign_name
                // let id = this.selected_campaign
                // console.log(campaign_name)
                let campaign_names = [];
                let campaign_ids = [];
                listings.forEach(id => {
                    let details = this.lists.filter(listing => listing.rid == id)[0];
                    if (!campaign_ids.includes(details.campaign_id)) {
                        campaign_names.push(details.campaign);
                        campaign_ids.push(details.campaign_id);
                    }
                });
                localStorage.setItem("source", "search page");
                localStorage.setItem("ver_listings",listings)
                let route = this.$router.resolve({name:"export", params:{campaign_id: campaign_ids.toString()}, query:{name:campaign_names.toString()}})
                window.open(route.href,"_blank")
            },
            applyFilters() {
                if (this.expanded)
                    this.expanded = !this.expanded
                this.applied_filters_count = this.filter_set_count()
                this.updateShowNumberItems()
                this.updatePage(1)
            },
            resetFilters() {
                if (this.expanded)
                    this.expanded = !this.expanded
                let filter_drawer = this.$parent.$children.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]
                let checked = document.querySelectorAll('input[name=platforms]:checked')
                // this.show_number_of_items = '0'
                this.updateShowNumberItems()
                this.order_by = "desc"
                this.infringement_option = '1'
                this.selected_reasons = []
                this.selected_payment_methods = []
                this.max_date_added = ''
                this.max_date_qualified = ''
                this.filter_seller_location = '0'
                this.filter_item_location = ''
                this.filter_case_number = ''
                this.filter_researcher = ''
                this.filter_qa = ''
                this.filter_email = ''
                this.filter_alias = ''
                this.filter_seller_url = ''
                this.filter_item_class = 0
                this.filterDateCaptured = ''
                this.filter_brand_name_on_image = '1'
                this.filter_logo_on_image = '1'
                this.filter_will_ship_to_us = '1'
                this.filter_with_shipping_location = '1'
                this.filter_shipping_location.selected = '0'
                this.filter_with_claimtype = '1'
                this.filter_claimtype.selected = '0'
                this.filters__.item_location.selected = 0
                this.filter_status_change_reason.selected = [];
                this.filter_purchase_type = 0;
                // this.filter_seller_url = ""
                this.filter_product_url = ""
                this.enable_options = []
                checked.forEach(platform => {
                    platform.checked = false
                })
                document.querySelectorAll('input[name=platforms]')[0].checked = true
                filter_drawer.joined_platforms = '0'
                filter_drawer.applyPlatformFilters()
                this.applied_filters_count = this.filter_set_count()
                this.updatePage(1)
            },
            removeFilter(filter_type, id) {
                let filter_drawer = this.$parent.$children.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]
                if (filter_type == 'reasons') {
                    this.selected_reasons = this.selected_reasons.filter(flag => flag != id)
                    this.updatePage(1)
                } else if (filter_type == 'payment_methods') {
                    this.selected_payment_methods = this.selected_payment_methods.filter(method => method != id)
                    this.updatePage(1)
                } else if (filter_type == 'platforms') {
                    let split = (this.$route.query.platform || '').split(',')
                    let filtered = split.filter(platform => platform != id)
                    let checked = document.querySelectorAll('input[name=platforms]:checked')
                    for (var i = 0; i < checked.length; i++) {
                        if (checked[i].value == 0) continue
                        if (checked[i].value == id)
                            checked[i].checked = false
                    }
                    checked = document.querySelectorAll('input[name=platforms]:checked')
                    if (checked.length == 0) {
                        document.querySelectorAll('input[name=platforms]')[0].checked = true
                    }
                    filter_drawer.joined_platforms = (filter_drawer.platforms.filter(platform => filtered.includes(platform.value.toString())) || []).map(platform => platform.value).join()
                    filter_drawer.applyPlatformFilters()
                }
            },
            getPaymentMethods() {
                let config = JSON.parse(JSON.parse(localStorage.getItem("ver")).headers)
                axios.get(`${process.env.VUE_APP_URL}/payment_types?page_size=1000`, config)
                    .then(response => {
                        this.payment_methods = response.data.data.filter(method => !["AAAA BBQ","testme","testssss","sm reward card","Sample Payment Method (Test)","Pinangga Card III"].includes(method.payment_name)).map(method => ({value: method.id, label: method.payment_name }))
                    }
                )
            },
            getReasons(){
                var config = JSON.parse(JSON.parse(localStorage.getItem("ver")).headers)
                axios.get(process.env.VUE_APP_URL+"/criteria?page_size=100",config)
                    .then(response=>{
                        var results = response.data
                        this.reasons = results.data.map(result=>{
                            return {label:result.title,value:result.id}
                        })
                    }).catch(err=>{alert(`${err.response.data.meta.message}. Please contact system admin.`)})
            },
            // updateInfringementOption(option) {
            //     this.infringement_option = option
            // },
            updateOption(key, value) {
                this[key] = value;
            },
            formatDate(date) {
                const months = ['January','February','March','April','May','June','July','August','September','October','November','December']
                const formatDate = new Date(date)
                const year = formatDate.getFullYear()
                const day = formatDate.getDate()
                const month = months[formatDate.getMonth()]
                return `${month} ${day}, ${year}`
            },
            getSellerLocations() {
                let config = JSON.parse(JSON.parse(localStorage.getItem("ver")).headers)
                axios.get(`${process.env.VUE_APP_URL}/countries?page_size=1000`, config)
                    .then(response => {
                        this.seller_locations = [ {label: "No Seller Country", value: -1}, ...response.data.data.map (country => ({value: country.id, label: country.name })) ]
                    }
                )
            },
            trySearch() {
                if (this.expanded) return;
                this.applyFilters()
            },
            filter_set_count(includeStatusChangeReason = true) {
                let filters_count = 0
                filters_count = this.selected_payment_methods.length + this.selected_reasons.length
                if (this.min_date_added != '') filters_count++
                if (this.min_date_qualified != '') filters_count++
                if (this.filter_item_location != '' || this.filters__.item_location.selected != 0) filters_count++
                if (this.filter_seller_location != '0') filters_count++
                if (this.filter_case_number != '' && this.$route.query.status == 'Filed') filters_count++
                if (this.filter_researcher != '') filters_count++
                if (this.filter_qa != '') filters_count++
                if (this.filter_email != '') filters_count++    // added feature EMAIL
                if (this.filter_alias != '') filters_count++    // added feature ALIAS
                if (this.filter_seller_url != '') filters_count++
                if (this.filter_item_class != 0) filters_count++
                if (this.filter_item_type.length) filters_count++
                if(this.filterDateCaptured !== '') filters_count++
                if (this.enable_options.includes('brand_name_on_image_option')) filters_count++
                if (this.enable_options.includes('logo_on_image_option')) filters_count++
                if (this.enable_options.includes('will_ship_to_us_option')) filters_count++
                if (this.enable_options.includes('commission_option')) filters_count++
                if (this.filter_shipping_location.selected != 0) filters_count++
                if (this.filter_claimtype.selected != 0) filters_count++
                if (this.selectedStatusChangeReasons.length && includeStatusChangeReason) filters_count++
                if (this.filter_roles.selected.length) filters_count++
                if (this.filter_employee.selected != 0) filters_count++
                if (this.filter_purchase_type != 0) filters_count++
                // if (this.filter_seller_url != '') filters_count++
                
                return filters_count
            },
            canBulkMerge() {
                if (this.access_level == 1)
                    return false
                /**
                 * Deliverable: Hide all For Rework/Reworked elements across all GUI
                 * Deliverable Number: 454
                 */
                if (!["researched", "qualified", /*"recheck", "reworked", - Hide For Rework & Reworked*/ "has potential", "monitoring", "accepted", "rejected", "filed"].includes(decodeURI(this.$route.params.status.toLowerCase())))
                    return false
                return true
            },
            resetMergeData() {
                this.for_merging_lists.forEach(l => {
                    l.merge_status = null;
                    l.merge_data = null;
                });
                this.error_merge_only = false;
            },
            async startBulkMerge() {
                for (let i = 0; i < this.for_merging_lists.length; i++) {
                    let cur_listing = this.for_merging_lists[i];
                    if (cur_listing.merge_status == 200) {
                        continue;
                    }
                    if (this.cancel_merging) {
                        cur_listing.merge_status = 205;
                        this.cancel_merging = false;
                        this.$parent.showSnackbar('Cancelled', false, 'success', 'fas fa-check');
                        break;
                    }
                    cur_listing.merge_status = 102;
                    let response = await this.merge(cur_listing);
                    cur_listing.merge_status = response.status;
                    if (cur_listing.merge_status == 200) {
                        cur_listing.merge_data = response.data.data;
                    }
                }
            },
            async retryMerge(listing) {
                listing.merge_status = 102;
                let response = await this.merge(listing);
                listing.merge_status = response.status;
                if (listing.merge_status == 200) {
                    listing.merge_data = response.data.data;
                }
            },
            cancelMerging() {
                this.cancel_merging = true;
                this.$parent.showSnackbar('Cancelling...', true);
            },
            downloadAllMerged() {
                this.downloading_merge_file = true;
                axios
                    .post(`${process.env.VUE_APP_URL}/merge/evidences`, { files: this.for_merging_lists.filter(l => l.merge_status == 200).map(l => l.merge_data.filename) }, this.$session.get('ver_headers'))
                    .then(response => {
                        window.open(`${process.env.VUE_APP_URL}/zip/${response.data.data}`, '_blank');
                        this.downloading_merge_file = false;
                    })
                    .catch(ex => {
                        console.log(ex);
                        this.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', 'fas fa-times-circle');
                        this.downloading_merge_file = false;
                    });
            },
            downloadMerged(listing) {
                window.open(`${process.env.VUE_APP_URL}/files/${listing.merge_data.filename}?download=1`, '_blank');
            },
            async merge(listing) {
                let response = {};
                try {
                    response = await axios.post(`${process.env.VUE_APP_URL}/merge/evidences/${listing.rid}`, {}, this.$session.get('ver_headers'));
                }
                catch(ex) {
                    response = { status: 500 };
                }
                return response;
            },
            getRandomKey() {
                return Math.random().toString(36).substr(2, 5);
            },
            batchInvalid() {
                this.$parent.showSnackbar('Processing request...')
                this.is_loading = true
                let payload = {"comment":""}
                let predefined_data = {
                    "main_reasons"   :this.selected_predefined_reasons,
                    "sub_reasons"    :this.selected_predefined_sub_reasons,
                    "status"         :99,
                    "other_reason_id":this.selected_other_reason,
                    "other_reason"   :this.other_reason
                }
                if(!predefined_data.main_reasons.length && !predefined_data.sub_reasons.length && !predefined_data.other_reason_id.length) {
                    this.is_loading = false
                    return this.$parent.showSnackbar('Please select reason(s)', false, 'invalid', 'fas fa-times-circle')
                }
                // if(predefined_data['other_reason_id'].length && predefined_data['other_reason'].trim() == '') {
                if(predefined_data.other_reason_id.length && predefined_data.other_reason.trim() == '') {
                    this.is_loading = false
                    return this.$parent.showSnackbar('Please add comment.', false, 'invalid', 'fas fa-info-circle')
                }
                //Always set comment to empty since there is predefined-reason to include
                payload.comment           = ''
                payload.reasons           = this.setPredefinedReasons(),
                payload.listing_ids       = this.selected_listings.join(),
                this.$http.patch(`${process.env.VUE_APP_URL}/listings/multiple/statuschange/99`, payload,  this.$session.get('ver_headers'))
                    .then(async _ => {
                        this.setRelatedCampaignDuplicateBypass(false)
                        this.$parent.showSnackbar('Successfully updated status', false, 'success', 'fas fa-check-circle')
                        $('#set-invalid-modal').modal('hide')
                        $('#related-campaign-duplicate-sellers-modal').modal('hide')
                        await this.$router.push({name: 'search', query: {status: 'All', platform: 0}})
                        this.getListing()
                        this.is_select_all = false
                        this.checkAllListing()
                    })
                    .catch(err => {
                        console.error(err)
                        const duplicate_logs = err?.response?.data?.meta?.duplicate_logs
                        if (duplicate_logs) { 
                            this.setRelatedCampaignDuplicateContinueFunction(() => this.batchInvalid())
                            this.setRelatedCampaignDuplicates(duplicate_logs)
                            this.$parent.showSnackbar(err.response.data.meta.message, false, "invalid", "fas fa-exclamation-circle")
                            return
                        }
                        this.$parent.showSnackbar('Something went wrong. Please contact System Admin', false, 'invalid', 'fas fa-times-circle')
                    })
                    .finally(_ => this.is_loading = false)
            },
            batchInvalidOld() {
                if(this.batch_comment == '') {
                    this.$parent.showSnackbar('Please provide a comment', false, 'invalid', 'fas fa-times-circle');
                    return false
                }
                this.is_loading = true
                let listings = this.selected_listings.join(',')
                let body = {
                    comment: this.batch_comment,
                    listing_ids: listings
                }
                this.$http.patch(`${process.env.VUE_APP_URL}/listings/multiple/statuschange/99`, body,  this.$session.get('ver_headers'))
                    .then(async _ => {
                        this.setRelatedCampaignDuplicateBypass(false)
                        this.$parent.showSnackbar('Successfully updated status', false, 'success', 'fas fa-check-circle')
                        $('#set-invalid-modal').modal('hide')
                        $('#related-campaign-duplicate-sellers-modal').modal('hide')
                        await this.$router.push({name: 'search', query: {status: 'All', platform: 0}})
                        this.getListing()
                    })
                    .catch(err => {
                        console.error(err)
                        const duplicate_logs = err?.response?.data?.meta?.duplicate_logs
                        if (duplicate_logs) { 
                            this.setRelatedCampaignDuplicateContinueFunction(() => this.batchInvalidOld())
                            this.setRelatedCampaignDuplicates(duplicate_logs)
                            this.$parent.showSnackbar(err.response.data.meta.message, false, "invalid", "fas fa-exclamation-circle")
                            return
                        }
                        this.$parent.showSnackbar('Something went wrong. Please contact System Admin', false, 'invalid', 'fas fa-times-circle')
                    })
                    .finally(_ => this.is_loading = false)
            },
            getItemClasses(){
                let config      = JSON.parse(JSON.parse(localStorage.getItem("ver")).headers)
                axios.get(process.env.VUE_APP_URL+`/classifications?page_size=1000`,config)
                    .then(response=>{
                        let results = response.data
                        this.item_class = results.data.map(result=>{
                            return {label: `[Class ${result.number ?? 'n/a'}] ${result.class}`, value: result.class}
                        })
                    }).catch(err=>{alert(`${err.response.data.meta.message}. Please contact system admin.`)})
            },
            async getChangeStatus(id, status){//use in changing status in listing modal
                //Reset reworkLevel and reworkCommission
                this.reworkCommission = null
                this.reworkLevel      = null
                this.hasAcknowledged = false
                this.reworkTimeline  = false
                await this.resetSelectedStatus()
                this.resetCommission()
                this.fetching_reasons         = false
                this.lid_to_change           = {}
                // this.selected_change_status  = 0
                // this.selected_comm           = ''
                // this.lid_to_change        = {[lid]: status}
                this.selected_change_status  = id
                this.predefined_status = status
                let clicked_statuses   = this.clicked_statuses //To know the current and previous clicked status
                let allow_get_predefined = true

                if (!this.isCLAccount || !this.isResearcher) {
                    /**
                     * Deliverable: Hide all For Rework/Reworked elements across all GUI
                     * Deliverable Number: 454
                     */
                    if(['Researched'/*,'Reworked' Hide Reworked*/].includes(status)){
                        for(let key in this.required_fields){
                            for(let value in this.required_fields[key]){
                                if(!this.required_fields[key][value]){
                                    allow_get_predefined = false
                                    this.show_predefined_reasons = false
                                }
                            }
                        }
                    }else{
                        this.show_required_list = false
                    }
                }
                if(allow_get_predefined){
                    if(!clicked_statuses.length) {
                        //Showing predefined reasons when changing status
                        this.show_predefined_reasons = !this.show_predefined_reasons
                    } else {
                        //Showing and hiding predefined reasons when same status is Click
                        this.show_predefined_reasons         = (clicked_statuses[clicked_statuses.length - 1] === id) ? !this.show_predefined_reasons: true
                        this.selected_predefined_reasons     = []
                        this.selected_predefined_sub_reasons = []
                        this.selected_other_reason           = []
                        this.other_reason                    = ""
                    }
                }
                this.clicked_statuses.push(id)
                if(this.show_predefined_reasons){
                    this.selected_predefined_reasons = []
                    this.other_reason                = ''
                    this.getPredefinedReasons(+id)
                }
            },
            resetCommission() {
                this.selected_comm = ''
                document.querySelectorAll('.comm-status, input[name="commission"]').forEach(item => {
                    item.classList.remove('active')
                })
            },
            async resetSelectedStatus() {
                this.selected_change_status = 0
                let promise = new Promise((resolve, reject) => {
                    let container = document.querySelector('.a-toggle-btn')
                    if (container) {
                        container.querySelectorAll('.btn').forEach(item => {
                            item.classList.remove('focus')
                            item.classList.remove('active')
                        })
                        container.querySelectorAll('.comm-status, input[name="commission"]').forEach(item => {
							item.classList.remove('focus')
							item.classList.remove('active')
						})
                    }
                    resolve('done')
                })
            },
            decideToGetReason(id) {
                if (this.isReassignWithReason) this.getPredefinedReasons(id)
            },
            async getPredefinedReasons(id) {
                // if (+id === 99) {
                    this.reworkLevel = null
                    this.reworkCommission = null
                // }
                const isQualifiedOrHasPotential = [3, 98].includes(+id)
                if (isQualifiedOrHasPotential) {
                    setTimeout(() => {
                        document.querySelector('.retain-commission')?.classList.add('active')
                    }, 100);
                }
                await this.$store.dispatch('resetPredefinedReasonState')
                this.batch_change_status = id
                const qflag = this.$parent.getQFlag(id)
                const forReworkId = 2
                //purpose disabled caching so that status change reasons in Additional Filters/More Filters will work correctly
                /*if(!forceRefresh && this.all_predefined_reasons[qflag]?.length > 0 && id !== forReworkId) {
                    this.predefined_reasons = this.all_predefined_reasons[qflag]
                    this.filter_status_change_reason.options = this.predefined_reasons.filter(reason => reason.listings_count > 0).map(reason => ({
                        label: typeof reason.reason === "object" ? Object.keys(reason.reason)[0] : reason.reason,
                        value: reason.id 
                    }));
                    this.fetching_reasons   = true
                    return
                }*/
                this.is_loading = true
                const queries = {
                    filter: {
                        qflag_id: id
                    },
                    include: "subReasons,subReasonsCount,listingsCount",
                    page_size: 1000,
                    campaign_id: this.selected_campaign != 0 ? this.selected_campaign : ''
                };
                await this.$http.get(`/api/v2/reasons?${qs.stringify(queries, { encode: false })}`, this.$session.get('ver_headers'))
                    .then(response => {
                        if(response.status === 200) {
                            const result = response.data
                            this.predefined_reasons = result.data
                                .filter(reason => reason.status)
                                .map(predefinedReason => {
                                    const {id, type, severity_level, sub_reasons, reason} = predefinedReason
                                    const getSubReasons = () => sub_reasons
                                        .filter(({status, deleted_at}) => status && deleted_at === null)
                                        .map(({id, text, severity_level}) => ({ id, severity_level, reason: text }))
                                    const mainReason = sub_reasons.length
                                        ? {[reason]: getSubReasons()}
                                        : reason
                                    return {id, type, severity_level, reason: mainReason}
                                    /* return {
                                        id,
                                        type,
                                        severity_level,
                                        reason : reason.sub_reasons.length
                                            ? { [reason.reason] : reason.sub_reasons
                                                    .filter(sub => sub.status && sub.deleted_at == null)
                                                    .map(sub => ({ id: sub.id, reason: sub.text }))
                                                }
                                            : reason.reason
                                    } */
                                })
                            this.all_predefined_reasons[qflag] = this.predefined_reasons
                            this.filter_status_change_reason.options = result.data.filter(reason => reason.listings_count > 0).map(reason => ({ label: reason.reason, value: reason.id }));
                            this.fetching_reasons = true
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        this.$parent.showSnackbar('Something went wrong. Please contact system admin.', false, 'success', 'fas fa-check-circle')
                    })
                    .finally(() => this.is_loading = false)
            },
            // async getListingIDs_withSellerName(seller_name) {
            //     let headers = JSON.parse(JSON.parse(localStorage.getItem('ver')).headers)
            //     headers.headers.Sort = `${this.sort_by == '0' ? 'lid' : this.sort_by},${this.order_by == '0' ? 'desc' : this.order_by}`
            //     headers.headers.Where = JSON.stringify({})
            //     headers.headers['Seller-name'] = seller_name
            //     let endpoint = '/listings/main/search'
            //     this.isLoading_existing_listings = true
            //     await this.$http.get(`${endpoint}?page_size=100`,headers).then(
            //         response => {
            //             if(response.status == 200){
            //                 this.isLoading_existing_listings = false
            //                 let results = response.data
            //                 results = results.data.map(list =>{
            //                     return {
            //                         listing_id: list.id,
            //                         qflag_id: list.qflag_id,
            //                         seller_name   : list.seller[0] !== undefined ? list.seller[0].name != "" || list.seller[0].name !== null ? list.seller[0].name: '------': [],
            //                         seller_url    : list.seller[0] !== undefined ? list.seller[0].url != "" || list.seller[0].url !== null ? list.seller[0].url : '------': [],
            //                     }
            //                 })
            //                 this.existing_listings_id = [...this.existing_listings_id, ...results]
            //             }
            //         }
            //     ).catch(error => {
            //         console.log(error)
            //         this.$parent.showSnackbar('Something went wrong. Please contact System Admin.', false, 'invalid', 'fas fa-times-circle')
            //     })
            // },
            copytoClipboard(str = "", prop_name = 'listing id') {
                if (str != "") {
                    let modal = []
                    let container = (modal = document.querySelectorAll('.modal.in, .modal.show')).length ? modal : document
                    const el = document.createElement('textarea')
                    el.value = str
                    el.setAttribute('readonly', '')
                    el.style.position = 'absolute'
                    el.style.left = '-9999px'
                    container[modal.length ? 0 : "body"].appendChild(el)
                    el.select()
                    document.execCommand('copy')
                    container[modal.length ? 0 : "body"].removeChild(el)

                    // call the snackbar when copied
                    this.$parent.showSnackbar(`You copied ${prop_name}: ${str}`, false, 'success', 'fas fa-check')
                }
            },
            transferExistingTo_reassign(existing_listings_result, results) {
                if(existing_listings_result.length == results.length){
                    this.reassignStatuses.results = results.map((item, index) => {
                        return { ...item, existing_listings: existing_listings_result[index] }
                    })
                }
            },
            transferExistingTo_existing_listings_id(result){
                this.existing_listings_id = result.map(item => {
                    return {
                        lid: item.duplicate.map(item => {
                            return item.lid
                        }),
                        status: item.duplicate.map(item => {
                            return item.qflag_id_word
                        }),
                    }
                })
            },
            redirectTo_searchPage(searched_lid, status) {
                window.open(`${window.location.origin}/verification/search?status=${status}&platform=0&lid=${searched_lid}`,'_blank')
            },
            async getAdditionalFilters_data() {
                if(!this.expanded)
                    return
                if(this.getDataFor_AdditionalFilters)
                    return
                
                this.getDataFor_AdditionalFilters = true

                await this.getItemClasses()
                await this.getResearchers()
                // await this.getCampaigns()
                await this.getPaymentMethods()
                await this.getReasons()
                await this.getItemLocations()
                await this.getSellerLocations()
                await this.getClaimTypes()
                await this.getItemTypes()
                // await this.getRoles()
                // await this.getEmployees()
                await this.getShippingLocations()

                this.getDataFor_AdditionalFilters = false
            },
        },
        watch: {
            get_existing_listings(val) {
                if(this.hasExisting_listings){
                    this.transferExistingTo_existing_listings_id(val)
                }
            },
            '$store.state.checked_reasons'(val) {
                this.selected_predefined_reasons = val
            },
            '$store.state.checked_subreasons'(val) {
                this.selected_predefined_sub_reasons = val
            },
            '$store.state.checked_other_reason'(val) {
                this.selected_other_reason = val
            },
            '$store.state.other_reason_comment'(val) {
                this.other_reason = val
            },
            '$store.state.checked_commission'(val) {
                this.selected_comm = val
            },
            show_error(val) {
                if (!val) {
                    this.lists.map(l => l.error = false);
                }
            },
            async selected_campaign(val) {
                this.applied_filters_count = this.filter_set_count(false);
                if (val != 0)
                this.selected_campaign_name = this.campaigns_select.find(c => c.value == val).label
                else {
                        this.selected_campaign_name = ""
                    }
                // let filter_drawer = this.$parent.$children.find(c => c.$vnode.componentOptions.tag == 'ar-filter').$children[0]
                // filter_drawer.getPlatforms()
                if (this.expanded == false) {
                    this.applyFilters()
                }
                await this.getPredefinedReasons(this.$parent.getQFlagId(this.$route.query.status));
            },
            min_date_added(val) {
                if (val == '') {
                    this.max_date_added = ''
                } else if (this.max_date_added == '') {
                    this.max_date_added = this.today
                } else if (val > this.max_date_added) {
                    this.max_date_added = val
                }
            },
            max_date_added(val) {
                if (val == '' && this.min_date_added == '') {
                    this.max_date_added = this.today
                } else if (val <= this.min_date_added) {
                    this.min_date_added = val
                }
            },
            min_date_qualified(val) {
                if (val == '') {
                    this.max_date_qualified = ''
                } else if (this.max_date_qualified == '') {
                    this.max_date_qualified = this.today
                } else if (val > this.max_date_qualified) {
                    this.max_date_qualified = val
                }
            },
            max_date_qualified(val) {
                if (val == '' && this.min_date_qualified == '') {
                    this.max_date_qualified = this.today
                } else if (val <= this.min_date_qualified) {
                    this.min_date_qualified = val
                }
            },
            rids(val) {
                if (this.expanded == false)
                    this.applyFilters()
            },
            show_number_of_items() {
                this.updateShowNumberItems()
            },
            single_reassign_campaign() {
                let researcher_select = document.querySelector("#campaigns-researcher-select input[type=text]")
                if (researcher_select != null) {
                    researcher_select.value = ""
                    this.single_reassign_researcher = 0
                }
                this.input_key.reassign.researcher = this.getRandomKey()
            },
            order_by() {
                this.getListing()
            },
            sort_by() {
                this.getListing()
            },
            page_size() {
                this.updatePage(1)
            },
            '$route.query': {
                handler: async function(search) {
                    this.selected_listings = []
                    this.applied_filters_count = this.filter_set_count(false);
                    this.updatePage(1)
                    await this.getPredefinedReasons(this.$parent.getQFlagId(this.$route.query.status));
                }
            },
            shown_data() {
                this.updatePage(1)
            },
            selected_listings(val) {
                if (val.length == 0)
                    this.adjustChipInputHeight()
            },
            change_status() {
                this.input_key.reassign.campaigns = this.getRandomKey()
                this.input_key.reassign.researcher = this.getRandomKey()
            },
            'reassignStatuses.selected'(val) {
                this.decideToGetReason(val)

                this.subStatusesStore.currentSelectedSubStatus = -1

                if (this.reassignmentBulkSubStatusOptions.length) {
                    setTimeout(() => {
                        $('.bulk-reassign-status-and-substatus-con').children('.btn-group-toggle').find('.sub-statuses-container .btn').first().addClass('active')
                    })
                }
            },
            'individualReassign.status.selected'(val) {
                if (val) {
                    this.decideToGetReason(val)
                    if (this.isReassignWithReason) this.getChangeStatus(val, this.reassignStatusOptions.find(s => s.value == val)?.label)
                } else {
                    this.show_predefined_reasons = false
                }

                this.subStatusesStore.currentSelectedSubStatus = -1

                if (this.reassignmentSubStatusOptions.length) {
                    setTimeout(() => {
                        $('.individual-reassign-status-and-substatus-con').children('.btn-group-toggle').find('.sub-statuses-container .btn').first().addClass('active')
                    })
                }
            }
        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
    .fieldset-alt.disabled .fieldset-alt-content {
        opacity: 0.5 !important;
    }

    .main-con {
        padding: 261px 343px 150px 77px;
    }
    .a-header {
        position: relative;
        top: 1px;
        letter-spacing: 0.024em;
        color: #383A3D;
        font-weight: 500;
        font-size: 24px;
        margin-bottom: 0;
        height: 40px;
        line-height: 7px;
        vertical-align: middle;
    }
    .a-header img {
        height: 40px;
        width: 40px;
        margin-right: 17px;
    }
    .a-header span {
        display: block;
        font-size: 16px;
        font-weight: 400;
        color: #383A3D;
    }
    .a-header em {
        font-style: initial;
        opacity: 0.8;
    }
    .a-header strong {
        font-weight: 500 !important;
    }
    .titlebar-con {
        padding-right: 343px;
        min-height: 66px;
        padding-left: 46px;
    }
    .titlebar-con.expanded {
        min-height: 139px;
    }
    .a-sidebar-filter {
        box-shadow: none;
        height: 100vh;
        z-index: 1;
        top: 57px;
        right: 0;
    }
    .a-header-con {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    .a-modal section {
        margin-bottom: 44px;
    }
    .a-modal hr {
        border: 1px solid #4e4e4e30;
        margin: 32px 0
    }
    .input_rid {
        max-height: 200px;
    }
    .empty-listing-con {
        position:relative;
        height: 30vh;
    }
    .empty-listing-con img {
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        position: absolute;
        width: 35%;
        left: 50%;
        top: 80%;
    }
    .qa-selected strong {
        font-weight: 500;
        position: relative;
    }
     .qa-selected strong.with-separator:after {
        content: "";
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        background: #ccc;
        position: absolute;
        right: -71px;
        height: 32px;
        width: 1px;
        top: 50%;
    }
     .qa-selected .action-item:hover,
     .qa-selected .action-item.disabled {
        opacity: 0.5;
    }
     .qa-selected .action-item:not(.disabled):active {
        opacity: 0.8;
    }
    .export-btn {
        background: #2D9E5A !important;
    }
    .export-btn:hover{
        background: #2d9e5a8c !important;
    }
    .search-footer {
        background: #fff;
        position: fixed;
        padding: 10px 350px 0 77px;
        height: 60px;
        width: 100%;
        bottom: 0;
        left: 0;
    }
    .campaign-preview {
        position : absolute !important;
        left     : 15px !important;
        bottom   : 6px;
        width    : 50px;
        height   : 50px !important;
        top      : initial !important;
        transform: none !important;
    }
    .additional-filters-con {
        height: 0;
        overflow: hidden;
        -webkit-transition: height 0.2s ease;
        transition: height 0.2s ease;
    }
    .additional-filters-con.expanded {
        height: calc(100vh - 182px);
    }
    .additional-filters-con-con {
        height: calc(100vh - 306px);
        padding: 0 20px 20px;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .additional-filters-con.has-lids.expanded .additional-filters-con-con {
        height: calc(100vh - 345px);
    }
    #merge-modal .heading {
        font-size: 24px;
        color: #29ABA2;
        font-weight: 500;
        margin-bottom: 30px;
    }
    #merge-modal .heading span {
        font-size: 16px;
        color: #515365;
        display: block;
    }
    #merge-modal hr {
        border-width: 2px;
    }
    #merge-modal .list-con {
        max-height: 300px;
        overflow-y: auto;
    }
    #merge-modal .list-item-con .download-merge {
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        -webkit-transition: opacity .3s ease;
        transition: opacity .3s ease;
        position: absolute;
        right: 20px;
        top: 50%;
        width: 30px;
        height: 30px;
    }
    #merge-modal .list-item-con .download-merge:hover {
        opacity: 0.5;
    }
    #merge-modal .list-item-con .download-merge img {
        width: 100%;
    }
    #merge-modal .list-item-con .loading-icon {
        top: 27%;
        right: 34px;
    }
    .lds-ripple {
        display: inline-block;
        position: relative;
        width: 10px;
        height: 10px;
    }
    .lds-ripple div {
        position: absolute;
        border: 4px solid #666;
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    .lds-ripple div:nth-child(2) {
        animation-delay: -0.5s;
    }
    @keyframes lds-ripple {
        0% {
            top: 28px;
            left: 28px;
            width: 0;
            height: 0;
            opacity: 1;
        }
        100% {
            top: 0px;
            left: 0px;
            width: 52px;
            height: 52px;
            opacity: 0;
        }
    }
    .has-bullet-separator {
        position: relative;
    }
    .has-bullet-separator::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        z-index: 2;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        height: 5px;
        width: 5px;
        border-radius: 100%;
    }
    #reassign-searchpage-modal .modal-dialog {
        -webkit-transition: .3s ease !important;
        transition: .3s ease !important;
    }
    #reassign-searchpage-modal .withReason .campaign-img {
        height: 40px;
        width: 40px;
    }
    #reassign-searchpage-modal .withReason .left-con {
        width: 330px;
        /* height: 391px; */
    }
    #reassign-searchpage-modal .withReason .reassign-right-con {
        width: calc(100% - 368px);
        /* background: #F8F8F8; */
        padding: 15px;
    }
    #reassign-searchpage-modal .withReason .right-con {
        height: 345px;
        overflow-y: auto;
        padding-right: 10px;
    }
    #reassign-searchpage-modal .withReason .reassign-left-heading h5,
    #reassign-searchpage-modal .withReason .reassign-right-heading h5 {
        font-weight: 500;
        font-size: 16px;
    }
    #reassign-searchpage-modal .withReason .reassign-left-heading h5 span,
    #reassign-searchpage-modal .withReason .reassign-right-heading h5 span {
        font-weight: 400;
        font-size: 12px;
    }
    .reassign-to-wip-notice {
        height: 87px;
        border: 1px dashed #ff971d;
        border-radius: 10px;
        position: relative;
        padding: 12px 20px 20px 124px;
        background: #fdf7f0;
    }
    .reassign-to-wip-notice .notice-img {
        width: 85px;
        height: 63px;
        position: absolute;
        left: 21px;
        top: 50%;
        /* overflow: hidden; */
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        opacity: 1;
        -webkit-transition: .5s ease;
        transition: .5s ease;
        border: 1px dashed transparent;
    }
    .reassign-to-wip-notice .notice-img img {
        position: absolute;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        top: 32%;
        left: 50%;
    }
    .reassign-to-wip-notice h6 {
        background: #ff971d;
        border-radius: 14px;
        line-height: 25px;
        height: 24px;
        width: 75px;
        text-align: center;
        color: #ffff;
        font-size: 11px;
        margin-bottom: 10px;
    }
    .reassign-to-wip-notice p {
        text-align: left;
        font-size: 13px;
        color: #000000CC;
    }
    #reassign-searchpage-modal .table-scroll {
        width:100%;
        display: block;
        empty-cells: show;
    }
    #reassign-searchpage-modal .table-scroll thead,
    #reassign-searchpage-modal .table-scroll tfoot{
        position:relative;
        display: block;
        width:100%;
    }
    #reassign-searchpage-modal .table-scroll tbody{
        display: block;
        position:relative;
        width:100%;
        height: 280px;
        overflow-y:auto;
    }
    #reassign-searchpage-modal .table-scroll tr{
        width: 100%;
        display: inline-block;
    }
    #reassign-searchpage-modal .table-scroll td,.table-scroll th{
        display: inline-block;
        word-break: break-all;
        padding: 1rem;
        text-align:left;
    }
    #reassign-searchpage-modal .table-scroll tr td .status {
        display: inline-block;
        border-radius: 4px;
        padding: 3px;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        font-size: 9px;
        font-weight: 500;
        background: #29ABA2;
        min-width: 66px;
    }
    #reassign-searchpage-modal .table-scroll tr td .status.not_found {
        background: #DC3C40;
    }
    #reassign-searchpage-modal .table-scroll tr td .status.wip {
        background: #f3a17a;
    }
    #reassign-searchpage-modal .table-scroll tr td .status.others {
        background: #3c97dc;
    }
    #reassign-searchpage-modal .table-scroll th {
        display: inline-block;
        color: #627aad;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 1.4px;
    }
    /*
    * VER VARIATIONS
    */
    .gui-ver .has-bullet-separator::before {
        background: #3e88da;
    }
    /*
    * END OF VER VARIATIONS
    */
    /* newly added email */
    .email_info_container {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        font-size: 12px;
        font-weight: 500;
        margin-top: 10px;
        padding: 10px 10px 10px 45px;
        height: calc(90% - 16px);
        color: #3e88da;
        background: #eef3ff;
        border:1px solid #3e88da;
        border-radius: 5px;
        position: relative;
    }
    .email_info_container i {
        top: 0;
        left: 0;
        padding: 10px 0px 0px 15px;
        position: absolute;
    }
    .email_info_container p {
        margin-bottom: 0;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    /* added style for Reassignment Status in Existing Listing(s) */
    .existing-listings-container {
        max-height: 100px;
        overflow: auto;
        margin-bottom: 5px;
        border: 1px solid rgb(197, 197, 197);
    }
    .existing-listings-item-container {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    .existing-listings-item-container {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    .existing-btn {
        background-color: #2D599E;
        letter-spacing: 1.25px;
        font-size: 12px;
        font-weight: 400;
        padding: 6px 10px 6px;
        border-radius: 20px;
        position: relative;
        opacity: 1;
        border: 0;
        color: #FFFFFF;
    }
    .existing-btn:not(.disabled):hover {
        background-color: #3E88DA;
    }

    .sub-statuses-container {
        max-height: 300px;
        overflow-y: auto;
        margin-bottom: 10px;
        border: 1px solid rgba(121, 189, 154, 0.35);
        border-top: none;
        margin-left: 7px;
        width: 95.9%;
        padding: 10px;
    }

    .sub-statuses-container .btn.sub-status {
        height: 36px !important;
        position: relative;
        width: 100%;
        padding-top: 22px !important;
        padding-bottom: 20px !important;
        padding-left: 80px !important;
        border-radius: 8px !important;
    }

    .sub-statuses-container .btn.sub-status.reassignment::before {
        left: 18px !important;
    }
</style>
